import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useContext } from "react";
import CommercialContext from "../context/CommercialContext";
import STcontext from "../context/STcontext";
export default function NouvelleTâches() {
  const {
    handleAddClient,
    submitTaskToConfirmed,
    addClient,
    filterClientInfo,
    setFilterClientInfo,
    show,
    setShow,
    handleFilterClientInfo,
    setaddClient,
    error,
  } = useContext(CommercialContext);
  const { getTaskInfo, NewTask } = useContext(STcontext);

  useEffect(() => {
    getTaskInfo();
  }, []);

  const handleClose = () => {
    setFilterClientInfo({});
    setaddClient({ Quantite: "1" });
    setShow(false);
  };
  const handleShow = () => setShow(true);
  return (
    <div>
      <Button
        className="nouveauProfilButton"
        style={{ width: "100%" }}
        onClick={handleShow}
      >
        Nouvelle tâche
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouvelle tâche a confirmer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={submitTaskToConfirmed}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Nom complet: </Form.Label>
                <Form.Control
                  required
                  list="clientList"
                  type="list"
                  autoComplete="off"
                  name="nomComplet"
                  placeholder="Nom complet"
                  onChange={(e) => handleFilterClientInfo(e, NewTask.Clients)}
                />
                <datalist id="clientList">
                  {NewTask.Clients.map((clientName, index) => (
                    <option key={index}>{clientName.nomComplet}</option>
                  ))}
                </datalist>
                {error && error.nomComplet ? (
                  <span style={{ color: "red" }}>{error.nomComplet[0]}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Telephone: </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="+212 555555"
                  name="telephone"
                  value={
                    addClient && addClient.telephone != null
                      ? addClient.telephone
                      : ""
                  }
                  onChange={handleAddClient}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Email: </Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  placeholder="example@example.com"
                  name="emailClient"
                  value={
                    addClient && addClient.emailClient != null
                      ? addClient.emailClient
                      : ""
                  }
                  onChange={handleAddClient}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Type tâche: </Form.Label>
                <Form.Select
                  list="dataTask"
                  name="typeTache"
                  autoComplete="off"
                  onChange={handleAddClient}
                >
                  <option value="none" selected disabled hidden>
                    Sélectionner Type Tache
                  </option>

                  {NewTask.TypesTasks.map((TypesTask, index) => (
                    <option key={index}>{TypesTask.catache}</option>
                  ))}
                </Form.Select>
                {error && error.typeTache ? (
                  <span style={{ color: "red" }}>{error.typeTache[0]}</span>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Date:</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="Date"
                  autoComplete="off"
                  onChange={handleAddClient}
                />
                {error && error.Date ? (
                  <span style={{ color: "red" }}>{error.Date[0]}</span>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="">
                <Form.Label>Quantité: </Form.Label>
                <Form.Control
                  type="number"
                  name="Quantite"
                  autoComplete="off"
                  onChange={handleAddClient}
                  value={addClient.Quantite}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="">
                <Form.Label>réalisée à : </Form.Label>
                <Form.Select
                  name="clientCity"
                  autoComplete="off"
                  onChange={handleAddClient}
                >
                  <option selected hidden>
                    {addClient &&
                    addClient.clientCity != null &&
                    filterClientInfo.city
                      ? filterClientInfo.city.cityName
                      : "select ville"}
                  </option>
                  <option disabled>select ville</option>
                  {NewTask &&
                    NewTask.Cities.map((city, index) => (
                      <option key={index} value={city.id}>
                        {city.cityName}
                      </option>
                    ))}
                </Form.Select>
                <span style={{ color: "red" }}>
                  {error && error.clientCity ? error.clientCity[0] : ""}
                </span>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="">
                <Form.Label>Observation: </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="Obsirvation"
                  autoComplete="off"
                  onChange={handleAddClient}
                />
              </Form.Group>
            </Row>

            <Modal.Footer>
              <Button type="submit" className="Modelbutton" variant="primary">
                Ajouter Tâche
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
