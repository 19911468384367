import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import { useNavigate } from "react-router-dom";

export default function DetailsModel({data}) {
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navTo = useNavigate();

  return (
    <div>
      <Button style={{ background:'none' , color:'black' , border:'none' }} variant="primary" onClick={handleShow}>
<AddCircleIcon />
      </Button>

      <Modal size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Détails tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display:'flex', maxHeight:'70vh', justifyContent:'start',flexWrap:'wrap', gap:'20px', overflowY:'scroll'}}>
            
            <h6>Besoin : </h6> {data.pivot.besoin}

        </Modal.Body>
      </Modal>
    </div>
  );
}