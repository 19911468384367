import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import CommercialContext from "../context/CommercialContext";
import Button from "react-bootstrap/Button";
import ConfirmPopup from "../updateAndDeleteButtons/ConfirmPopup";
import UpdateTaskToConfirm from "../updateAndDeleteButtons/UpdateTaskToConfirm";
import pusher from "../../pusher";
import NouvelleTâches from "./NouvelleTâches";
import "./style/style.css";
import { SuccessMessage } from "../errorSuccessNotification/SuccessMessage";

const TaskToConfirm = () => {
  const { getTasktoConfirmed, task, getTaskInfo, deleteTask } =
    useContext(CommercialContext);
  useEffect(() => {
    getTasktoConfirmed();
    getTaskInfo();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getTasktoConfirmed();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const [tasksToConfirmed, setTasksTConfirmed] = useState([]);
  useEffect(() => {
    setTasksTConfirmed(task);
  }, [task]);
  const [pages, setPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const lastTaskIndex = pages * rowsPerPage;
  const firstTaskIndex = lastTaskIndex - rowsPerPage;
  const currentTasks = tasksToConfirmed.slice(firstTaskIndex, lastTaskIndex);

  let numberPages = [];
  for (let p = 1; p <= Math.ceil(tasksToConfirmed.length / rowsPerPage); p++) {
    numberPages.push(p);
  }

  console.log(tasksToConfirmed);
  const handlesearchTaskToConfirmed = async (e) => {
    const { value } = e.target;
    const searchTerm = value.trim().toLowerCase();

    if (value != "") {
      const filterTasksToConfirmed =
        task &&
        task.filter(
          (task) =>
            (task.clients &&
              task.clients.nomComplet.toLowerCase().includes(searchTerm)) ||
            (task.clients &&
              task.cataches.catache.toLowerCase().includes(searchTerm))
        );
      if (filterTasksToConfirmed.length > 0) {
        setTasksTConfirmed(filterTasksToConfirmed);
      } else {
        setTasksTConfirmed([]);
      }
    } else {
      setTasksTConfirmed(task);
    }
  };
  return (
    <div style={{ padding: "20px 20px 30px 20px" }}>
      <SuccessMessage />
      <div className="bar-table">
        <div className="left-bar-table"></div>
        <div className="right-bar-table">
          <NouvelleTâches />
        </div>
      </div>

      <div>
        <div className="Table-tasks">
          <div className="numberTasks">
            <h3>
              Total Tâches &nbsp; <span class="vl"></span> &nbsp;{" "}
              <span style={{ fontSize: "15px" }}>
                {" "}
                {tasksToConfirmed.length} tâches à confirmer
              </span>{" "}
            </h3>
          </div>
          <div className="searchTasks" style={{ width: "100%" }}>
            <div className="inputSearch input-group rounded">
              <span className="icon-input">
                <i class="pi pi-search"></i>
                <input
                  className="input-text form-control rounded"
                  type="search"
                  placeholder="Nom client, Type tâche"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  onChange={handlesearchTaskToConfirmed}
                />
              </span>
            </div>
          </div>
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Nom</th>
                <th scope="col">Type tache</th>
                <th scope="col">Date previsionnelle debut</th>
                <th scope="col">Envoyer par</th>
                <th scope="col">Statut</th>
                <th scope="col">Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {currentTasks.length > 0
                ? currentTasks.map((TASK) => (
                    <tr key={TASK.id}>
                      <td></td>
                      <td>
                        <p className="fw-bold mb-1">
                          {TASK.clients.nomComplet}
                        </p>
                      </td>
                      <td>{TASK.cataches.catache}</td>
                      <td>{TASK.date_previsionnelle_debut}</td>
                      <td>{TASK.created_by.name}</td>
                      <td>
                        <Button>
                          {TASK.validation == 1 ? "Confirmée" : "E.Validation"}
                        </Button>
                      </td>
                      <td style={{ width: "5%" }}>
                        <div style={{ display: "flex" }}>
                          <UpdateTaskToConfirm taskToUpdate={TASK} />
                          <ConfirmPopup
                            message="Etes vous sûre de vouloir supprimer cette tâche?"
                            id={TASK.id}
                            deleteFunction={(e) => deleteTask(e, TASK.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                : "Aucune tâche trouvée."}
            </MDBTableBody>
          </MDBTable>
          {task.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberPages.map((numberPage, index) => (
                  <MDBPaginationItem
                    key={index}
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPages(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}{" "}
        </div>
      </div>
    </div>
  );
};

export default TaskToConfirm;
