import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useParams } from "react-router-dom";
import { EditAppareilsPreUser } from "./EditAppareilsPreUser";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useEffect } from "react";
import { useContext } from "react";
import STcontext from "../../../context/STcontext";
import ComptableContext from "../../../context/ComptableContext";

export const ShowAppareilPerUser = () => {
  const {
    getAppareilsPerUser,
    apparielsPerUser,
    showApparielPreUser,
    setAppareilPerUser,
    setShowAppareilPerUser,
  } = useContext(STcontext);
  const { getTypesgps, editAppareilsPreUser, getAllUsers } =
    useContext(ComptableContext);
  const { id, typeAppareil } = useParams();
  const handleClose = () => {
    setAppareilPerUser("");
    setShowAppareilPerUser(false);
  };
  const [IdType, setIdType] = useState();
  useEffect(() => {
    setAppareilPerUser(null);
    let typeId;
    switch (typeAppareil) {
      case "GPS":
        typeId = 1;
        setIdType(1);
        break;
      case "SIM":
        typeId = 2;
        setIdType(2);
        break;
      case "accessorie":
        typeId = 3;
        setIdType(3);
    }

    getAppareilsPerUser(id, typeId);
    getTypesgps();
    getAllUsers();
  }, []);

  const onRowEditComplete = (e) => {
    let _products = [...apparielsPerUser];
    let { newData, index } = e;

    _products[index] = newData;
    setAppareilPerUser(_products);
    editAppareilsPreUser(newData);
  };
  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const editIcon = (options) => {
    return (
      <EditAppareilsPreUser
        data={options}
        paramsId={id}
        paramsTypeId={IdType}
      />
    );
  };

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    serial_number: { value: null },
    nameModele: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    nameType: { value: null, matchMode: FilterMatchMode.IN },
    numberBox: { value: null, matchMode: FilterMatchMode.IN },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <>
      <div className="bar-table" style={{ padding: "0rem 2rem 1rem 2rem " }}>
        <div
          className=""
          style={{
            backgroundColor: "white",
            width: "95%",
            padding: "2rem",
            marginLeft: "20px",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <div
            className="showAppareilsPreUser"
            style={{ marginBottom: "1rem" }}
          >
            <h5>
              List Des Appareils&nbsp;{" "}
              {apparielsPerUser?.map((userName) => userName.name)[0]}
            </h5>
          </div>
          <DataTable
            value={apparielsPerUser}
            editMode="row"
            dataKey="id"
            onRowEditComplete={onRowEditComplete}
            tableStyle={{ minWidth: "50rem" }}
            paginator
            header={header}
            filters={filters}
            rows={10}
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column
              header="#"
              headerStyle={{ width: "3rem" }}
              body={(data, options) => options.rowIndex + 1}
              bodyStyle={{ fontWeight: "bold" }}
            ></Column>
            <Column
              header="IMEI"
              field="serial_number"
              style={{ width: "20%" }}
            ></Column>
            <Column
              header="Model"
              field="nameModele"
              style={{ width: "20%" }}
            ></Column>
            <Column
              header="Type"
              field="nameType"
              style={{ width: "20%" }}
            ></Column>
            <Column
              header="Endroit"
              field="name"
              style={{ width: "20%" }}
            ></Column>
            <Column
              header="Action"
              body={(options) => editIcon(options)}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};
