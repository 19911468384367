import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import TuneIcon from '@mui/icons-material/Tune';
import CommercialContext from "../../context/CommercialContext";
import axios from "../../context/axios";
import DetailsModel from "./DetailsModel";
import STcontext from "../../context/STcontext";
import pusher from "../../../pusher";


const pageSize = 4;
const TachesCommercial = () => {
 
  const{handleDateClick,inputDate,handleDateChange,handleClick,handleChange,filterData,getClient,taskCommercial} = useContext(STcontext  );

  const [task, setTask] = useState([]);
  const [paginaed, setPaginated] = useState([]);
  const [currentPage, setCurrentPage] = useState();
const [USERR,SETUSER]=useState([]);

let clientNumber;

  useEffect(() => {
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getClient();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  
  useEffect(()=>{
    if(filterData == null){
      (taskCommercial.forEach((response)=>setTask(()=>[...(response.client)])));
      ((taskCommercial.forEach((response)=>setPaginated(()=>_([...(response.client)]).slice(0).take(pageSize).value()))))
    }else if (filterData !=null && filterData.length>0){
      setPaginated(..._(filterData.map((obj)=>obj.client)).slice(0).take(pageSize).value())

    }
 
  }, [taskCommercial,filterData])
    
  const [details, setDetails] = useState({});

  let pageCount;

  if (filterData!=null && filterData.length>0) {
    pageCount = filterData.map((obj)=>obj.client) ? Math.ceil(filterData.map((obj)=>obj.client)  / pageSize) : 0;

  } else {
    pageCount = task ? Math.ceil(task.length / pageSize) : 0;

  }
  const pages = _.range(1, pageCount + 1);
  const pagination = (pageNo) => {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    if (filterData != null && filterData.length>0) {
      const paginatedTask = _(...filterData.map((obj)=>obj.client)).slice(startIndex).take(pageSize).value();
      setPaginated(paginatedTask)
    } else {
      const paginatedTask = _(task).slice(startIndex).take(pageSize).value();
      setPaginated(paginatedTask);
    }

  };

  return (


    <div>
      <div className="bar-table">
        <div>
          <h5>Clients &nbsp;   <span class="vl"></span> &nbsp;  <span style={{ fontSize: '15px' }}> {task.length} clients</span> </h5>
        </div>

        <div class="search input-group rounded">
          <input type="search" class="form-control rounded" onChange={handleChange} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
          <span class="input-group-text border-0" style={{ cursor: 'pointer' }} id="search-addon">
            <i onClick={handleClick}class="fas fa-search"></i>
          </span>
        </div>
        <div className="">
          {/* <CommTâches /> */}
        </div>
      </div>
      <div className="tâches-tech">

        <div className="Table-tech">
          <MDBTable align="middle">
            <MDBTableHead >
              <tr>
                <th scope="col"></th>
                <th scope="col">Nom du client</th>
                <th scope="col">Email</th>
                <th scope="col">date création</th>
                <th scope="col">Par </th>
              </tr>
            </MDBTableHead>
              <MDBTableBody>
              {paginaed.map((TASK) =>
                <tr key={TASK.id}>
                  <td>
                    <DetailsModel data={TASK} />
                  </td>
                  <td>
                    <p className="fw-bold mb-1">{TASK.nomComplet} </p>
                    <span>
                    {TASK.telephone_client}</span>
                
                  </td>
                  <td>
                     {TASK.email_client }
                  </td>
            
                  <td>
                     {TASK.pivot.date_besoin}
                  </td>
                  <td>
                     {USERR.map((user)=>user.id == TASK.pivot.utilsateur_id ? user.name:"")}
                  </td>
                
                </tr>
                )}

              </MDBTableBody>
          </MDBTable>
          <nav className="d-flex justify-content-end">
            <ul className="pagination">
              {pages.map((page) => (
                <div
                  className={`${page === currentPage ? "page-item active" : "page-item"
                    }`}
                >
                  <p className="page-link" onClick={() => pagination(page)}>
                    {page}
                  </p>
                </div>
              ))}
            </ul>
          </nav>

        </div>


        <div className="Filter">
          <h5> <TuneIcon />&nbsp; <span style={{ marginTop: "100px" }}>Filtrer</span></h5>
          <hr></hr>

          <div>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label>Depuis : </Form.Label>
                <Form.Control
                  type="date"
                  value={inputDate.startDate}
                  name="startDate"
                  onChange={handleDateChange}
                  required

                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label>Jusqu'a : </Form.Label>
                <Form.Control
                  type="date"
                  value={inputDate.endDate}
                  name="endDate"
                  onChange={handleDateChange}
                  required

                />
              </Form.Group>
            </Row>

            <div className="filter-button">

              <Button onClick={handleDateClick}> <TuneIcon />Filtrer</Button>
            </div>


          </div>

        </div>


      </div>
    </div>

  );
};


export default TachesCommercial;