import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Image from 'react-bootstrap/Image';
import { MDBCarousel, MDBCarouselItem, MDBBtn } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import axios from "../../context/axios";
import STcontext from "../../context/STcontext";
import { Sync } from "@mui/icons-material";
import Select from "react-select";

export default function DetailsModel({ taskToUpdate }) {
  const { NewTask } = useContext(STcontext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navTo = useNavigate();
  const values = [true];

  const [TaskToUpdateFromST, setTaskToUpdateFromST] = useState({
    garantie: taskToUpdate.garantie,
  });
  const [newDataTask, setNewDataTask] = useState([]);
  const [fullscreen, setFullscreen] = useState(true);
  const [images, setImages] = useState({
    imageUrl: "",
    id: 0,
  });

  const [technicians, setTechnicians] = useState([]);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
    setTaskToUpdateFromST(taskToUpdate);
  }

  const handelUpdateTaskFromST = (e) => {
    const { name, value } = e.target;
    setTaskToUpdateFromST({ ...TaskToUpdateFromST, [name]: value });
    setNewDataTask({ ...newDataTask, [name]: value });
  };
  const handleCheckBoxGrantie = (e) => {
    const name = e.target.name;
    setTaskToUpdateFromST({
      ...TaskToUpdateFromST,
      garantie: e.target.checked,
    });
    if (e.target.checked == false) {
      setNewDataTask({ ...newDataTask, [name]: "non" });
    } else {
      setNewDataTask({ ...newDataTask, [name]: e.target.checked });
    }
  };

  const updateTaskSubmit = async (e, id) => {
    e.preventDefault();
    try {
      const updateTaskSubmit = await axios.put(
        `api/supportTechnique/updateTask/${id}`,
        newDataTask
      );
      if (updateTaskSubmit) {
        handleClose();
        setNewDataTask("");
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log("error updating");
    }
  };

  const showImage = (imageUrl, id) => {
    setImages({ imageUrl, id });
  };
  const closeImageShow = () => {
    setImages({ imageUrl: "", id: 0 });
  };

  const defaultAccessories =
    TaskToUpdateFromST.vehicules &&
    TaskToUpdateFromST.vehicules.articles &&
    TaskToUpdateFromST.vehicules.articles
      ? TaskToUpdateFromST.vehicules.articles.map((acc) => {
          return {
            value: acc.id,
            label: acc.serial_number,
          };
        })
      : "";
  return (
    <div>
      <Button
        style={{ background: "none", color: "black", border: "none" }}
        onClick={() => handleShow(values)}
      >
        <AddCircleIcon />
      </Button>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        {images.imageUrl ? (
          <div
            className="show-image"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <MDBBtn
              className="btn-close"
              color="white"
              aria-label="Close"
              onClick={closeImageShow}
            />
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {" "}
                <img
                  style={{
                    maxWidth: "50%",
                    width: "auto",
                  }}
                  className="d-block"
                  src={"https://d3instal.com/images/" + images.imageUrl}
                  alt="..."
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Détails tâche</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{ gap: "20px", marginBottom: "10px", overflowY: "scroll" }}
            >
              <Form
                noValidate
                method="POST"
                onSubmit={(e) => updateTaskSubmit(e, TaskToUpdateFromST.id)}
              >
                <Row className="mb-3">
                  {/* NameClient */}
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Nom client : </Form.Label>
                    <Form.Control
                      required
                      list="listClient"
                      type="text"
                      name="client_name"
                      autoComplete="off"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.client_name}
                    />
                    <datalist id="listClient">
                      {NewTask.Clients.map((client) => (
                        <option key={client.id}>{client.nomComplet}</option>
                      ))}
                    </datalist>
                  </Form.Group>

                  {/* matricule */}
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Matricule : </Form.Label>
                    <Form.Control
                      type="text"
                      name="matricule"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.matricule}
                    />
                  </Form.Group>

                  {/* type tache */}
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Type du tâche : </Form.Label>
                    <Form.Control
                      list="typeTasks"
                      type="text"
                      name="catache"
                      autoComplete="off"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.catache}
                    />
                    <datalist id="typeTasks">
                      {NewTask.TypesTasks.map((typeTask) => (
                        <option key={typeTask.id}>{typeTask.catache}</option>
                      ))}
                    </datalist>
                  </Form.Group>

                  {/* IMEI */}
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>IMEI : </Form.Label>
                    <Form.Control
                      required
                      autoComplet="off"
                      type="text"
                      name="IMEI"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.IMEI}
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  {/* model GPS */}
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Modele Gps : </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="nameModeleGPS"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.nameModeleGPS}
                    />
                  </Form.Group>

                  {/* propreitaire GPS */}
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Prop GPS : </Form.Label>
                    <Form.Select
                      list="proprietaireList"
                      required
                      type="text"
                      name="proprietaire"
                      onChange={handelUpdateTaskFromST}
                    >
                      <option value="none" selected disabled hidden>
                        {TaskToUpdateFromST.proprietaire === 0
                          ? "D3 hi-tech"
                          : TaskToUpdateFromST.proprietaire === 1
                          ? "client"
                          : "proprietaire GPS"}
                      </option>
                      <option disabled>proprietaire</option>
                      <option value={0}>interne</option>
                      <option value={1}>client</option>
                    </Form.Select>
                  </Form.Group>

                  {/* GPS Position */}
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>GPS Position: </Form.Label>
                    <Form.Select
                      type="text"
                      placeholder=""
                      name="GPSPosition"
                      onChange={handelUpdateTaskFromST}
                    >
                      <option value="none" selected disabled hidden>
                        {TaskToUpdateFromST.gps_principale == 0
                          ? "principale"
                          : TaskToUpdateFromST.gps_principale == 1
                          ? "secondaire"
                          : "GPS Position"}
                      </option>
                      <option disabled>GPS Position</option>
                      <option value="principale">principale</option>
                      <option value={1}>secondaire</option>
                    </Form.Select>
                  </Form.Group>

                  {/* model SIM */}
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Modele SIM : </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      name="nameModeleSIM"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.nameModeleSIM}
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  {/* sim number */}
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>SIM : </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="SIM"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.SIM}
                    />
                  </Form.Group>

                  {/* propreitaire sim */}
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Prop SIM : </Form.Label>
                    <Form.Select
                      type="text"
                      placeholder=""
                      name="SIM_proprietaire"
                      onChange={handelUpdateTaskFromST}
                    >
                      <option value="none" selected disabled hidden>
                        {TaskToUpdateFromST.SIM_proprietaire === 0
                          ? "D3 hi-tech"
                          : TaskToUpdateFromST.SIM_proprietaire === 1
                          ? "client"
                          : "Sim Propreitaire"}
                      </option>
                      <option disabled>Sim Propreitaire</option>
                      <option value="internal">D3 HI-TECH</option>
                      <option value="client">client</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Type Relais : </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="accesoriesModel"
                      onChange={handelUpdateTaskFromST}
                      value={
                        TaskToUpdateFromST.vehicules &&
                        TaskToUpdateFromST.vehicules.articles &&
                        TaskToUpdateFromST.vehicules.articles.filter(
                          (relais) => relais.modele_id === 21
                        ).length > 0
                          ? "Spécial"
                          : TaskToUpdateFromST.vehicules &&
                            TaskToUpdateFromST.vehicules.articles &&
                            TaskToUpdateFromST.vehicules.articles.filter(
                              (relais) => relais.modele_id === 22
                            )
                          ? "normal"
                          : ""
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Garantie : </Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="sans"
                      name="garantie"
                      onClick={handleCheckBoxGrantie}
                      checked={
                        TaskToUpdateFromST.garantie == 0
                          ? true
                          : TaskToUpdateFromST.garantie == 1
                          ? false
                          : ""
                      }
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Exécutée par : </Form.Label>
                    <Form.Control
                      required
                      list="userList"
                      type="text"
                      name="user_name"
                      autoComplete="off"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.user_name}
                    />
                    <datalist id="userList">
                      {NewTask.Users.filter((user) => user.profil_id === 1).map(
                        (user) => (
                          <option key={user.id}>{user.name}</option>
                        )
                      )}
                    </datalist>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Date previsionnel debut : </Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="date_previsionnelle_debut"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.date_previsionnelle_debut}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom02">
                    <Form.Label>Date fin realisation : </Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="date_fin_realisation"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.date_fin_realisation}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationCustom01">
                    <Form.Label>Observation : </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      type="text"
                      name="observation"
                      onChange={handelUpdateTaskFromST}
                      value={TaskToUpdateFromST.observation}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 imageSlider" md="2">
                  {TaskToUpdateFromST.images
                    ? TaskToUpdateFromST.images.map((imagesTask) => (
                        <div
                          className="images"
                          style={{ maxWidth: "15%" }}
                          key={imagesTask.id}
                        >
                          <img
                            className="w-100 d-block"
                            // itemId={imagesTask.id}
                            src={
                              "https://d3instal.com/images/" +
                              imagesTask.url_imgtache
                            }
                            alt="..."
                            onClick={() =>
                              showImage(imagesTask.url_imgtache, imagesTask.id)
                            }
                            style={{ aspectRatio: "3/3" }}
                          />
                        </div>
                      ))
                    : ""}
                </Row>
                <Button type="submit" variant="primary">
                  Envoyer
                </Button>
              </Form>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
}
