import React, { useContext, useEffect, useState } from "react";
import "../../../App.css";
import _ from "lodash";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import SIM from "./SGA/Sim";
import Gps from "./SGA/Gps";
import Accessoires from "./SGA/Accessoires";
import TechnicianContext from "../../context/TechnicienContext";
import pusher from "../../../pusher";
const pageSize = 5;

export default function AppCards() {
  const [table, setTable] = useState(null);
  const [task, setTask] = useState([]);
  const [paginaed, setPaginated] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const { getAppareil, appareil } = useContext(TechnicianContext);

  useEffect(() => {
    getAppareil();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAppareil();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  useEffect(() => {
    setTask(appareil.GPS);
    setPaginated(_(appareil.GPS).slice(0).take(pageSize).value());
  }, []);

  const pageCount = task ? Math.ceil(task.length / pageSize) : 0;
  const pages = _.range(1, pageCount + 1);
  const pagination = (pageNo) => {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const paginatedTask = _(task).slice(startIndex).take(pageSize).value();
    setPaginated(paginatedTask);
  };
  const handleTable = (e) => {
    const { id } = e.target;

    if (id == 1) {
      setTable(<Gps />);
    } else if (id == 2) {
      setTable(<SIM />);
    } else if (id == 3) {
      setTable(<Accessoires />);
    }
  };
  return (
    <>
      <MDBRow className="Careds">
        <MDBCol>
          <MDBCard className="cardStats pointer">
            <MDBCardBody id="1" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>Gps </MDBCardTitle>
              <span className="card-text">{appareil.NumberGps}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard className="cardStats pointer">
            <MDBCardBody id="2" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>SIM</MDBCardTitle>
              <span className="card-text">{appareil.NumberSim}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard className="cardStats pointer">
            <MDBCardBody id="3" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>Accessoires</MDBCardTitle>
              <span className="card-text">{appareil.NumberAccessory}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <div>
        {table == null ? (
          <div
            style={{
              width: "90%",
              padding: "2rem",
              marginTop: "1rem",
              marginLeft: "50px",
              borderRadius: "2rem",
            }}
          ></div>
        ) : (
          table
        )}
      </div>
    </>
  );
}
