import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import TechnicianContext from "../../../context/TechnicienContext";
import pusher from "../../../../pusher";
const Gps = ({ state, data }) => {
  const { appareil, getAppareil } = useContext(TechnicianContext);
  const [GPS, setGPS] = useState([]);
  const [pages, setPages] = useState(1);
  const [rowsPrePage, setRowsPrepage] = useState(10);
  const lastGPSIndex = pages * rowsPrePage;
  const firstGPSIndex = lastGPSIndex - rowsPrePage;
  const currentGPS = GPS.slice(firstGPSIndex, lastGPSIndex);
  useEffect(() => {
    setGPS(appareil.GPS);
  }, [state]);
  let numberOfPages = [];
  for (let p = 1; p <= Math.ceil(GPS.length / rowsPrePage); p++) {
    numberOfPages.push(p);
  }

  useEffect(() => {
    getAppareil();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAppareil();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const handleSearchGPS = (e) => {
    const value = e.target.value.toLowerCase();
    if (value != "") {
      const filterGPS = appareil.GPS.filter(
        (gps) =>
          gps.serial_number.toLowerCase().includes(value) ||
          gps.nameModele.toLowerCase().includes(value) ||
          gps.nameType.toLowerCase().includes(value) ||
          (gps.serial_numberCombination != null &&
            gps.serial_numberCombination.toLowerCase().includes(value))
      );
      if (filterGPS.length > 0) {
        setGPS(filterGPS);
        setPages(1);
      } else {
        setGPS([]);
      }
    } else {
      setGPS(appareil.GPS);
    }
  };
  return (
    <>
      <div className="tablesApp">
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <div
            className="bar-table"
            style={{ padding: "0rem 2rem 1rem 2rem " }}
          >
            <div>
              <h5>
                GPS &nbsp; <span className="vl"></span> &nbsp;{" "}
                <span style={{ fontSize: "15px" }}>
                  {" "}
                  {GPS.length} Appareils
                </span>{" "}
              </h5>
            </div>
          </div>
          <div className="search-appareil-technicien input-group rounded">
            <input
              type="search"
              className="form-control rounded Search-input"
              placeholder="Search IMEI, Modèle, Marque, Number SIM"
              aria-label="Search"
              aria-describedby="search-addon"
              onChange={handleSearchGPS}
            />
          </div>
          <MDBTable align="middle">
            <MDBTableHead className="table-ligh">
              <tr>
                <th scope="col"></th>
                <th scope="col">IMEI</th>
                <th scope="col">Number SIM</th>
                <th scope="col">Modèle </th>
                <th scope="col">Marque</th>
              </tr>
            </MDBTableHead>
            {currentGPS.length > 0
              ? currentGPS.map((GPS, index) => (
                  <MDBTableBody  key={index}>
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center"></div>
                      </td>
                      <td>
                        <p className="fw-normal mb-1">{GPS.serial_number}</p>
                      </td>
                      <td>
                        <p className="fw-normal mb-1">
                          {GPS.serial_numberCombination}
                        </p>
                      </td>
                      <td>
                        <p className="fw-normal mb-1">{GPS.nameModele}</p>
                      </td>
                      <td>{GPS.nameType}</td>
                    </tr>
                  </MDBTableBody>
                ))
              : "Aucun résultat trouvé"}
          </MDBTable>
          {GPS.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberOfPages.map((numberPage, index) => (
                  <MDBPaginationItem
                  key={index}
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPages(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Gps;
