import React, { useContext, useEffect, useState } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import TechnicianContext from "../context/TechnicienContext";
import { ShowImages } from "./ShowImages";

export default function RéparationRelais() {
  //useParams hook to get the id and status from the url redirecting you to this component
  const { id, statu } = useParams();
  //import all related logic ,states and function from technician context and use them within this component
  const {
    reparationRelais,
    setReparationRelais,
    handleFileEvent,
    handleReparationRelais,
    ReparationRelaisSubmit,
    errorMessage,
    getTasksDataForReparation,
    reparationTaskData,
    clientInfoTask,
  } = useContext(TechnicianContext);
  //get a task by its id once this componenent mounted
  useEffect(() => {
    getTasksDataForReparation(id);
  }, []);
  //useEffect hook to set the property "etatTache" of reparationRelai state with the value 2 which is related to a terminated task state in the backend

  const [vehiculeInfo, setVehiculeInfo] = useState(null);
  const hanleFilterByDevice = async (e) => {
    const getIdVehicule = await reparationTaskData?.allDeviceInstalled.filter(
      (idVehicule) => idVehicule.serial_number === e.target.value
    );
    if (getIdVehicule.length > 0) {
      setVehiculeInfo(...getIdVehicule);
    } else {
      setVehiculeInfo(null);
    }
  };
  console.log(reparationRelais);
  useEffect(() => {
    if (vehiculeInfo != null) {
      const vehicule = reparationTaskData.vehicules.filter(
        (articleInfo) => articleInfo.id === vehiculeInfo.vehicule_id
      )[0];
      setReparationRelais({
        ...reparationRelais,
        Matricule: vehicule ? vehicule.matricule : "",
        IMEI: vehiculeInfo ? vehiculeInfo.serial_number : "",
      });
    } else {
      setReparationRelais(null);
    }
  }, [vehiculeInfo]);
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "1rem",
        }}
      >
        <MDBContainer>
          <MDBCardHeader>
            <h5> Changelent du Relais</h5>
          </MDBCardHeader>
          <hr></hr>

          <Form noValidate>
            <Row className="mb-4">
              {/* Nom client */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Nom Client : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled
                  placeholder="Nom client"
                  value={clientInfoTask.client_name}
                />
              </Form.Group>
              {/* Ancienne IMEI */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>IMEI: </Form.Label>
                <Form.Control
                  list="IMEIList"
                  name="IMEI"
                  autoComplete="off"
                  placeholder="IMEI"
                  onChange={(e) => hanleFilterByDevice(e)}
                />
                <datalist id="IMEIList">
                  {reparationTaskData?.allDeviceInstalled.map((oldDevice) => (
                    <option key={oldDevice.id} id={oldDevice.id}>
                      {oldDevice.serial_number}
                    </option>
                  ))}
                </datalist>
              </Form.Group>

              {/*  Matricule */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label> Matricule : </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Matricule"
                  autoComplete="off"
                  name="Matricule"
                  value={reparationRelais ? reparationRelais.Matricule : ""}
                  onChange={handleReparationRelais}
                />
                {errorMessage && errorMessage.Matricule ? (
                  <span style={{ color: "red" }}>
                    {errorMessage.Matricule[0]}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>

              {/* Type du relais */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label> Type du relais : </Form.Label>
                <Form.Select
                  placeholder="type du Relais"
                  name="typeRelais"
                  onChange={handleReparationRelais}
                >
                  <option Selected hidden value="none">
                    Sélectionnez le relais
                  </option>
                  <option disabled value="none">
                    Sélectionnez le relais
                  </option>
                  <option value="normal">Normal</option>
                  <option value="special">Spéciale</option>
                </Form.Select>
                {errorMessage && errorMessage.typeRelais ? (
                  <span style={{ color: "red" }}>
                    {errorMessage.typeRelais[0]}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {/*images*/}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Images : </Form.Label>
                <Form.Control
                  required
                  name="images"
                  multiple
                  type="file"
                  accept="application/pdf,image/jpeg,image/png"
                  onChange={(e) => handleFileEvent(e, statu)}
                />
                {errorMessage && errorMessage.images ? (
                  <span style={{ color: "red" }}>{errorMessage.images[0]}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <ShowImages />
            </Row>

            <Row className="mb-4">
              {/* Observation */}

              <Form.Group as={Col} md="9" controlId="validationCustom04">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  style={{ height: "10rem" }}
                  as="textarea"
                  name="Observation"
                  required
                  type="file"
                  value={reparationRelais ? reparationRelais.Observation : ""}
                  onChange={handleReparationRelais}
                />
              </Form.Group>
            </Row>

            <Row md="6" className="mt-4 justify-content-center">
              <Button
                onClick={() => ReparationRelaisSubmit(id)}
                variant="primary"
              >
                Terminer Tâche
              </Button>
            </Row>
          </Form>
        </MDBContainer>
      </div>
    </div>
  );
}
