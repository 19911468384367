import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import { useNavigate } from "react-router-dom";
import STcontext from "../../context/STcontext";
import TechnicianContext from "../../context/TechnicienContext";
import axios from "../../context/axios";

export default function DetailsModel({data}) {
  
  const [show, setShow] = useState(false);
  const navTo = useNavigate();
  const values = [true];

  const [fullscreen, setFullscreen] = useState(true);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  const {getTaskInfo,NewTask} = useContext(STcontext);
  const {appareil,getAppareil,getAllTaskToConfirmed} = useContext(TechnicianContext)
  useEffect(()=>{
    getTaskInfo()
    getAppareil()
  }, [])


  const [updateTaskToConfirmed, setUpdateTaskToConfirmed] = useState({
    id:data.tache_id,
    clientName:data.client_name,
    IMEI:data.IMEI,
    typeTask:data.catache,
    telephoneClient:data.telephone_client,
    estimatedStartDate:data.date_previsionnelle_debut,
    observation:data.observation
  })

  const handleUpdateTaskToConfirmed =(e)=>{
    const name = e.target.name;
    const value = e.target.value;
    setUpdateTaskToConfirmed({...updateTaskToConfirmed,[name]:value});
  }

  const handlechangenamme = async (e, Clients) => {
    const nameClient = e.target.value;
    const name = e.target.name;
    const getphoneNumber = await Clients.filter((phoneNumber)=>phoneNumber.nomComplet == nameClient)
    setUpdateTaskToConfirmed({...updateTaskToConfirmed,clientName:nameClient,telephoneClient:getphoneNumber.map((phoneNumber)=>phoneNumber.telephone_client)[0]})
  };


  const sumbitUpdateTaskToConfirmed = async(e)=>
  {
    e.preventDefault();
    try
    {
      const updateTaskToConfirmedTask = await axios.put(`/api/technicien/UpdateTaskToConfirmed/${updateTaskToConfirmed.id}`,updateTaskToConfirmed)
      console.log(updateTaskToConfirmed);
      await getAllTaskToConfirmed()
      setShow(false)

    }
    catch (err)
    {
      console.log('error updating');
    }

  } 

  return (
    <div>

      {values.map((v, idx) => (
        <Button style={{ background:'none' , color:'black' , border:'none' }} key={idx}  onClick={() => handleShow(v)}>
          <AddCircleIcon />
          {typeof v === 'string' && `below ${v.split('-')[0]}`}
        </Button>
      ))}

      <Modal 
      show={show} size="lg" onHide={() => setShow(false)}>

        <Modal.Header closeButton>
          <Modal.Title>Détails tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{gap:'10px', marginBottom:'10px'}}>
        <Form noValidate method="POST" onSubmit={sumbitUpdateTaskToConfirmed}>
            
        <Row className="mb-3">
              <Form.Group as={Col} md="5" controlId="validationCustom01">
                <Form.Label>Nom client : </Form.Label>
                <Form.Control
                  required
                  list="nameClientList"
                  type="text"
                  name="clientName"
                  onChange={(e)=>handlechangenamme(e,NewTask.Clients)}
                  value={updateTaskToConfirmed.clientName}
                />
                <datalist id="nameClientList">
                  {NewTask.Clients.map((clientName)=>(
                    <option>{clientName.nomComplet}</option>
                  ))}
                </datalist>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Telephone: </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="telephoneClient"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.telephoneClient}
                />
                </Form.Group>
        
              
              

              {/* <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Modele Gps : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="modelGPS"
                  autoComplete="off"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.modelGPS ? updateTaskToConfirmed.modelGPS : "sans"}
                />
              </Form.Group> */}


              {/* <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>IMEI : </Form.Label>
                <Form.Control
                  required
                  list="IMEIList"
                  autoComplete="off"
                  type="text"
                  name="IMEI"
                  onChange={(e)=>handleChooseIMEI(e,appareil.GPS)}
                  value={updateTaskToConfirmed.IMEI}
                />
                 <datalist id="IMEIList">
                  {appareil.GPS.map((imeiNumber)=>(
                    <option value={imeiNumber.serial_number}>{imeiNumber.serial_number}</option>
                  ))}
                </datalist>
              </Form.Group> */}
              
            </Row>


        <Row className="mb-3">
        {/* <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Prop App : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="gpsOwner"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.gpsOwner == 1 ? "interne":"client"}
                />
              </Form.Group> */}

              {/* <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>position d'app installé: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="appPosition"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.appPosition == 0 ? "principale" :"secondaire"}
                  
                />
              </Form.Group> */}

              {/* <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>SIM : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="numberSim"
                  onChange={handleUpdateTaskToConfirmed}
                  value={simCombination?simCombination.serial_numberCombination:''}
                />
              </Form.Group> */}
             
              {/* <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Modele SIM : </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="modelSim"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.modelSim ? updateTaskToConfirmed.modelSim : "sans"}
                />
              </Form.Group> */}
            
            <Form.Group as={Col} md="5" controlId="validationCustom02">
                <Form.Label>Type du tâche : </Form.Label>
                <Form.Control
                  type="text"
                  list="catagoryTaskList"
                  autoComplete="off"
                  name="typeTask"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.typeTask}
                />
                 <datalist id="catagoryTaskList">
                  {NewTask.TypesTasks.map((categoryTask)=>(
                    <option value={categoryTask.catache}>{categoryTask.catache}</option>
                  ))}
                </datalist>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Date previsionnel debut : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="estimatedStartDate"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.estimatedStartDate ? updateTaskToConfirmed.estimatedStartDate : "sans"}                  
                />
              </Form.Group>

              
            </Row>

        <Row className="mb-3">
              {/* <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>SIM : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={data.SIM ? data.SIM :"sans"}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Prop SIM : </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="simOwner"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.simOwner == 0 ? "interne":"client"}
                  
                />
              </Form.Group> */}
              {/* <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Type Relais : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="typeAccesoryestimatedStartDate"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.typeAccesoryestimatedStartDate ? updateTaskToConfirmed.typeAccesoryestimatedStartDate:" pas d'accesoires"}
                />
              </Form.Group> */}

              {/* <Form.Group as={Col} md="3" controlId="validationCustom02">
                  <Form.Label>Garantie : </Form.Label>
                    <Form.Check
                    type="checkbox"
                    label = "sans"
                    checked={data.garantie == 0 ?true:false}
                  />
                 
            </Form.Group> */}
            </Row>


        <Row className="mb-3">        


              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  as="textarea" rows={3}
                  type="text"
                  name="observation"
                  onChange={handleUpdateTaskToConfirmed}
                  value={updateTaskToConfirmed.observation ? updateTaskToConfirmed.observation :"sans"}
                />
              </Form.Group>
            </Row>

            <Modal.Footer>
 
 <Button type="submit" variant="primary">
   Envoyer
 </Button>
</Modal.Footer>
            </Form>
        </Modal.Body>
            {/* <div style={{ display:'flex',flexWrap:'wrap' }}>
                <div className="images" style={{ width:'10%' }}> 
                <img src={logo} width="100%" />
                <img src={logo} width="100%" />
                <img src={logo} width="100%" />
                </div>
      
        
            </div> */}
      </Modal>
    </div>
  );
}