import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CompleteTask } from "./CompleteTask";
import { DelayTask } from "./DelayTask";
import { CancelTask } from "./CancelTask";

export const EndTaskST = () => {
  const { state } = useLocation();
  const { taskStatus } = useParams();
  const [bodyForm, setBodyForm] = useState();
  const renderBodyForm = () => {
    switch (taskStatus) {
      case "terminer":
        setBodyForm(<CompleteTask detailsTask={state} />);
        break;
      case "reporter":
        setBodyForm(<DelayTask detailsTask={state} />);
        break;
      case "annuler":
        setBodyForm(<CancelTask detailsTask={state} />);
        break;
    }
  };
  useEffect(() => {
    renderBodyForm();
  }, []);
  return <div>{bodyForm}</div>;
};
