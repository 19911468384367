import React from 'react'
import AppCards from './AppCards'
import './AppStyle.css'
import ModelAppareil from './ModelAppareil';

export default function Parametrage() {
  return (
    <div>
     
    <AppCards/>
    </div>
  )
}
