import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import Button from "react-bootstrap/Button";
import { InputText } from "primereact/inputtext";
import TechnicianContext from "../../context/TechnicienContext";
import pusher from "../../../pusher";
export const AppareilsDésinstallés = () => {
  const {
    devicesReturned,
    getReturnedDevices,
    returnedToST,
    handleAffectedArticleOption,
  } = useContext(TechnicianContext);
  useEffect(() => {
    getReturnedDevices();
  }, []);

  const channel = pusher.subscribe("Installer_realTime");
  channel.bind("realTimeData", (data) => {
    getReturnedDevices();
  });

  //end connect to pusher

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [rowClick, setRowClick] = useState(true);
  const [selectedAppareils, setSelectedAppareils] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    if (selectedAppareils != null) {
      const appareilsId = selectedAppareils.map((appareil) => appareil.id);
      handleAffectedArticleOption(appareilsId);
    }
  }, [selectedAppareils]);

  useEffect(() => {
    setSelectedAppareils(null);
  }, [devicesReturned]);

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div style={{ padding: "20px 50px 30px 50px" }}>
      <div
        className="bar-table"
        style={{ padding: "10px 30px 10px 30px" }}
      ></div>
      <div className="tâches-tech-container">
        <div className="Table-tech" style={{ padding: "2rem" }}>
          <div>
            <h5>
              GPS retournées &nbsp; <span class="vl"></span> &nbsp;{" "}
              <span style={{ fontSize: "15px" }}>
                {" "}
                {devicesReturned ? devicesReturned.length : 0} appareils
              </span>{" "}
            </h5>
          </div>
          <DataTable
            value={devicesReturned}
            header={header}
            filters={filters}
            selectionMode={rowClick ? null : "checkbox"}
            selection={selectedAppareils}
            onSelectionChange={(e) => setSelectedAppareils(e.value)}
            dataKey="id"
            removableSort
            paginator
            rows={15}
            rowsPerPageOptions={[15, 25, 50, 100]}
            tableStyle={{
              minWidth: "50rem",
              backgroundColor: "#F24405",
            }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>

            <Column
              field="nomComplet"
              header="Nom du client"
              sortable
              style={{ width: "25%", color: "#000", padding: "1rem" }}
              bodyStyle={{ fontWeight: "bold" }}
            ></Column>

            <Column
              field="serial_number"
              header="IMEI"
              sortable
              style={{ width: "20%", padding: "1rem" }}
            ></Column>
            <Column
              field="nameModele"
              header="Type d'appareil"
              sortable
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="matricule"
              header="Matricule"
              sortable
              style={{ width: "20%" }}
            ></Column>

            <Column
              field="dataReturned"
              header="Date retour"
              sortable
              style={{ width: "30%" }}
            ></Column>
          </DataTable>
          <div
            style={{
              display: "flex",
              width: "40%",
              gap: "20px",
              padding: "2rem",
            }}
          >
            <Button
              type="submit"
              variant="primary"
              onClick={(e) => returnedToST(e, selectedAppareils)}
            >
              retourner à ST
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
