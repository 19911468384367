import React, { useContext, useEffect } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechnicianContext from "../context/TechnicienContext";
import { useParams } from "react-router-dom";

export default function Reportée() {
  //technician context to get all related states and functions of technician context
  const { getTask, reporte, handleAddReporte, reporteSubmit, clientInfoTask } =
    useContext(TechnicianContext);
  //get the id and the report params from the url redirecting you to this component
  const { id } = useParams();
  //get task by its id once the componenet mounted
  useEffect(() => {
    getTask(id);
  }, []);
  //useEffect hook to set the property "etatTache" of reporte state with the value 3 which is related to a reported task state in the backend
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "1rem",
        }}
      >
        <MDBContainer>
          <MDBCardHeader>
            <h5>Tâches reportée</h5>
          </MDBCardHeader>
          <hr></hr>

          <Form noValidate>
            <Row className="mb-4">
              {/* Nom client */}

              <Form.Group as={Col} md="4" controlId="validationCustom04">
                <Form.Label>Nom Client : </Form.Label>
                <Form.Control
                  disabled
                  required
                  name="nomComplet"
                  type="text"
                  placeholder="Nom client"
                  value={clientInfoTask.client_name}
                />
              </Form.Group>
              {/* Date */}

              <Form.Group as={Col} md="4" controlId="validationCustom04">
                <Form.Label>Nouvelle date : </Form.Label>
                <Form.Control
                  required
                  type="date"
                  name="NouvelleDate"
                  placeholder="Nouvell eDate"
                  onChange={handleAddReporte}
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              {/* Observation */}

              <Form.Group as={Col} md="8" controlId="validationCustom04">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  style={{ height: "10rem" }}
                  as="textarea"
                  name="Observation"
                  required
                  onChange={handleAddReporte}
                />
              </Form.Group>
            </Row>

            <Row md="6" className="mt-4 justify-content-center">
              <Button onClick={() => reporteSubmit(id)} variant="primary">
                Reporter Tâches
              </Button>
            </Row>
          </Form>
        </MDBContainer>
      </div>
    </div>
  );
}
