import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

export const MoreDetail = ({ moreDetail }) => {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Details
      </Button>
      <Modal
        show={modalShow}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Plus Detail: {moreDetail.client_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <Row>
              <Col xs={6} md={6}>
                Numéro Téléphone:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.telephone_client}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Type du tâche:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.catache}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Date Previsionnelle début:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.date_previsionnelle_debut}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Matricule:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.matricule ? moreDetail.matricule : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Type d’appareil:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.nameModeleGPS ? moreDetail.nameModeleGPS : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                IMEI:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.IMEI ? moreDetail.IMEI : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Position GPS:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.gps_principale === 0
                  ? "1er/ première"
                  : moreDetail.gps_principale === 1
                  ? "2eme/ deuxième"
                  : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Propriétaire GPS:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.proprietaire === 0
                  ? "D3 HI-TECH"
                  : moreDetail.proprietaire === 1
                  ? "Client"
                  : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                type Relais:
              </Col>
              <Col xs={12} md={6}>
                {/* {moreDetail.vehicules &&
                moreDetail.vehicules.articles &&
                moreDetail.vehicules.articles.filter(
                  (relais) => relais.modele_id === 21
                ).length > 0
                  ? "Spécial"
                  : moreDetail.vehicules &&
                    moreDetail.vehicules.articles &&
                    moreDetail.vehicules.articles.filter(
                      (relais) => relais.modele_id === 22
                    )
                  ? "normal"
                  : "--"} */}
                {moreDetail.accesoriesModel || "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Type du SIM:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.nameModeleSIM ? moreDetail.nameModeleSIM : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Num Sim:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.SIM ? moreDetail.SIM : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Propriétaire SIM:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.SIM_proprietaire === 0
                  ? "D3 HI-TECH"
                  : moreDetail.SIM_proprietaire === 1
                  ? "Client"
                  : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Accessoires:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.vehicules &&
                moreDetail.vehicules.articles.length > 0
                  ? "have acc"
                  : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Date début:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.date_debut_realisation
                  ? moreDetail.date_debut_realisation
                  : "--/--/--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Observation:
              </Col>
              <Col xs={12} md={6}>
                {moreDetail.observation ? moreDetail.observation : "--"}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
