import _ from "lodash";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Form from "react-bootstrap/Form";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import STcontext from "../../../context/STcontext";
import pusher from "../../../../pusher";
import { ConfirmationEndroitAppareil } from "./ConfirmationEndroitAppareil";

const Appareilretournées = () => {
  const {
    confirmeAcceptanceOfTheDevice,
    getReturnedDevices,
    apprareilReturned,
    handleGetAllModeles,
  } = useContext(STcontext);

  //connect to pusher
  useEffect(() => {
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getReturnedDevices();
    });
  }, []);

  //end connect to pusher

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const Navigate = useNavigate();

  useEffect(() => {
    getReturnedDevices();
    handleGetAllModeles();
  }, []);

  const handleSelectChange = (e) => {
    e.preventDefault();
    const etatTache = e.target.value;
    if (etatTache == 1) {
      Navigate("/supporttechnique/appareilretournées/Sim");
    }
  };
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const renderConfirmedDevice = (rowDate) => {
    return (
      <>
        <ConfirmationEndroitAppareil
          data={rowDate}
          ConfirnationFunction={confirmeAcceptanceOfTheDevice}
        />
      </>
    );
  };

  return (
    <div style={{ padding: "20px 50px 30px 50px" }}>
      <div className="Table-tech" style={{ padding: "2rem" }}>
        <div className="bar-table" style={{ padding: "10px 30px 10px 30px" }}>
          <div>
            <h5>
              GPS retournées &nbsp; <span class="vl"></span> &nbsp;{" "}
              <span style={{ fontSize: "15px" }}>
                {" "}
                {apprareilReturned.getAllDevice
                  ? apprareilReturned.getAllDevice.length
                  : ""}{" "}
                appareils
              </span>{" "}
            </h5>
          </div>
          <div class="search input-group rounded">
            <Form.Select
              onChange={(e) => handleSelectChange(e)}
              className="tâches-statut"
              aria-label="Default select example"
            >
              <option value="" selected disabled hidden>
                Veuillez choisir un option{" "}
              </option>
              <option value="" disabled>
                Veuillez choisir un option{" "}
              </option>
              <option value={0}>GPS retournée </option>
              <option value={1}>Sim retournées</option>
            </Form.Select>
          </div>
        </div>
        <DataTable
          value={apprareilReturned.getAllDevice}
          header={header}
          filters={filters}
          dataKey="id"
          removableSort
          paginator
          rows={15}
          rowsPerPageOptions={[15, 25, 50, 100]}
          tableStyle={{
            minWidth: "50rem",
            backgroundColor: "#F24405",
          }}
        >
          <Column
            header="#"
            headerStyle={{ width: "3rem" }}
            body={(data, options) => options.rowIndex + 1}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            field="nomComplet"
            header="Nom du client"
            sortable
            style={{ width: "22%", color: "#000" }}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            field="serial_number"
            header="IMEI"
            sortable
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="nameModele"
            header="Type d'appareil"
            sortable
            style={{ width: "10%" }}
          ></Column>
          <Column
            field="matricule"
            header="Matricule"
            sortable
            style={{ width: "30%" }}
          ></Column>
          <Column
            field="dataReturned"
            header="Date retour"
            sortable
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="name"
            header="Retourne par"
            sortable
            style={{ width: "25%" }}
          ></Column>
          <Column
            header="Confirmer"
            sortable
            body={renderConfirmedDevice}
            style={{ width: "25%", padding: "10px" }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Appareilretournées;
