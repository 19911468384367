import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import STcontext from "../context/STcontext";
export default function DetailsModel({ data }) {
  const { NewTask, confirmTask, setErrorsMessages, errorsMessages } =
    useContext(STcontext);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setErrorsMessages(null);
  };

  const filtered = NewTask.Users.filter(
    (user) => user.profil_id === 1 || user.profil_id === 2
  );

  const [UpdateTaskToConfirmed, setUpdateTaskToConfirmed] = useState({
    nomClient: data.nomComplet,
    telephoneClient: data.telephone_client,
    caTache: data.catache,
    date_previsionnelle_debut: data.date_previsionnelle_debut,
    Observation: data.observation,
    ExecutedBy: data.user_name,
    city: data.city_id,
  });

  const handleUpdateTaskToConfirmed = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUpdateTaskToConfirmed({ ...UpdateTaskToConfirmed, [name]: value });
  };

  const filterCity = {
    ...(NewTask && NewTask.Cities.find((city) => city.id === data.city_id)),
  };

  useEffect(() => {
    handleClose();
  }, [data]);

  return (
    <div>
      <Button
        style={{ color: "white", border: "none", margin: "10px" }}
        onClick={() => setShow(true)}
      >
        Confirmer
      </Button>

      <Modal show={show} size="lg" onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Détails tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ gap: "20px", marginBottom: "10px" }}>
          <Form
            noValidate
            method="POST"
            onSubmit={(e) => confirmTask(e, UpdateTaskToConfirmed, data.id)}
          >
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Nom client : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="nomClient"
                  onChange={handleUpdateTaskToConfirmed}
                  value={UpdateTaskToConfirmed.nomClient}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Telephone client : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="telephoneClient"
                  onChange={handleUpdateTaskToConfirmed}
                  value={UpdateTaskToConfirmed.telephoneClient}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Type du tâche : </Form.Label>
                <Form.Control
                  type="text"
                  name="caTache"
                  list="dataTask"
                  onChange={handleUpdateTaskToConfirmed}
                  value={UpdateTaskToConfirmed.caTache}
                />
                <datalist id="dataTask">
                  {NewTask.TypesTasks.map((TypesTask, index) => (
                    <option key={index}>{TypesTask.catache}</option>
                  ))}
                </datalist>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Exécutée par : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="ExecutedBy"
                  value={
                    UpdateTaskToConfirmed.ExecutedBy
                      ? UpdateTaskToConfirmed.ExecutedBy
                      : ""
                  }
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Date previsionnel debut : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  onChange={handleUpdateTaskToConfirmed}
                  name="date_previsionnelle_debut"
                  value={
                    UpdateTaskToConfirmed.date_previsionnelle_debut
                      ? UpdateTaskToConfirmed.date_previsionnelle_debut
                      : ""
                  }
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="">
                <Form.Label>Affectée à : </Form.Label>
                <Form.Control
                  list="userList"
                  name="affecte"
                  autoComplete="off"
                  required
                  onChange={handleUpdateTaskToConfirmed}
                />
                <datalist id="userList">
                  {filtered
                    ? filtered.map((user, index) => (
                        <option
                          key={index}
                          onChange={() =>
                            setUpdateTaskToConfirmed({
                              ...UpdateTaskToConfirmed,
                              TechnicianID: user.name,
                            })
                          }
                        >
                          {user.name}
                        </option>
                      ))
                    : ""}
                </datalist>
                <span style={{ color: "red" }}>
                  {errorsMessages && errorsMessages.affecte
                    ? errorsMessages.affecte[0]
                    : ""}
                </span>
              </Form.Group>
            </Row>
            <Row>
              {" "}
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>réalisée à: </Form.Label>
                <Form.Select
                  name="city"
                  autoComplete="off"
                  onChange={handleUpdateTaskToConfirmed}
                >
                  <option selected hidden>
                    {filterCity && filterCity.cityName
                      ? filterCity.cityName
                      : "select ville"}
                  </option>
                  {NewTask &&
                    NewTask.Cities.map((city, index) => (
                      <option key={index} value={city.id}>
                        {city.cityName}
                      </option>
                    ))}
                </Form.Select>
                <span style={{ color: "red" }}>
                  {errorsMessages && errorsMessages.city
                    ? errorsMessages.city[0]
                    : ""}
                </span>
              </Form.Group>
            </Row>
            <Row>
              {" "}
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="Observation"
                  value={
                    UpdateTaskToConfirmed.Observation
                      ? UpdateTaskToConfirmed.Observation
                      : ""
                  }
                  onChange={handleUpdateTaskToConfirmed}
                />
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button className="mb-3" type="submit" variant="primary">
                Confirmer tâche
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
