import React, { memo, useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CommercialContext from "../../context/CommercialContext";

const UpdateTaskToConfirm = ({ taskToUpdate, disabledButton }) => {
  const {
    NewTask,
    UpdateTaskToConfirmPreClientSubmit,
    setShowEditTaskToConfirm,
    tasksPreClient,
  } = useContext(CommercialContext);
  // const handleShow = () => setShowEditTaskToConfirm(true);
  // const handleClose = () => setShowEditTaskToConfirm(false);
  const [UpdateTask, setUpdateTask] = useState({
    id: taskToUpdate.id,
    client_id: taskToUpdate.client_id,
    typeTask: taskToUpdate.cataches.catache,
    estimatedStartDate: taskToUpdate.date_previsionnelle_debut,
    observation: taskToUpdate.observation,
    city: taskToUpdate.city != null && taskToUpdate.city.cityName,
  });

  useEffect(() => {
    setModalShow(false);
  }, [tasksPreClient]);

  const [modalShow, setModalShow] = useState(false);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUpdateTask({ ...UpdateTask, [name]: value });
  };

  return (
    <>
      <div>
        <Button
          disabled={disabledButton}
          style={{ border: "none" }}
          variant="none"
          onClick={() => setModalShow(true)}
        >
          <BorderColorIcon sx={{ color: "#00c496" }} />
        </Button>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modifier Tâche
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            method="POST"
            onSubmit={(e) => UpdateTaskToConfirmPreClientSubmit(e, UpdateTask)}
          >
            <Form.Group
              as={Col}
              className="mb-3"
              controlId="validationCustom02"
            >
              <Form.Label>Type tâche: </Form.Label>
              <Form.Control
                list="dataTask"
                name="typeTask"
                className="mb-3"
                autoComplete="off"
                value={UpdateTask.typeTask}
                onChange={handleChange}
              />
              <datalist id="dataTask">
                {NewTask.TypesTasks.map((TypesTask) => (
                  <option key={TypesTask.id} data-value={TypesTask.id}>
                    {TypesTask.catache}
                  </option>
                ))}
              </datalist>
            </Form.Group>
            <Form.Group as={Col} controlId="validationCustom02">
              <Form.Label>Date:</Form.Label>
              <Form.Control
                type="datetime-local"
                name="estimatedStartDate"
                className="mb-3"
                autoComplete="off"
                value={UpdateTask.estimatedStartDate}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="">
              <Form.Label>réalisée à : </Form.Label>
              <Form.Select
                name="clientCity"
                autoComplete="off"
                onChange={handleChange}
              >
                <option selected hidden>
                  {UpdateTask.city || "select ville"}
                </option>

                <option disabled>select ville</option>
                {NewTask &&
                  NewTask.Cities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.cityName}
                    </option>
                  ))}
              </Form.Select>
              {/* <span style={{ color: "red" }}>
                {error && error.clientCity ? error.clientCity[0] : ""}
              </span> */}
            </Form.Group>
            <Form.Group as={Col} controlId="">
              <Form.Label>Observation: </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                name="observation"
                className="mb-3"
                autoComplete="off"
                value={UpdateTask.observation}
                onChange={handleChange}
              />
            </Form.Group>

            <Modal.Footer>
              <Button type="submit" className="Modelbutton" variant="primary">
              Enregistrer les modifications
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateTaskToConfirm;
