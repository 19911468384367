import React, { useContext, useState, useEffect, memo } from "react";
import Cards from "./Cards";
import Form from "react-bootstrap/Form";
import AppCards from "./AppCards";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Taches from "../Tâches/Taches";
import ClearIcon from "@mui/icons-material/Clear";
import "./style/dashboard.css";
import TuneIcon from "@mui/icons-material/Tune";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Filler,
  plugins,
} from "chart.js";
import ReactApexChart from "react-apexcharts";
import { AuthContext } from "../context/AuthContext";
import STcontext from "../context/STcontext";
import GpsTable from "./Gpstable";
import SimTable from "./SimTable";
import pusher from "../../pusher";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  plugins,
  Title,
  Filler
);

function Dashboard() {
  const { USE } = useContext(AuthContext);
  const {
    getTask,
    statisticsTasksDashboard,
    getAppareil,
    getAppareilInstalle,
    filteringPerDate,
    appareilInstalle,
    isFilterTask,
    setIsFilterTask,
    statisticsTasks,
    getStatisticsTasks,
    getStatisticsTasksTechnicien,
    handleChangeDateStatistics,
  } = useContext(STcontext);

 
  const [filterTask, setFilterTask] = useState({
    date: {
      date_presionnel_début: "1",
      date_presionnel_fin: "1",
    },
    profil: "1",
  });

  useEffect(() => {
    getTask(
      filterTask.date.date_presionnel_début,
      filterTask.date.date_presionnel_fin,
      filterTask.profil
    );
    getAppareil();

    getAppareilInstalle(
      filterTask.date.date_presionnel_début,
      filterTask.date.date_presionnel_fin
    );
  }, []);

  useEffect(() => {
    if (isFilterTask) {
      const dateFilter = JSON.parse(sessionStorage.getItem("dateFilter"));
      setFilterTask({ ...filterTask, date: dateFilter });
      filteringPerDate(
        dateFilter.date_presionnel_début,
        dateFilter.date_presionnel_fin,
        filterTask.profil
      );
    }
  }, [isFilterTask]);

  const handleChangeDate = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilterTask({
      ...filterTask,
      date: { ...filterTask.date, [name]: value },
    });
  };

  useEffect(() => {
    getStatisticsTasksTechnicien();
  }, [getStatisticsTasks]);

  useEffect(() => {
    // geTaskTechnician();
    getAppareil();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getTask(
        filterTask.date.date_presionnel_début,
        filterTask.date.date_presionnel_fin,
        filterTask.profil
      );
      getAppareil();

      getAppareilInstalle(
        filterTask.date.date_presionnel_début,
        filterTask.date.date_presionnel_fin
      );
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      type: "datetime",
      categories: statisticsTasks[0]?.labelArray,
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };

  const clearFilterTaskTechnicien = () => {
    setIsFilterTask(false);
    getStatisticsTasksTechnicien();
    setFilterTask({
      date: { date_presionnel_début: "1", date_presionnel_fin: "1" },
      profil: 1,
    });
    getTask(
      (filterTask.date_presionnel_début = "1"),
      (filterTask.date_presionnel_fin = "1"),
      (filterTask.profil = "1")
    );
    getAppareilInstalle(
      (filterTask.date_presionnel_début = "1"),
      (filterTask.date_presionnel_fin = "1"),
      (filterTask.profil = "1")
    );
    sessionStorage.clear();
  };
  return (
    <div>
      <div className="dashcontainer">
        <Row className="mb-3">
          {USE.profil_id != 28 ? (
            <Form.Group as={Col} controlId="validationCustom04">
              <Form.Select
                onChange={(e) =>
                  setFilterTask((old) => {
                    return {
                      ...old,
                      profil: e.target.value,
                    };
                  })
                }
              >
                <option disabled>Veuillez choisir un profil</option>
                <option value="1">Technicien</option>
                <option value="2">Support technique</option>
              </Form.Select>
            </Form.Group>
          ) : (
            ""
          )}

          <Form.Group as={Col}>
            <Form.Control
              type="date"
              name="date_presionnel_début"
              onChange={handleChangeDate}
              value={filterTask.date.date_presionnel_début}
            ></Form.Control>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Control
              type="date"
              name="date_presionnel_fin"
              onChange={handleChangeDate}
              value={filterTask.date.date_presionnel_fin}
            ></Form.Control>
          </Form.Group>
          <Form.Group
            className="btn-validate dashboard-Filter-buttons"
            as={Col}
            md="4"
            controlId="validationCustom04"
          >
            <Button
              type="submit"
              onClick={() =>
                filteringPerDate(
                  filterTask.date.date_presionnel_début,
                  filterTask.date.date_presionnel_fin,
                  filterTask.profil
                )
              }
              variant="primary"
              data-test="dd"
            >
              <TuneIcon />
              Valider
            </Button>
            <Button variant="danger" onClick={clearFilterTaskTechnicien}>
              {" "}
              <ClearIcon />
              Annule
            </Button>
          </Form.Group>
        </Row>
      </div>

      <h4>Statistiques des tâches </h4>
      <br />
      <Cards data={statisticsTasksDashboard} />
      <br /> <br />
      <h4>Statistiques des appareils</h4>
      <br />
      <AppCards data={appareilInstalle} />
      <div style={{ display: "flex", justifyContent: "center", width: "95%" }}>
        <div className="chart" style={{ padding: "20px", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h5 className="chart-title">Stats tâche</h5>
            </div>
            <div style={{ display: "flex", gap: "30px" }}>
              <Form.Select
                color="success"
                style={{
                  paddingLeft: "1.2rem",
                  marginBottom: "1.2rem",
                }}
                aria-label="Default select example"
                name="categoreyTask"
                onChange={handleChangeDateStatistics}
              >
                <option disabled>Type du tâche</option>
                <option value="1" default>
                  Installation
                </option>
                <option value="2">Désinstallation</option>
                <option value="3">Réinstallation</option>
                <option value="4">Réparation SIM</option>
                <option value="5">Réparation GPS </option>
                <option value="6">Réparation Relais</option>
              </Form.Select>
              <Form.Select
                color="success"
                style={{
                  paddingLeft: "1.2rem",
                  marginBottom: "1.2rem",
                }}
                aria-label="Default select example"
                name="date"
                onChange={handleChangeDateStatistics}
              >
                <option disabled>date</option>

                <option value="weeks" default>
                  jours
                </option>
                <option value="months">semaines</option>
                <option value="years">mois</option>
                <option value="allYears">années</option>
              </Form.Select>
            </div>
          </div>

          <ReactApexChart
            options={options}
            series={statisticsTasks}
            type="bar"
            height={500}
          />
        </div>
      </div>
      <div className="chart-dash">
        <div style={{ marginTop: "2rem", minWidth: "20rem" }}>
          <GpsTable GpsInstall={appareilInstalle} />
        </div>
        <div style={{ marginTop: "2rem", minWidth: "20rem" }}>
          <SimTable SimInstall={appareilInstalle} />
        </div>
      </div>
      <div className="tâches-dash">
        <Taches />
      </div>
    </div>
  );
}

export default memo(Dashboard);
