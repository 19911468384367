import React, { useContext, useState } from "react";
import "./ConfirmPopup.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import CommercialContext from "../context/CommercialContext";
import { useEffect } from "react";

const ConfirmPopup = ({ message, id, deleteFunction }) => {
  const { task } = useContext(CommercialContext);
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  useEffect(() => {
    handleClose();
  }, [task]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "25px 2px",
        }}
      >
        <Button variant="danger" onClick={handleShow}>
          <DeleteIcon />
        </Button>
      </div>

      <Modal
        show={modalShow}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={(e) => deleteFunction(id)}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmPopup;
