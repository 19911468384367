import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useContext } from "react";
import STcontext from "../../context/STcontext";
import axios from "../../context/axios";

export default function NouvelleTâches({}) {
  const {
    getTaskInfo,
    NewTask,
    handleAddTaskToTechnicien,
    AddNewTask,
    checkbox,
    checkboxsim,
    checkboxaccessoire,
    show,
    handleCheckBoxAccChange,
    handleCheckBoxSimChange,
    handleCheckboxChange,
    setAddNewTask,
    addTaskToTechnicien,
    setShow,
  } = useContext(STcontext);

  const handleShow = () => setShow(true);
  const [filteredUser, setFilterUser] = useState([]);

  useEffect(() => {
    getTaskInfo();
  }, []);

  useEffect(() => {
    if (checkbox == true) {
      setAddNewTask({ ...AddNewTask, GPSOwner: true });
    } else if (checkbox == false) {
      setAddNewTask({ ...AddNewTask, GPSOwner: false });
    }
  }, [checkbox]);

  useEffect(() => {
    if (checkboxsim == true) {
      setAddNewTask({ ...AddNewTask, SIMOwner: true });
    } else if (checkboxsim == false) {
      setAddNewTask({ ...AddNewTask, SIMOwner: false });
    }
  }, [checkboxsim]);

  const handlechangeProfil = (e, Users) => {
    const profil = e.target.value;
    if (profil == 1) {
      setFilterUser(Users.filter((user) => user.profil_id == 1));
    } else if (profil == 2) {
      setFilterUser(Users.filter((user) => user.profil_id == 2));
    }
    setAddNewTask({ ...AddNewTask, taskFor: profil });
  };

  //filter number telephone by client

  const [filterTelephone, setFilterTelephone] = useState([]);

  const filterTelephoneByNameClient = (e, Clients) => {
    const nameClient = e.target.value;
    const name = e.target.name;

    const getphoneNumber = Clients.filter(
      (phoneNumber) => phoneNumber.nomComplet == nameClient
    );
    setFilterTelephone(getphoneNumber);
    setAddNewTask({ ...AddNewTask, [name]: nameClient });
  };

  const handleClose = () => {
    setShow(false);
    setFilterTelephone("");
    setAddNewTask({
      Quantite: 1,
    });
  };

  useEffect(() => {
    setAddNewTask({
      ...AddNewTask,
      telephone: filterTelephone
        ? filterTelephone.map((phoneNumber) => phoneNumber.telephone_client)[0]
        : "",
      emailClient: filterTelephone
        ? filterTelephone.map((emailClient) => emailClient.email_client)[0]
        : "",
    });
  }, [filterTelephone]);

  useEffect(() => {
    if (AddNewTask.Quantite == "") {
      setAddNewTask({ ...AddNewTask, Quantite: 1 });
    }
  }, [AddNewTask]);

  return (
    <div>
      <Button
        className="nouveauProfilButton"
        style={{ width: "100%" }}
        onClick={handleShow}
      >
        Nouvelle tâche
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouvelle tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={addTaskToTechnicien}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Nom complet : </Form.Label>
                <Form.Control
                  list="data"
                  name="nomComplet"
                  autoComplete="off"
                  onChange={(e) =>
                    filterTelephoneByNameClient(e, NewTask.Clients)
                  }
                  value={AddNewTask.nomComplet}
                />
                <datalist id="data">
                  {NewTask.Clients.map((client) => (
                    <option>{client.nomComplet}</option>
                  ))}
                </datalist>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Telephone : </Form.Label>
                <Form.Control
                  list="phoneNumber"
                  name="telephone"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.telephone}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Email : </Form.Label>
                <Form.Control
                  list="emailList"
                  name="emailClient"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.emailClient}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Type de la tâche : </Form.Label>
                <Form.Control
                  list="dataTask"
                  name="typeTache"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.typeTache}
                />
                <datalist id="dataTask">
                  {NewTask.TypesTasks.map((TypesTask) => (
                    <option>{TypesTask.catache}</option>
                  ))}
                </datalist>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="">
                <Form.Label>Quantité: </Form.Label>
                <Form.Control
                  type="number"
                  name="Quantite"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.Quantite}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Date : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="Date"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.Date}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Pour : </Form.Label>

                <Form.Select
                  onChange={(e) => handlechangeProfil(e, NewTask.Users)}
                  name="pour"
                  autoComplete="off"
                >
                  <option value="none" selected disabled hidden>
                    Tache Pour
                  </option>
                  <option disabled>Tache Pour</option>
                  <option value={1}>Technicien</option>
                  <option value={2}>Bureau</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="">
                <Form.Label>Affectée à : </Form.Label>
                <Form.Control
                  list="userList"
                  name="affecté"
                  autoComplete="off"
                  onChange={handleAddTaskToTechnicien}
                  value={AddNewTask.affecté}
                />
                <datalist id="userList">
                  {filteredUser
                    ? filteredUser.map((user) => <option>{user.name}</option>)
                    : ""}
                </datalist>
              </Form.Group>

              
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Propriétere de l'appareil: </Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Client"
                  name="GPSOwner"
                  onChange={handleCheckboxChange}
                  value={checkbox}
                  // checked={D}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Propriétere de la carte SIM : </Form.Label>
                <Form.Check
                  type="checkbox"
                  label="client"
                  name="SIMOwner"
                  onChange={handleCheckBoxSimChange}
                  value={checkboxsim}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  placeholder="description"
                  name="Description"
                  value={AddNewTask.Description}
                  as="textarea"
                  rows={3}
                  onChange={handleAddTaskToTechnicien}
                />
              </Form.Group>
            </Row>

            <Modal.Footer>
              <Button type="submit" variant="primary">
                Envoyer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
