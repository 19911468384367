import React, { useContext } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ComptableContext from "../../../../context/ComptableContext";
import axios from "../../../../context/axios";
import UpdateType from "./UpdateType";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Gpsaddmodel({ id, data, appareil_id }) {
  const { getTypesgps } = useContext(ComptableContext);
  const [GPSAddSpecificModel, setAddSPecificGPSMODEL] = useState({
    type_id: id,
    nameModele: "",
  });
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState();
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setErrors();
    setShow(false);
  };

  const handleGpsSpecificModel = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAddSPecificGPSMODEL({ ...GPSAddSpecificModel, [name]: value });
  };
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const addModelSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/api/comptable/addnewModel",
        GPSAddSpecificModel
      );
      getTypesgps();
      handleClose();
      showSuccessMessage(response.data);
      setAddSPecificGPSMODEL({});
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "25px",
          gap: "10px",
        }}
      >
        <UpdateType typedata={data} appareil_id={appareil_id} />
        <Button variant="primary" onClick={handleShow}>
          Nouveau modèle
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouveau modèle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={addModelSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Nom modèle: </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="nameModele"
                  autoComplete="off"
                  placeholder="Nom Model"
                  onChange={handleGpsSpecificModel}
                />
                <span style={{ color: "red" }}>{errors?.nameModele[0]}</span>
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                AJOUTER
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
