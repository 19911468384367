import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import TuneIcon from '@mui/icons-material/Tune';
import CommercialContext from "../context/CommercialContext";
import axios from "../context/axios"
import NouvelleTâches from "../ST/Technicien/NouvelleTâches";
import { useNavigate } from "react-router-dom";


const pageSize = 4;

const Taches = () => {
  
  const{handleDateClick,inputDate,handleDateChange,handleClick,handleChange,filterData,getTask} = useContext(CommercialContext);
  const [task, setTask] = useState([]);
  const [paginaed, setPaginated] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [USERR,SETUSER]=useState([]);
  const Navigate = useNavigate();

  let clientNumber;

  useEffect(() => {

      axios.get('/api/supportTechnique/technicienTask').then((res) => {
      setTask(res.data.taskTechniciens.filter((t) => t.etat_tache==1));
      setPaginated(_(res.data.taskTechniciens.filter((t) => t.etat_tache==1)).slice(0).take(pageSize).value());
    });


  }, []);
  const [details, setDetails] = useState({});

  
const navigation = ()=>{
  Navigate('/SupportTechnique/tâches/technicien')
}
  useEffect(()=>{
    let filteredDATA = task.filter((data) => (data.Status == "en Cours"))
    let max = {};
    max ={...filteredDATA.sort((a,b)=> a.id -b.id)[filteredDATA.length - 1]}
    setDetails({...max})
  }, [])
  
  useEffect(() => {
    if (filterData != null) {
      console.log("condition passed")
      setPaginated(_(filterData).slice(0).take(pageSize).value())
    }
  }, [filterData])
  let pageCount;
  if (filterData != null) {
    pageCount = filterData ? Math.ceil(filterData.length / pageSize) : 0;

  } else {
    pageCount = task ? Math.ceil(task.length / pageSize) : 0;

  }
  const pages = _.range(1, pageCount + 1);
  const pagination = (pageNo) => {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    if (filterData != null) {
      console.log("data exist")
      const paginatedTask = _(filterData).slice(startIndex).take(pageSize).value();
      setPaginated(paginatedTask)
    } else {
      console.log("doesnt exist")
      const paginatedTask = _(task).slice(startIndex).take(pageSize).value();
      setPaginated(paginatedTask);
    }

  };

  return (


    <div>
          
          <div style={{ display:'flex',justifyContent:'space-between' }}>

              <div style={{ padding:'30px' }}>
                  <h5>Tâches en cours  &nbsp;<span class="vl"></span> &nbsp;  <span style={{ fontSize: '15px' }}> {task.length} taches</span> </h5>
                </div>
                <div className="button-end">
                    <Button onClick={navigation}  variant="primary">

                        Voir tâches technicien
                      </Button>
                
              </div>
          </div>

        
        <div className="Table-tech">
          <MDBTable align="middle">
            <MDBTableHead >
              <tr>
                <th scope="col"></th>
                <th scope="col">Nom du client</th>
                <th scope="col">Type du tache</th>
                <th scope="col">date</th>
                <th scope="col">matricule</th>
                <th scope="col">Exécuter par </th>
              </tr>
            </MDBTableHead>
              <MDBTableBody>
              {paginaed.map((TASK) =>
                <tr key={TASK.id}>
                  <td>
                    {/* <Details data={TASK} /> */}
                  </td>
                  <td>
                    <p className="fw-bold mb-1">{TASK.client_name} </p>               
                  </td>
                  <td>{TASK.catache }</td>
                  <td>{TASK.date_previsionnelle_debut}</td>
                  <td>{TASK.matricule}</td>
                  <td>{TASK.user_name}</td>               
                </tr>
                )}

              </MDBTableBody>
          </MDBTable>
          <nav className="d-flex justify-content-end">
            <ul className="pagination">
              {pages.map((page) => (
                <div
                  className={`${page === currentPage ? "page-item active" : "page-item"
                    }`}
                >
                  <p className="page-link" onClick={() => pagination(page)}>
                    {page}
                  </p>
                </div>
              ))}
            </ul>
          </nav>

        </div>



    </div>

  );
};


export default Taches;