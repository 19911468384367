import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import TechnicianContext from "../../../context/TechnicienContext";
import pusher from "../../../../pusher";

const SIM = () => {
  const { appareil, getAppareil } = useContext(TechnicianContext);
  const [SIM, setSIM] = useState([]);
  useEffect(() => {
    setSIM(appareil.SIM);
  }, [appareil.SIM]);

  const [pages, setPages] = useState(1);
  const [rowsPrePage, setRowsPrepage] = useState(10);
  const lastSIMIndex = pages * rowsPrePage;
  const firstSIMIndex = lastSIMIndex - rowsPrePage;
  const currantSIMPrePages = SIM.slice(firstSIMIndex, lastSIMIndex);

  let numberOfPages = [];
  for (let p = 1; p <= Math.ceil(SIM.length / rowsPrePage); p++) {
    numberOfPages.push(p);
  }

  useEffect(() => {
    getAppareil();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAppareil();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const handleFilterSIM = (e) => {
    const value = e.target.value;
    if (value != "") {
      const filterSIM = appareil.SIM.filter((sim) =>
        sim.serial_number.includes(value)
      );
      if (filterSIM.length > 0) {
        setSIM(filterSIM);
      } else {
        setSIM([]);
      }
    } else {
      setSIM(appareil.SIM);
    }
  };
  return (
    <>
      <div className="tablesApp">
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <div
            className="bar-table"
            style={{ padding: "0rem 2rem 1rem 2rem " }}
          >
            <div>
              <h5>
                SIM &nbsp; <span class="vl"></span> &nbsp;
                <span style={{ fontSize: "15px" }}> {SIM.length} cartes</span>
              </h5>
            </div>
          </div>
          <div class="search-appareil-technicien input-group rounded">
            <input
              type="search"
              class="form-control rounded Search-input"
              placeholder="Search SIM, Modèle, Type"
              aria-label="Search"
              aria-describedby="search-addon"
              onChange={handleFilterSIM}
            />
          </div>
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col"></th>
                <th scope="col">SIM</th>
                <th scope="col">Modèle</th>
                <th scope="col">Type</th>
              </tr>
            </MDBTableHead>
            {currantSIMPrePages
              ? currantSIMPrePages.map((SIM, index) => (
                  <MDBTableBody>
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center"></div>
                      </td>
                      <td>
                        <p className="fw-normal mb-1">{SIM.serial_number}</p>
                      </td>
                      <td>
                        <p className="fw-normal mb-1">{SIM.nameModele}</p>
                      </td>
                      <td>{SIM.nameType}</td>
                    </tr>
                  </MDBTableBody>
                ))
              : " Aucun résultat trouvé"}
          </MDBTable>
          {SIM.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberOfPages.map((numberPage, index) => (
                  <MDBPaginationItem
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPages(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default SIM;
