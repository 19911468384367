import React, { useContext, useEffect, useState } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechnicianContext from "../context/TechnicienContext";
import { useParams } from "react-router-dom";
import { ShowImages } from "./ShowImages";

export default function Désinstallation() {
  //useParams hook to get the id and status of Technician Task from the url redirecting you to this componenet
  const { id, statu } = useParams();

  // // console.log(statu);for debugging
  //import states related to desinstallation and other functions related to its input from the technician context
  const {
    getTasksDataForReparation,
    reparationTaskData,
    desinstallation,
    setDesinstallation,
    handleFileEvent,
    handleDesinstallChange,
    desinstallationSubmit,
    clientInfoTask,
    errorMessage,
    setErrorMessage,
  } = useContext(TechnicianContext);
  //useEffect hook executing the getTAsk function to get a Task by id
  useEffect(() => {
    setErrorMessage()
    getTasksDataForReparation(id);
  }, []);

  //logic responsible for keeping track of the endroit change and setting up the affectedID that the technician will affect to a specific
  //ST,
  const handleEndroit = (e) => {
    const value = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex]; // Get the selected option
    const data_special = selectedOption.getAttribute("data-special");
    setDesinstallation({
      ...desinstallation,
      affecteID: value,
      special: data_special,
    });
  };

  const [vehiculeInfo, setVehiculeInfo] = useState(null);

  const hanleFilterByDevice = async (e) => {
    const getIdVehicule = await reparationTaskData?.allDeviceInstalled.filter(
      (idVehicule) => idVehicule.serial_number === e.target.value
    );
    if (getIdVehicule.length > 0) {
      setVehiculeInfo(...getIdVehicule);
    } else {
      setVehiculeInfo(null);
    }
  };

  // useEffect(() => {
  //   if (vehiculeInfo != null) {
  //     const getArticleInfo = desinstallation.AllTaskOfClient.filter(
  //       (articleInfo) => articleInfo.vehicule_id == vehiculeInfo.id
  //     );
  //     setDesinstallation({
  //       // ...desinstallation,
  //       IMEI: getArticleInfo.map(
  //         (articleInfo) => articleInfo.articles.serial_number
  //       )[0],
  //       NSIM: getArticleInfo.map(
  //         (articleInfo) =>
  //           articleInfo.articles.latest_sim_relating.map(
  //             (simCombination) => simCombination.serial_number
  //           )[0]
  //       )[0],
  //       modelAppareil: getArticleInfo.map((articleInfo) =>
  //         articleInfo.articles.modeles
  //           ? articleInfo.articles.modeles.nameModele
  //           : ""
  //       )[0],
  //       Matricule: vehiculeInfo.matricule,
  //     });
  //   }
  // }, [vehiculeInfo]);

  useEffect(() => {
    if (vehiculeInfo != null) {
      const vehicule = reparationTaskData.vehicules.filter(
        (articleInfo) => articleInfo.id === vehiculeInfo.vehicule_id
      )[0];
      setDesinstallation({
        ...desinstallation,
        statut: vehiculeInfo ? vehiculeInfo.gps_principale : null,
        NSIM: vehiculeInfo
          ? vehiculeInfo.latest_sim_relating.map(
              (simCombination) => simCombination.serial_number
            )[0]
          : "",
        Matricule: vehicule ? vehicule.matricule : "",
        typeRelay:
          vehicule && vehicule.articles
            ? vehicule.articles.map((relay) => relay.modele_id)[0]
            : "",
        IMEI: vehiculeInfo ? vehiculeInfo.serial_number : "",
      });
    } else {
      setDesinstallation(null);
    }
  }, [vehiculeInfo]);
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "1rem",
        }}
      >
        <MDBContainer>
          <MDBCardHeader>
            <h5>Désinstallation</h5>
          </MDBCardHeader>
          <hr></hr>

          <Form noValidate>
            <Row className="mb-4">
              {/* Nom client */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Nom Client : </Form.Label>
                <Form.Control
                  disabled
                  name="nomComplet"
                  autoComplete="off"
                  type="text"
                  placeholder="Nom client"
                  value={clientInfoTask.client_name}
                />
              </Form.Group>
              {/* IMEI */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>IMEI : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="IMEI"
                  list="instaledIMEIList"
                  autoComplete="off"
                  placeholder="IMEI"
                  onChange={hanleFilterByDevice}
                />
                <datalist id="instaledIMEIList">
                  {reparationTaskData?.allDeviceInstalled.map(
                    (instaledDevice) => (
                      <option key={instaledDevice.id} id={instaledDevice.id}>
                        {instaledDevice.serial_number}
                      </option>
                    )
                  )}
                </datalist>
              </Form.Group>
              {/* SIM */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>N Sim : </Form.Label>
                <Form.Control
                  required
                  name="NSIM"
                  type="text"
                  autoComplete="off"
                  placeholder="N sim"
                  value={desinstallation?.NSIM}
                  onChange={handleDesinstallChange}
                />
              </Form.Group>
              {/* Matricule */}
              <Form.Group as={Col} md="3 4" controlId="validationCustom04">
                <Form.Label>Matricule : </Form.Label>
                <Form.Control
                  required
                  list="matriculesList"
                  name="Matricule"
                  placeholder="Matricule"
                  autoComplete="off"
                  value={desinstallation ? desinstallation.Matricule : ""}
                  onChange={handleDesinstallChange}
                />
                  {errorMessage && errorMessage.Matricule ?
                     (<span style={{ color: "red" }}>
                      {errorMessage.Matricule[0]}
                     </span>)
             
                    : ""}
              </Form.Group>
            </Row>

            <Row className="mb-4">
              {/* endroit appareil */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Endroit:</Form.Label>
                <Form.Select
                  required
                  autoComplete="off"
                  type="text"
                  name="endroit"
                  onChange={(e) => handleEndroit(e)}
                >
                  <option value="none" selected disabled hidden>
                    Remettre Chez{" "}
                  </option>
                  <option disabled>Remettre Chez</option>
                  <option data-special="client">Client</option>
                </Form.Select>
              </Form.Group>
              {/*images*/}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Images : </Form.Label>
                <Form.Control
                  required
                  name="imagesTask"
                  multiple
                  type="file"
                  accept="application/pdf,image/jpeg,image/png"
                  onChange={(e) => handleFileEvent(e, statu)}
                />
                  {errorMessage && errorMessage.images ?
                    (<span style={{ color: "red" }}>
                      {errorMessage.images[0]}
                    </span>)
            
                    : ""}
              </Form.Group>
          
              <ShowImages />
            </Row>

            <Row className="mb-4"></Row>

            <Row className="mb-4">
              <Form.Group as={Col} md="9" controlId="validationCustom04">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  style={{ height: "10rem" }}
                  as="textarea"
                  name="Observation"
                  required
                  type="file"
                  value={desinstallation?.Observation}
                  onChange={handleDesinstallChange}
                />
              </Form.Group>
            </Row>

            <Row md="6" className="mt-4 justify-content-center">
              <Button
                onClick={() => desinstallationSubmit(id, statu)}
                variant="primary"
              >
                Terminer Tâche
              </Button>
            </Row>
          </Form>
        </MDBContainer>
      </div>
    </div>
  );
}
