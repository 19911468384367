import React, { useContext, useEffect, useState } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechnicianContext from "../context/TechnicienContext";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { ShowImages } from "./ShowImages";

export default function Installation() {
  const { id, statu } = useParams();
  //context Tech context importing all of its stated related to installation and functions
  const {
    getTask,
    handleAddChange,
    installation,
    handleFileEvent,
    installationSubmit,
    setInstallation,
    appareil,
    getAppareil,
    handleAddAccessory,
    errorMessage,
    clientInfoTask,
  } = useContext(TechnicianContext);

  //useEffect that execute the getTAsk func
  useEffect(() => {
    getTask(id);
    getAppareil();
    setSubmitBtn(true);
  }, []);

  //useEffect hook to set the property "etatTache" of installation state with the value 2 which is related to a terminated task state in the backend

  useEffect(() => {
    if (installation.etatTache === "") {
      setInstallation({ ...installation, etatTache: "2" });
    }
  }, [installation]);

  const [simCombination, setSimCombination] = useState({
    serial_numberCombination: "",
  });

  const handleUdpateSim = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSimCombination({ ...simCombination, [name]: value });
    setInstallation({ ...installation, NSIM: value });
  };

  const handleChooseIMEI = async (e, GPS) => {
    const serial_number = e.target.value;
    const name = e.target.name;
    isFormValid(serial_number);
    const filterSimByIMEI = await GPS.filter(
      (GpsNumber) => GpsNumber.serial_number === serial_number
    );
    setSimCombination(...filterSimByIMEI);
    setInstallation({
      ...installation,
      [name]: serial_number,
      NSIM: filterSimByIMEI.map(
        (sim_number) => sim_number.serial_numberCombination
      )[0],
    });
  };

  const [submitBtn, setSubmitBtn] = useState(false);

  const isFormValid = (IMEI) => {
    if (IMEI !== "") {
      setSubmitBtn(false);
    } else if (IMEI === "") {
      setSubmitBtn(true);
    }
  };

  const accessoryOptions = appareil.Accessory.map((optionAccessory) => {
    return {
      label: optionAccessory.nameType,
      value: optionAccessory.serial_number,
    };
  });

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "1rem",
        }}
      >
        <MDBContainer>
          <MDBCardHeader>
            <h5> Installation</h5>
          </MDBCardHeader>
          <hr></hr>

          <Form noValidate id="myForm" encType="multipart/form-data">
            <Row className="mb-4">
              {/* Nom client */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Nom Client : </Form.Label>
                <Form.Control
                  name="nomComplet"
                  disabled
                  required
                  type="text"
                  placeholder="Nom client"
                  value={clientInfoTask.client_name}
                />
              </Form.Group>

              {/* Matricule */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Matricule : </Form.Label>
                <Form.Control
                  required
                  name="Matricule"
                  type="text"
                  autoComplete="off"
                  placeholder="Veuillez saisir la matricule"
                  value={installation.Matricule ? installation.Matricule : ""}
                  onChange={handleAddChange}
                />
                {errorMessage && errorMessage.Matricule ? (
                  <span style={{ color: "red" }}>{errorMessage.Matricule}</span>
                ) : (
                  ""
                )}
              </Form.Group>

              {/* IMEI */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>IMEI : </Form.Label>
                <Form.Control
                  list="serial_numbers"
                  autoComplete="off"
                  name="IMEI"
                  placeholder="IMEI"
                  id="IMEI"
                  value={installation.IMEI}
                  onChange={(e) => handleChooseIMEI(e, appareil.GPS)}
                  required
                />
                <datalist id="serial_numbers">
                  {appareil.GPS.map((IMEI, index) => (
                    <option key={index}>{IMEI.serial_number}</option>
                  ))}
                </datalist>
                {errorMessage && errorMessage.IMEI ? (
                  <span style={{ color: "red" }}>{errorMessage.IMEI}</span>
                ) : (
                  ""
                )}
              </Form.Group>

              {/* SIM */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>N Sim : </Form.Label>
                <Form.Control
                  list="simNumberList"
                  autoComplete="off"
                  name="serial_numberCombination"
                  type="text"
                  placeholder="N sim"
                  value={
                    simCombination
                      ? simCombination.serial_numberCombination
                      : ""
                  }
                  onChange={handleUdpateSim}
                />
                <datalist id="simNumberList">
                  {appareil.SIM.map((simNumber) => (
                    <option key={simNumber.id}>
                      {simNumber.serial_number}
                    </option>
                  ))}
                </datalist>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              {/* Principale/secondaire */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Gps principale / secondaire :</Form.Label>
                <Form.Select
                  name="GPSstatut"
                  onChange={handleAddChange}
                  aria-label="Default select example"
                >
                  <option value="none" selected disabled hidden>
                    principale(1er)
                  </option>
                  <option disabled>GPS Position</option>
                  <option value={1}>secondaire(2eme)</option>
                  <option value="principale">principale(1er)</option>
                </Form.Select>
              </Form.Group>

              {/* type Relais */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>normal / spécial:</Form.Label>
                <Form.Select
                  name="typeRelais"
                  onChange={handleAddChange}
                  aria-label="Default select example"
                >
                  <option value="none" selected disabled hidden>
                    Sélectionner Type Relais
                  </option>
                  <option disabled>Type Relais</option>
                  <option value="none">Sans Relais</option>
                  <option value="normal">Normal</option>
                  <option value="spécial">Spécial</option>
                </Form.Select>
                {errorMessage && errorMessage.typeRelais ? (
                  <span style={{ color: "red" }}>
                    {errorMessage.typeRelais}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Accessoires: </Form.Label>
                <Select
                  isMulti
                  name="accessory"
                  onChange={handleAddAccessory}
                  options={accessoryOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Images : </Form.Label>
                <Form.Control
                  required
                  name="imagesTask[]"
                  multiple
                  type="file"
                  accept="image/jpeg,image/png"
                  onChange={(e) => handleFileEvent(e, statu)}
                />
                {errorMessage && errorMessage.images ? (
                  <span style={{ color: "red" }}>{errorMessage.images}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <ShowImages />
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col} md="9" controlId="validationCustom04">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  style={{ height: "10rem" }}
                  as="textarea"
                  name="Observation"
                  required
                  type="file"
                  value={installation.Observation}
                  onChange={handleAddChange}
                />
              </Form.Group>
            </Row>
            <Row md="6" className="mt-4 justify-content-center">
              <Button
                onClick={() => installationSubmit(id)}
                variant="primary"
                id="submitBtn"
                name="submitBtn"
                disabled={submitBtn}
              >
                Terminer Tâche
              </Button>
            </Row>
          </Form>
        </MDBContainer>
      </div>
    </div>
  );
}
