import React, { useContext, useEffect, useState } from "react";
import "./style/commercialstyle.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "react-bootstrap/Button";
import CommercialContext from "../context/CommercialContext";
import ClearIcon from "@mui/icons-material/Clear";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TuneIcon from "@mui/icons-material/Tune";
import pusher from "../../pusher";
import { useNavigate } from "react-router-dom";
import NouvelleTâches from "../taskToConfirmeForm/NouvelleTâches";

export const TasksCommerciale = () => {
  const {
    getTasksCommerciale,
    task,
    handleFilterTasksByDate,
    filterTasksByDateClick,
    filterTasksByDate,
    clearFilterTaskTechnicien,
  } = useContext(CommercialContext);
  const navigateTo = useNavigate();
  useEffect(() => {
    getTasksCommerciale();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getTasksCommerciale();
    });
    // Clean up the Pusher connection when the component unmounts
  }, []);
  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const handleNavigateTo = (client_id, clientName) => {
    navigateTo(`/commercial/tâches-client/${client_id}`, {
      state: { clientName: clientName },
    });
  };
  const renderMoreDetailComponent = (rowDate) => {
    return (
      <>
        <div style={{ padding: "10px 0px" }}>
          <Button
            onClick={() => handleNavigateTo(rowDate.id, rowDate.nomComplet)}
          >
            <p className="fw-normal mb-1">plus details</p>
          </Button>
        </div>
      </>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();
  return (
    <>
      <div className="bar-table">
        <div className="left-bar-table">
          <NouvelleTâches />
        </div>
        <div className="right-bar-table">
          <div className="Filter">
            <div style={{ display: "flex", gap: "10px" }}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={filterTasksByDate.startDate}
                    onChange={handleFilterTasksByDate}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={filterTasksByDate.endDate}
                    onChange={handleFilterTasksByDate}
                    required
                  />
                </Form.Group>
              </Row>

              <div className="filter-button-task-technicien">
                <Button onClick={(e) => filterTasksByDateClick()}>
                  {" "}
                  <TuneIcon />
                  Filtrer
                </Button>
                <Button variant="danger" onClick={clearFilterTaskTechnicien}>
                  {" "}
                  <ClearIcon />
                  Annule
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tâches-tech">
        <div className="Table-tech-commerciale">
          <div>
            <DataTable
              value={task}
              header={header}
              filters={filters}
              removableSort
              paginator
              rows={15}
              rowsPerPageOptions={[15, 25, 50, 100]}
              tableStyle={{
                minWidth: "50rem",
                backgroundColor: "#F24405",
              }}
              emptyMessage="Aucun résultat trouvé."
            >
              <Column
                header="#"
                headerStyle={{ width: "3rem" }}
                body={(data, options) => options.rowIndex + 1}
                bodyStyle={{ fontWeight: "bold" }}
              ></Column>
              <Column
                header="Plus Details"
                body={renderMoreDetailComponent}
                style={{ width: "15%" }}
              ></Column>
              <Column
                field="nomComplet"
                header="Nom du client"
                sortable
                style={{ width: "22%", color: "#000" }}
                bodyStyle={{ fontWeight: "bold" }}
              ></Column>
              <Column
                field="telephone_client"
                header="N.Téléphone"
                sortable
                style={{ width: "15%" }}
              ></Column>
              <Column
                field="email_client"
                header="Email"
                sortable
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="last_task.date_affectation"
                header="Date"
                sortable
                style={{ width: "20%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};
