import React, { useContext, useEffect, useState } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechnicianContext from "../context/TechnicienContext";
import { useParams } from "react-router-dom";
import { ShowImages } from "./ShowImages";

export default function RéparationGps() {
  //grab the id and status of a task from the url redirecting u to this componenet
  const { id, statu } = useParams();
  //technician context importing states and functions related to reparationGPS
  const {
    reparationGPS,
    handleReparationGPS,
    ReparationGPSSubmit,
    handleChangeEdroit,
    setReparationGPS,
    getAppareil,
    appareil,
    getTasksDataForReparation,
    reparationTaskData,
    handleFileEvent,
    clientInfoTask,
    errorMessage,
    setErrorMessage,
  } = useContext(TechnicianContext);
  //get the task by its id an axios request behind the scene ( context of technician)
  useEffect(() => {
    getTasksDataForReparation(id);
    getAppareil();
    setErrorMessage(null)
  }, []);

  const [newSim, setNewSim] = useState([]);

  const [vehiculeInfo, setVehiculeInfo] = useState(null);

  const hanleFilterByDevice = async (e) => {
    const getIdVehicule = await reparationTaskData?.allDeviceInstalled.filter(
      (idVehicule) => idVehicule.serial_number === e.target.value
    );
    if (getIdVehicule.length > 0) {
      setVehiculeInfo(...getIdVehicule);
    } else {
      setVehiculeInfo(null);
    }
  };

  useEffect(() => {
    if (vehiculeInfo != null) {
      const vehicule = reparationTaskData.vehicules.filter(
        (articleInfo) => articleInfo.id === vehiculeInfo.vehicule_id
      )[0];
      setReparationGPS({
        ...reparationGPS,
        statut: vehiculeInfo ? vehiculeInfo.gps_principale : null,
        NSIM: vehiculeInfo
          ? vehiculeInfo.latest_sim_relating.map(
              (simCombination) => simCombination.serial_number
            )[0]
          : "",
        Matricule: vehicule ? vehicule.matricule : "",
        typeRelay:
          vehicule && vehicule.articles
            ? vehicule.articles.map((relay) => relay.modele_id)[0]
            : "",
        AncienneIMEI: vehiculeInfo ? vehiculeInfo.serial_number : "",
      });
    } else {
      setReparationGPS(null);
    }
  }, [vehiculeInfo]);

  const handleupdateSim = (e) => {
    const value = e.target.value;
    setReparationGPS({ ...reparationGPS, NSIM: value });
  };

  useEffect(() => {
    if (reparationGPS != null && reparationGPS.imei != null) {
      const filter = appareil.GPS.filter(
        (filterGps) => filterGps.serial_number == reparationGPS.imei
      );
      setNewSim(filter);
    }
  }, [reparationGPS]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "1rem",
        }}
      >
        <MDBContainer>
          <MDBCardHeader>
            <h5> Changement GPS</h5>
          </MDBCardHeader>
          <hr></hr>

          <Form noValidate>
            <Row className="mb-4">
              {/* Nom client */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Nom Client : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled
                  placeholder="Nom client"
                  name="nomComplet"
                  value={clientInfoTask.client_name}
                />
              </Form.Group>
              {/* Ancienne IMEI */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Ancienne IMEI : </Form.Label>
                <Form.Control
                  list="AncienneIMEIList"
                  name="AncienneIMEI"
                  autoComplete="off"
                  placeholder="Ancienne IMEI"
                  onChange={(e) => hanleFilterByDevice(e)}
                />
                <datalist id="AncienneIMEIList">
                  {reparationTaskData?.allDeviceInstalled.map((oldDevice) => (
                    <option key={oldDevice.id} id={oldDevice.id}>
                      {oldDevice.serial_number}
                    </option>
                  ))}
                </datalist>
              </Form.Group>
              {/*  Matricule */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label> Matricule : </Form.Label>
                <Form.Control
                  list="matricules"
                  autoComplete="off"
                  name="Matricule"
                  placeholder="Matricule"
                  onChange={handleReparationGPS}
                  value={reparationGPS ? reparationGPS.Matricule : ""}
                />
                {errorMessage && errorMessage.Matricule ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.Matricule[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>

              {/* Nouveau  IMEI */}

              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Nouveau IMEI: </Form.Label>
                <Form.Control
                  list="NouveauIMEIList"
                  name="imei"
                  autocomplete="off"
                  placeholder="Nouveau IMEI"
                  onChange={handleReparationGPS}
                  value={reparationGPS ? reparationGPS.imei : null}
                />
                <datalist id="NouveauIMEIList">
                  {appareil.GPS.map((Gps, index) => (
                    <option key={index} value={Gps.id}>
                      {Gps.serial_number}
                    </option>
                  ))}
                </datalist>
                {errorMessage && errorMessage.imei ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.imei[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Numéro carte SIM: </Form.Label>
                <Form.Control
                  list="simNumberList"
                  name="NSIM"
                  autocomplete="off"
                  placeholder="sim number"
                  onChange={handleupdateSim}
                  value={reparationGPS ? reparationGPS.NSIM : ""}
                />
                <datalist id="simNumberList">
                  {appareil.SIM.map((sim, index) => (
                    <option value={sim.serial_number} key={index}>
                      {sim.latest_device_relating.length > 0
                        ? sim.latest_device_relating.map(
                            (deviceRelating) => deviceRelating.serial_number
                          )[0]
                        : ""}
                    </option>
                  ))}
                </datalist>
              </Form.Group>
              {/* endroits */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Endroit: </Form.Label>
                <Form.Select
                  required
                  autoComplete="off"
                  type="text"
                  name="endroit"
                  onChange={(e) => handleChangeEdroit(e)}
                >
                  <option value="none" selected disabled hidden>
                    Veuillez choisir un endroit
                  </option>
                  <option data-special="client" value="client">
                    Client
                  </option>
                </Form.Select>
              </Form.Group>

              {/* Principale/secondaire */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Gps principale / secondaire:</Form.Label>
                <Form.Select
                  name="statut"
                  onChange={handleReparationGPS}
                  aria-label="Default select example"
                >
                  <option selected disabled hidden>
                    {reparationGPS && reparationGPS.statut === 0
                      ? "principale(1er)"
                      : reparationGPS && reparationGPS.statut === 1
                      ? "secondaire(2eme)"
                      : "select position"}
                  </option>
                  <option value="0">principale(1er)</option>
                  <option value="1">secondaire(2eme)</option>
                </Form.Select>
                {errorMessage && errorMessage.statut ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.statut[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>
              {/* type Relais */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>normal / spécial:</Form.Label>
                <Form.Select
                  name="typeRelay"
                  onChange={handleReparationGPS}
                  aria-label="Default select example"
                >
                  <option selected disabled hidden>
                    {reparationGPS && reparationGPS.typeRelay === 22
                      ? "Normal"
                      : reparationGPS && reparationGPS.typeRelay === 21
                      ? "Spéciale"
                      : "select type relais"}
                  </option>
                  <option disabled>Type Relais</option>
                  <option value="normal">normal</option>
                  <option value="spécial">spécial</option>
                </Form.Select>
                {errorMessage && errorMessage.typeRelay ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.typeRelay[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>
            </Row>

            <Row className="mb-4">
              {/*images*/}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Images: </Form.Label>
                <Form.Control
                  required
                  name="images"
                  multiple
                  type="file"
                  accept="application/pdf,image/jpeg,image/png"
                  onChange={(e) => handleFileEvent(e, statu)}
                />
                  {errorMessage && errorMessage.images ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.images[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>

              <ShowImages />
            </Row>
            <Row className="mb-4">
              {/* Observation */}
              <Form.Group as={Col} md="9" controlId="validationCustom04">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  style={{ height: "10rem" }}
                  as="textarea"
                  name="Observation"
                  required
                  type="file"
                  value={reparationGPS ? reparationGPS.Observation : null}
                  onChange={handleReparationGPS}
                />
              </Form.Group>
            </Row>

            <Row md="6" className="mt-4 justify-content-center">
              <Button onClick={() => ReparationGPSSubmit(id)} variant="primary">
              Terminer Tâche
              </Button>
            </Row>
          </Form>
        </MDBContainer>
      </div>
    </div>
  );
}
