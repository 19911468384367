import React, { useContext, useEffect , useState } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebare from "./Components/Sidebare/Sidebare";
import NavigationBar from "./Components/Sidebare/Topbar";
import "./App.css";
import Profil from "./Components/Admin/Profil";
import Utilisateurs from "./Components/Admin/Utilisateurs";
import Dashboard from "./Components/Dashboard/Dashboard";
import Taches from "./Components/Tâches/Taches";
import AdminContext from "./Components/context/AdminContext";
import Calendar from "./Components/Calendar/Calendar";
import Installation from "./Components/Technicien/Installation";
import Annulée from "./Components/Technicien/Annulée";
import Reportée from "./Components/Technicien/Reportée";
import Réinstallation from "./Components/Technicien/Réinstallation";
import RéparationSim from "./Components/Technicien/RéparationSim";
import RéparationGps from "./Components/Technicien/RéparationGps";
import RéparationRelais from "./Components/Technicien/RéparationRelais";
import Login from "./Components/auth/Login";
import { AuthContext } from "./Components/context/AuthContext";
import { CommercialProvider } from "./Components/context/CommercialContext";
import Loader from "./Components/Loader/Loader";
import { STProvider } from "./Components/context/STcontext";
import TachesCommercial from "./Components/ST/Commercial/TachesCommercial";
import TachesTechnicien from "./Components/ST/Technicien/TachesTechnicien";
import ComptableTasksTechnicien from "./Components/Comptable/technicienTasks/ComptableTasksTechnicien";
import TehcTâches from "./Components/Technicien/TechTâches";
import TechAppareil from "./Components/Technicien/Appareil/TechAppareil";
import STappareil from "./Components/ST/Appareil/TechAppareil";
import Appareilsretournées from "./Components/ST/Appareil/appareilReturned/Appareilsretournées";
import TechTachesaconfirmer from "./Components/Technicien/TachesAconfirmer/TechTachesaconfirmer";
import { TechnicianProvider } from "./Components/context/TechnicienContext";
import Désinstallation from "./Components/Technicien/Désinstallation";
import SttaskToConfirmed from "./Components/ST/SttaskToConfirmed";
import TaskToConfirm from "./Components/Gerant/TasktoConfirm";
import Gerapp from "./Components/Gerant/Appareil/Gerapp";
import TachesSt from "./Components/ST/TachesSt";
import Parametrage from "./Components/Comptable/Parametrage/Parametrage";
import Comptableapp from "./Components/Comptable/Appareil/Comptableapp";
import Simretournées from "./Components/ST/Appareil/appareilReturned/Simretournées";
import { ComptableProvider } from "./Components/context/ComptableContext";
import { TaskByStatu } from "./Components/Dashboard/TaskByStatu";
import { TasksCommerciale } from "./Components/Commerciale/TasksCommerciale";
import { AllClients } from "./Components/Comptable/client/AllClients";
import { ShowAppareilPerUser } from "./Components/Comptable/Appareil/SGA/ShowAppareilPerUser";
import { ShowAppareilPerUserST } from "./Components/ST/Appareil/SGA/ShowAppareilPerUserST";
import { AppareilsDésinstallés } from "./Components/Technicien/Appareil/AppareilsDésinstallés";
import DetailsTasksClient from "./Components/Commerciale/DetailsTasksClient";
import { EndTaskST } from "./Components/ST/endTaskST/EndTaskST";
import { Panne } from "./Components/Technicien/Panne";
import { SuccessMessage } from "./Components/errorSuccessNotification/SuccessMessage";

function App() {
  const { USE, checkUserLoggedIn,token,autologout,csrf } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    checkUserLoggedIn()
    // csrf()
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  
  return (
    <div className="App">
      {isLoading ? 
      
      <Loader/> :token !=null ? (
        <div className="Content">
          <Sidebare />
          <SuccessMessage/>
          <div className="containt">
            <div className="navBar">
              <NavigationBar />
            </div>
            <div className="buttomContaint">
              <AdminContext>

                <STProvider>
                <CommercialProvider>
                <TechnicianProvider>
                <ComptableProvider>
                    

                <Routes>
                  <Route path="/profil" element={<Profil />} />
                  <Route path="/utilisateurs" element={<Utilisateurs />} />
                  <Route path="/Dashboard" element={<Dashboard />} />
                  <Route path="/taches" element={<Taches />} />
                  <Route path="/Calendrier" element={<Calendar />} />
                  <Route path="/tâches/:statu" element={<TaskByStatu/>}/>
                  <Route path="/Annulée/:id" element={<Annulée />} />
                  <Route path="/commercial/Tout Cients" element={<AllClients/>}/>
                  <Route path="/commercial/tâches" element={<TasksCommerciale/>}/>
                  <Route path="/commercial/tâches-client/:client_id" element={<DetailsTasksClient/>}/>

                  {/* Support technique */}

                  <Route path="/SupportTechnique/tâches/Commercial" element={<TachesCommercial/>}/>
                  <Route path="/SupportTechnique/tâches/technicien" element={<TachesTechnicien/>}/>
                  <Route path="/SupportTechnique/appareil" element={<STappareil/>}/>
                  <Route path="/SupportTechnique/tachesaconfirmer" element={<SttaskToConfirmed/>}/>
                  <Route path="/SupportTechnique/appareilretournées/GPS" element={<Appareilsretournées/>}/>
                  <Route path="/supportTechnique/appareilretournées/Sim" element={<Simretournées/>}/>
                  <Route path="/supportTechnique/tâches/supporttechnique" element={<TachesSt/>}/>
                  <Route path="/supportTechnique/tâches/supporttechnique/:id/:taskStatus" element={<EndTaskST/>}/>
                  <Route path="/Support-technique/appareils-pour-l'utilisateur/:id/:typeAppareil" element={<ShowAppareilPerUserST/>}/>
                 
                  {/* Technicien route */}
    
                  <Route path="/technicien/tâches" element={<TehcTâches/>}/>
                  <Route path="/Technicien/appareil" element={<TechAppareil />} />
                  <Route path="/Technicien/Appareils-désinstallés" element={<AppareilsDésinstallés />} />
                  <Route path="/Reportée/:id" element={<Reportée />} />
                  <Route path="/termineDesinstalation/:id/:statu" element={<Désinstallation />} />
                  <Route path="/termineRéinstallation/:id/:statu" element={<Réinstallation />} />
                  <Route path="/termineInstallation/:id/:statu" element={<Installation />} />
                  <Route path="/termineReparationSIM/:id/:statu" element={<RéparationSim />} />
                  <Route path="/termineReparationRelais/:id/:statu" element={<RéparationRelais />}/>
                  <Route path="/terminePanne/:id/:statu" element={<Panne />}/>
                  <Route path="/termineReparationGPS/:id/:statu" element={<RéparationGps />}/>
                  <Route path="/technicien/taches/confirmer" element={<TechTachesaconfirmer />} />
                  <Route path="/Gerant/tâches-à-confirmer" element={<TaskToConfirm/>}/>
                  <Route path="/Gerant/appareils" element={<Gerapp/>}/>

                  {/* Comptable */}
                  
                  <Route path="/Comptable/tâches/supporttechnique" element={<TachesSt/>}/>
                  <Route path="/Comptable/tâches/technicien" element={<ComptableTasksTechnicien/>}/>
                  <Route path="/Comptable/tâches/Commercial" element={<TachesCommercial/>}/>
                  <Route path="/comptable/paramétrage" element={<Parametrage/>}/>
                  <Route path="/comptable/appareil" element={<Comptableapp/>}/>
                  <Route path="/comptable/Tout Cients" element={<AllClients/>}/>
                  <Route path="/comptable/appareils-pour-l'utilisateur/:id/:typeAppareil" element={<ShowAppareilPerUser/>}/>
                   </Routes>
                   </ComptableProvider>
                  </TechnicianProvider>
              </CommercialProvider>
              </STProvider>
              </AdminContext>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Login />
        </div>
      )}
      
    
    </div>

  );
}

export default App;