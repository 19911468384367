import React, { useEffect, useContext } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import STcontext from "../../context/STcontext";
import TechnicianContext from "../../context/TechnicienContext";

export default function NouvelleTâches() {
  const { getTaskInfo, NewTask } = useContext(STcontext);
  const {
    DATAS,
    handleAddTask,
    setData,
    SumbitTachConfirmée,
    allTasksToConfirmed,
    errorMessage,
    setErrorMessage,
    handleChangeName,
    setFilterClientInfo,
    filterClientInfo,
  } = useContext(TechnicianContext);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setErrorMessage(null);
    setFilterClientInfo({});
    setData({ Quantite: 1 });
  };
  const handleShow = () => {
    setData({ ...DATAS, TypeTask: "normal" });
    setShow(true);
  };

  useEffect(() => {
    handleClose();
  }, [allTasksToConfirmed]);

  useEffect(() => {
    getTaskInfo();
  }, []);

  return (
    <div>
      <Button
        className="nouveauProfilButton"
        style={{ width: "100%" }}
        onClick={handleShow}
      >
        Nouvelle tâche
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouvelle tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={SumbitTachConfirmée}>
            <Row className="mb-3">
              {/* name Clinet */}
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Nom complet : </Form.Label>
                <Form.Control
                  list="data"
                  placeholder="Nom Client"
                  name="nomComplet"
                  autoComplete="off"
                  onChange={(e) => handleChangeName(e, NewTask.Clients)}
                  value={DATAS.nomComplet}
                />
                <datalist id="data">
                  {NewTask.Clients.map((client, index) => (
                    <option key={index}>{client.nomComplet}</option>
                  ))}
                </datalist>
              </Form.Group>
              {/*  number Phone Client */}
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Telephone: </Form.Label>
                <Form.Control
                  name="telephone"
                  autoComplete="off"
                  onChange={handleAddTask}
                  placeholder="+2126********"
                  value={
                    DATAS && DATAS.telephone != null ? DATAS.telephone : ""
                  }
                />
                {/* email Client */}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Email: </Form.Label>
                <Form.Control
                  name="email"
                  autoComplete="off"
                  placeholder="example@gmail.com"
                  onChange={handleAddTask}
                  value={DATAS && DATAS.email != null ? DATAS.email : ""}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {/* taype Task*/}

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Type de la tâche : </Form.Label>
                <Form.Control
                  list="dataTask"
                  name="typeTache"
                  placeholder="type tâche"
                  autoComplete="off"
                  onChange={handleAddTask}
                  value={DATAS.typeTache}
                />
                <datalist id="dataTask">
                  {NewTask.TypesTasks.map((TypesTask, index) => (
                    <option key={index}>{TypesTask.catache}</option>
                  ))}
                </datalist>
                {errorMessage && errorMessage.typeTache ? (
                  <span style={{ color: "red" }}>
                    {errorMessage.typeTache[0]}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Date : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="Date"
                  autoComplete="off"
                  onChange={handleAddTask}
                  value={DATAS.Date}
                />
                {errorMessage && errorMessage.Date ? (
                  <span style={{ color: "red" }}>{errorMessage.Date[0]}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Quantité: </Form.Label>
                <Form.Control
                  type="number"
                  name="Quantite"
                  onChange={handleAddTask}
                  value={DATAS.Quantite}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="">
                <Form.Label>réalisée à : </Form.Label>
                <Form.Select
                  name="clientCity"
                  autoComplete="off"
                  onChange={handleAddTask}
                >
                  <option selected hidden>
                    {DATAS && DATAS.clientCity != null && filterClientInfo.city
                      ? filterClientInfo.city.cityName
                      : "select ville"}
                  </option>
                  <option disabled>select ville</option>
                  {NewTask &&
                    NewTask.Cities.map((city, index) => (
                      <option key={index} value={city.id}>
                        {city.cityName}
                      </option>
                    ))}
                </Form.Select>
                <span style={{ color: "red" }}>
                  {errorMessage && errorMessage.clientCity
                    ? errorMessage.clientCity[0]
                    : ""}
                </span>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  placeholder="description"
                  name="Description"
                  value={DATAS.Description}
                  as="textarea"
                  rows={3}
                  onChange={handleAddTask}
                />
              </Form.Group>
            </Row>

            <Modal.Footer>
              <Button type="submit" variant="primary">
                Ajouter tâche
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
