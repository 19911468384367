import React, { useEffect, useState } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./calendar.css";
import axios from "../context/axios";
import pusher from "../../pusher";

export default function Calendar() {
  const [task, setTask] = useState([]);

  useEffect(() => {
    axios.get("/api/supportTechnique/technicienTask").then((res) => {
      setTask(res.data.taskTechniciens);
    });
  }, []);
  useEffect(() => {
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData",function (data) {
        setTask(data);
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const formatDate = (datePrevisionnelleDebut) => {
    const year = new Date(datePrevisionnelleDebut).toLocaleString("default", {
      year: "numeric",
    });
    const month = new Date(datePrevisionnelleDebut).toLocaleString("default", {
      month: "2-digit",
    });
    const day = new Date(datePrevisionnelleDebut).toLocaleString("default", {
      day: "2-digit",
    });
    return [year, month, day].join("-") + " 09:00";
  };

  const filteredData = task.map((tsk) => {
    return {
      title: `${tsk.client_name} : ${tsk.catache}`,
      date: formatDate(tsk.date_previsionnelle_debut),
    };
  });

  return (
    <div className="calendar">
      <FullCalendar
        events={filteredData.map((task) => task)}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          start: "title",
          center: "today prev,next",
          end: "dayGridMonth,timeGridWeek,timeGridDay,new",
        }}
        customButtons={{
          new: {
            text: "List",
            click: () => console.log("new event"),
          },
        }}
      />
    </div>
  );
}
