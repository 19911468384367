import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import STcontext from "../../../context/STcontext";
import axios from "../../../context/axios";

export default function AffectationSim({ data, SimData }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { handelCombinedChange, appareil, getAppareil, SubmitSimCombinaison } =
    useContext(STcontext);
  const [updateSimCombination, setUpdateSimCombination] = useState({
    serial_number_sim: data.articleCombination_serial_number,
  });

  const handleChangeUpdateSimCombination = (e) => {
    const value = e.target.value;
    setUpdateSimCombination({
      ...updateSimCombination,
      serial_number_sim: value,
    });
  };

  const handleUpdateSimCombination = async (e, id) => {
    e.preventDefault();
    try {
      await axios.put(
        `/api/supportTechnique/UpdateSimCombination/${id}`,
        updateSimCombination
      );
      getAppareil();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handelCombinedChangeBefor = (e) => {
    if (data.articleCombination_serial_number === null) {
      handelCombinedChange(e);
    } else {
      handleChangeUpdateSimCombination(e);
    }
  };
  const handelCombinedSubmitBefor = (e) => {
    if (data.articleCombination_serial_number === null) {
      SubmitSimCombinaison(e, data.id);
      handleClose();
    } else {
      handleUpdateSimCombination(e, data.id);
    }
  };

  useEffect(() => {
    setUpdateSimCombination({
      ...updateSimCombination,
      serial_number_sim: data.articleCombination_serial_number,
    });
  }, [appareil.AllGPS]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "25px",
          width: "15%",
        }}
      >
        <Button
          variant="primary"
          style={{
            background: "none",
            color: "black",
            border: "none",
            width: "20%",
          }}
          onClick={handleShow}
        >
          <AddCircleIcon />
        </Button>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Affectation Sim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            method="POST"
            onSubmit={(e) => handelCombinedSubmitBefor(e, data.id)}
          >
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>IMEI: </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="GPSID"
                  key={data.id}
                  value={data.serial_number}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Sim : </Form.Label>
                <Form.Control
                  required
                  list="SimData"
                  type="text"
                  name="serial_number_sim"
                  autoComplete="off"
                  placeholder="Numero SIM"
                  onChange={(e) => handelCombinedChangeBefor(e)}
                  value={updateSimCombination.serial_number_sim}
                />
                <datalist id="SimData">
                  {SimData.map((sim) => (
                    <option key={sim.id}>{sim.serial_number}</option>
                  ))}
                </datalist>
              </Form.Group>
            </Row>

            <Modal.Footer>
              <Button type="submit" variant="primary">
                Affecter SIM
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
