import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import _ from "lodash";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Gpsaddmodel from "./Models/Gpsaddmodel";
import ComptableContext from "../../../context/ComptableContext";
import SimModel from "./Models/SimModel";
import UpdateModel from "./Models/UpdateModel";

const Gps = () => {
  const [toggle, setToggle] = useState(false);
  const [visibility, setVisibility] = useState("none");
  const [pointer, setPointer] = useState(null);
  const { typesim, getTypessim, addModelSubmit } = useContext(ComptableContext);

  const showInput = () => {
    // console.log("hello there")
    setToggle(!toggle);
  };

  useEffect(() => {
    getTypessim();
    if (toggle == true) {
      setVisibility("flex");
    } else if (toggle == false) {
      setVisibility("none");
    }
  }, [toggle]);

  const SubmittedAppareil = () => {
    setVisibility("none");
    setToggle(false);
  };
  const pointerChanger = () => {
    if (pointer == null) {
      setPointer("pointer");
    } else {
      setPointer(null);
    }
  };

  return (
    <div>
      <SimModel id="2" />
      <MDBContainer className="porfils pt-4" style={{ maxWidth: "1929" }}>
        <MDBAccordion initialActive={0}>
          {typesim
            ? typesim.map((appareil) => (
                <MDBAccordionItem
                  collapseId={appareil.id}
                  headerTitle={appareil.nameType}
                  key={appareil.id}
                >
                  <Gpsaddmodel
                    id={appareil.id}
                    data={appareil}
                    appareil_id="2"
                  />

                  <div
                    className="Table-tech"
                    style={{ width: "100%", overflowX: "auto", padding: "0" }}
                  >
                    <MDBTable align="middle">
                      <MDBTableHead>
                        <tr>
                          <th scope="col">Modele</th>
                          <th scope="col">Modifier</th>
                        </tr>
                      </MDBTableHead>

                      <MDBTableBody>
                        {appareil.modeles.map((modele) => (
                          <tr key={modele.id}>
                            <td>{modele.nameModele}</td>

                            <td>
                              <UpdateModel data={modele} appareil_id="2" />
                            </td>
                          </tr>
                        ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </MDBAccordionItem>
              ))
            : ""}
        </MDBAccordion>
      </MDBContainer>
    </div>
  );
};

export default Gps;
