import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import ComptableContext from "../../../context/ComptableContext";
import axios from "../../../context/axios";
import { AuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import { Checkbox } from "primereact/checkbox";
import "../AppStyle.css";

export default function AdminModel() {
  const { alltype, getAllTypes, getAllAppareilsForComtaple } =
    useContext(ComptableContext);
  const { getCOmptaAppareil } = useContext(AuthContext);
  const [ModelAppareil, setModelAppareil] = useState([]);
  const [checked, setChecked] = useState(true);
  const [errorMessages, setErrorMessages] = useState();

  useEffect(() => {
    getAllTypes();
  }, []);

  const handleTheTypeChange = (e) => {
    e.preventDefault();
    const ID = e.target.value;
    setModelAppareil(alltype.filter((type) => type.id == ID));
    e.preventDefault();
    setAppareilAdd({ ...AppareilAdd, type_id: "", Serie: "" });
    setArr([
      {
        type: "text",
        id: 1,
        value: "",
      },
    ]);
    setSentData({ ...sentData, Model: "" });
  };

  const [AppareilAdd, setAppareilAdd] = useState({
    Modele_id: "",
    Series: [],
  });

  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setSentData({ garantie: true });
    setAppareilAdd("");
    setModelAppareil("");
    setErrorMessages("");
    setArr([
      {
        type: "text",
        value: "",
        type_id: "",
      },
    ]);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const inputArr = [
    {
      type: "text",
      id: 1,
      value: "",
    },
  ];
  const [arr, setArr] = useState(inputArr);

  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };
  const [sentData, setSentData] = useState({
    ModelDevice: "",
    Serie: [],
    numberBox: "",
    numberOfAccessory: "",
    garantie: true,
  });
  const handleTheModeleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    // setAppareilAdd({ ...AppareilAdd, Modele_id: value });
    setSentData({ ...sentData, ModelDevice: value });
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const index = e.target.id;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;
      return newArr;
    });
    setAppareilAdd({ ...AppareilAdd, [name]: value });
  };

  useEffect(() => {
    setSentData({
      ...sentData,
      Serie: [...(arr ? arr.map((obj) => obj.value) : "")],
    });
  }, [arr]);
  useEffect(() => {
    setSentData({ ...sentData, garantie: checked });
  }, [checked]);

  const handleNumeroBox = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setSentData({ ...sentData, [name]: value });
  };
  // start add new articles
  const addNewArticle = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "api/comptable/addNewAppareil",
        sentData
      );
      getCOmptaAppareil();
      getAllAppareilsForComtaple();
      setChecked(true);
      handleClose();
      showSuccessMessage(response.data);
    } catch (err) {
      setErrorMessages(err.response.data.errors);
    }
  };

  //end add new articles

  const handleRemoveInput = (index) => {
    const newForm = [...arr];
    newForm.splice(index, 1);
    delete errorMessages[`Serie.${index}`];
    setArr(newForm);
  };


  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Nouveaux appareils{" "}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouveaux appareils</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={addNewArticle}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Type d'appareil</Form.Label>
                <Form.Select
                  onChange={handleTheTypeChange}
                  aria-label="Default select example"
                >
                  <option value="none" selected disabled hidden>
                    Select Type
                  </option>
                  <option disabled>Select Type</option>
                  {alltype.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.nameType}
                    </option>
                  ))}
                </Form.Select>

                {errorMessages && errorMessages.ModelDevice ? (
                  <span style={{ color: "red" }}>
                    {" "}
                    {errorMessages.ModelDevice[0]}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Modele appareil</Form.Label>
                <Form.Select
                  onChange={handleTheModeleChange}
                  aria-label="Default select example"
                >
                  <option value="none" selected disabled hidden>
                    Select Model
                  </option>
                  <option disabled>Select Model</option>
                  {ModelAppareil
                    ? ModelAppareil.map((Model) =>
                        Model.modeles.map((model) => (
                          <option value={model.id}>{model.nameModele}</option>
                        ))
                      )
                    : ""}
                </Form.Select>
                {errorMessages && errorMessages.ModelDevice ? (
                  <span style={{ color: "red" }}>
                    {" "}
                    {errorMessages.ModelDevice[0]}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label size="40">Box N°:</Form.Label>
                <Form.Control
                  className="form-control"
                  onChange={handleNumeroBox}
                  name="numberBox"
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label size="40">N° accessorie:</Form.Label>
                <Form.Control
                  type="number"
                  className="form-control"
                  onChange={handleNumeroBox}
                  name="numberOfAccessory"
                />
                {errorMessages && errorMessages.numberOfAccessory ? (
                  <span style={{ color: "red" }}>
                    {" "}
                    {errorMessages.numberOfAccessory[0]}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {arr
                ? arr.map((item, i) => {
                    return (
                      <Form.Group
                        key={i}
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Form.Label size="40">Serie N°:</Form.Label>
                        <Col style={{ display: "flex", gap: "15px" }}>
                          <Col>
                            <input
                              className="form-control"
                              onChange={handleChange}
                              value={item.value}
                              id={i}
                              type={item.type}
                              name="Serie"
                              autoComplete="off"
                            />
                            {errorMessages &&
                              errorMessages[`Serie.${i}`] &&
                              errorMessages[`Serie.${i}`].map(
                                (message, index) => (
                                  <span
                                    style={{ color: "red" }}
                                    key={index}
                                    className="error-message"
                                  >
                                    {message}
                                  </span>
                                )
                              )}
                          </Col>

                          <Button
                            variant="outline-danger"
                            style={{ maxHeight: "42px" }}
                            onClick={() => handleRemoveInput(i)}
                          >
                            <ClearIcon />
                          </Button>
                        </Col>
                      </Form.Group>
                    );
                  })
                : ""}

              {/* </Form.Group> */}
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="valid">
                <Button variant="secondary" onClick={addInput}>
                  <AddCircleIcon />
                </Button>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom2">
                <Form.Label>garantie: </Form.Label>
                <Checkbox
                  className="ms-3 mb-2"
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Ajouter Appareil
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
