import { useContext, useEffect, useState } from "react";
import "./style/commercialstyle.css";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { Column } from "primereact/column";
import CommercialContext from "../context/CommercialContext";
import { useLocation, useParams } from "react-router-dom";
import ConfirmPopup from "./updateAndDeleteButtons/ConfirmPopup";
import UpdateTaskToConfirm from "./updateAndDeleteButtons/UpdateTaskToConfirm";

export default function DetailsTasksClient() {
  const { client_id } = useParams();
  let location = useLocation();
  const nameClient = location.state.clientName;
  const {
    getTasksForEachClient,
    tasksPreClient,
    deleteTaskPreClient,
    getTaskInfo,
  } = useContext(CommercialContext);
  useEffect(() => {
    getTasksForEachClient(client_id);
    getTaskInfo();
  }, []);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const deviceOwnerBodyTemplate = (rowData) => {
    return (
      <td>
        {rowData.articles && rowData.articles.proprietaire == 1
          ? "client"
          : rowData.articles && rowData.articles.proprietaire === 0
          ? "D3 HI-TECH"
          : ""}
      </td>
    );
  };

  const renderOwerSim = (rowdata) => {
    return (
      <td>
        {rowdata.articles &&
        rowdata.articles.latest_sim_relating &&
        rowdata.articles.latest_sim_relating.length > 0
          ? rowdata.articles.latest_sim_relating.map((SIM) =>
              SIM.modeles ? SIM.modeles.types.nameType : ""
            )
          : ""}
      </td>
    );
  };

  const getSeverityEtat = (status) => {
    let validation = status.validation;
    let statu = status.etat_tache;
    switch (true) {
      case validation === 1 && statu === 0:
        return "info";
      case validation === 1 && statu === 1:
        return "success";
      case (validation === 1 && statu === 2) ||
        (statu === 2 && validation === 2):
        return "success";
      case validation === 1 && statu === 3:
        return "warning";
      case validation === 1 && statu === 4:
        return "danger";

      default:
        return null;
    }
  };
  const renderEtatTask = (status) => {
    let validation = status.validation;
    let statu = status.etat_tache;
    switch (true) {
      case statu == 0 && validation === 1:
        return "E.Att";
      case statu === 1 && validation === 1:
        return "En cours";
      case (statu === 2 && validation === 1) ||
        (statu === 2 && validation === 2):
        return "Terminée";
      case (statu === 3 && validation === 1) ||
        (statu === 3 && validation === 2):
        return "Reportée";
      case (statu === 4 && validation === 1) ||
        (statu === 4 && validation === 2):
        return "Annulé";
      default:
        return "E.C";
    }
  };

  const devicePositionBodyTemplate = (rowData) => {
    return (
      <td>
        {rowData.articles && rowData.articles.gps_principale == 1
          ? "Deuxième"
          : rowData.articles && rowData.articles.gps_principale === 0
          ? "Premier"
          : ""}
      </td>
    );
  };

  const TypeDeviceBody = (rowData) => {
    return (
      <td>
        {rowData.articles && rowData.articles.modeles
          ? rowData.articles.modeles.nameModele
          : ""}
      </td>
    );
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const renderformatDateBody = (rowData) => {
    return formatDate(rowData.date_previsionnelle_debut);
  };

  const renderEtatTaskBody = (rowData) => {
    return (
      <Tag
        value={renderEtatTask(rowData)}
        severity={getSeverityEtat(rowData)}
      />
    );
  };

  const renderActionBody = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          padding: "10px 0px",
        }}
      >
        <ConfirmPopup
          message="Etes vous sûre de vouloir supprimer cette tâche?"
          id={rowData.id}
          clientId={client_id}
          disabledButton={rowData.validation === 0 ? false : true}
          deleteFunction={(e) => deleteTaskPreClient(rowData.id, client_id)}
        />
        <UpdateTaskToConfirm
          taskToUpdate={rowData}
          disabledButton={rowData.validation === 0 ? false : true}
        />
      </div>
    );
  };

  const header = renderHeader();
  return (
    <div>
      <div className="tâches-tech">
        <div className="Table-tech-commerciale">
          <div className="numberTasks">
            <h5>
              <span className="NumberOfTasks">{nameClient}</span>
            </h5>
            <h6>
              Total Tâches &nbsp; <span class="vl"></span> &nbsp;{" "}
              <span className="NumberOfTasks">
                {" "}
                {tasksPreClient && tasksPreClient.length} Tâches
              </span>
            </h6>
          </div>
          <div>
            <DataTable
              value={tasksPreClient}
              dataKey="id"
              header={header}
              filters={filters}
              removableSort
              paginator
              rows={15}
              rowsPerPageOptions={[15, 25, 50, 100]}
              tableStyle={{
                minWidth: "50rem",
                backgroundColor: "#F24405",
              }}
              emptyMessage="Aucun résultat trouvé."
            >
              <Column
                header="#"
                headerStyle={{ width: "3rem" }}
                body={(data, options) => options.rowIndex + 1}
                bodyStyle={{ fontWeight: "bold" }}
              ></Column>
              <Column
                field="vehicules.matricule"
                header="Matricule"
                sortable
                style={{ width: "22%", height: "3rem" }}
              ></Column>
              <Column
                field="cataches.catache"
                header="Type du tache"
                sortable
                style={{ width: "15%", height: "3rem" }}
              ></Column>
              <Column
                field="articles.proprietaire"
                header="Prop d'app"
                body={deviceOwnerBodyTemplate}
                sortable
                style={{ width: "20%", height: "3rem" }}
              ></Column>
              <Column
                field="articles.modeles.types.nameType"
                header="Type d'app"
                body={TypeDeviceBody}
                sortable
                style={{ width: "20%", height: "3rem" }}
              ></Column>
              <Column
                field="articles.gps_principale"
                header="Position d'app"
                body={devicePositionBodyTemplate}
                sortable
                style={{ width: "20%", height: "3rem" }}
              ></Column>
              <Column
                field="articles.latest_sim_relating"
                header="Type SIM"
                body={renderOwerSim}
                sortable
                style={{ width: "20%", height: "3rem" }}
              ></Column>
              <Column
                field="date_prevesionnel_debut"
                header="Date debut"
                body={renderformatDateBody}
                sortable
                style={{ width: "20%", height: "3rem" }}
              ></Column>
              <Column
                field="etat_tache"
                header="Statut"
                body={renderEtatTaskBody}
                sortable
                style={{ width: "20%", height: "3rem" }}
              ></Column>
              <Column
                field="etat_tache"
                header="Action"
                body={renderActionBody}
                style={{ width: "20%", height: "3rem" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
