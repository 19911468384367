import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "./axios";

const TechnicianContext = createContext();

export const TechnicianProvider = ({ children }) => {
  const navigate = useNavigate();
  const [checkbox, setcheckbox] = useState(false);
  const [checkboxsim, setcheckboxsim] = useState(false);
  const [checkboxaccessoire, setcheckboxAssessoire] = useState(false);
  const [taskTechnicien, setTaskTechnicien] = useState([]);
  const [Task, setTask] = useState({});
  const [DATAS, setData] = useState({
    nomComplet: "",
    typeTache: "",
    TypeAppareil: "",
    IMEI: "",
    SiM: "",
    Date: "",
    Accessoire: "",
    affecté: "",
    TypeSIM: "",
    Description: "",
    images: [],
    observation: "",
    telephone: "",
    email: "",
    typeTask: "",
    Quantite: "1",
  });

  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [appareil, setAppareil] = useState({ GPS: [], SIM: [], Accessory: [] });

  const [installation, setInstallation] = useState({
    nomComplet: "",
    Matricule: "",
    IMEI: "",
    NSIM: "",
    GPSstatut: 0,
    Observation: "",
    imagesTask: [],
    etatTache: "",
    accessory: "",
    typeRelais: "",
  });

  const [reporte, setReporte] = useState({
    NouvelleDate: "",
    Observation: "",
  });

  const [annule, setAnnule] = useState({
    Observation: "",
  });

  const [reinstallation, setReinstallation] = useState({
    nomComplet: "",
    AncienMatricule: "",
    NouvelleMatricule: "",
    IMEI: "",
    NSIM: "",
    TypeAppareil: "",
    GPSProprietaire: "",
    Acessoire: "",
    Observation: "",
    images: [],
    etatTache: "",
    AllTaskOfClient: "",
  });

  const [desinstallation, setDesinstallation] = useState({
    nomComplet: "",
    Matricule: "",
    IMEI: "",
    NSIM: "",
    modelAppareil: "",
    GPSProprietaire: "",
    typeSim: "",
    Acessoire: "",
    Observation: "",
    imagesTask: [],
    endroit: [],
    affecteID: "",
    special: "tech",
    specialSIM: "tech",
    etatTache: "",
  });

  const [reparationSIM, setReparationSIM] = useState({
    Matricule: "",
    AncienSIM: "",
    NouveauSim: "",
    images: [],
    Observation: "",
  });

  const [reparationGPS, setReparationGPS] = useState({
    nomComplet: "",
    Matricule: "",
    AncienneIMEI: "",
    imei: "",
    SIM: "",
    proprietaireAncienIMEI: "",
    statut: "",
    Observation: "",
    images: [],
    etatTache: "",
    special: "",
  });

  const [reparationRelais, setReparationRelais] = useState({
    nomComplet: "",
    Matricule: "",
    typeRelais: "",
    images: [],
    Observation: "",
  });
  const [panneTask, setPanneTask] = useState();

  //error messege Function
  const showErrorMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  //success messege Function
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const [showImages, setShowImages] = useState([]);

  const handleFileEvent = async (e, typeTask) => {
    setShowImages(Array.from(e.target.files));
    const filesImages = e.target.files;
    const base64Images = [];
    const max_width = 960;
    const max_height = 1280;

    for (const image of filesImages) {
      const readerFile = new FileReader();
      readerFile.readAsDataURL(image);
      readerFile.onload = () => {
        const img = new Image();
        img.src = readerFile.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          // Calculate the new dimensions to maintain aspect ratio
          if (width > height && width > max_width) {
            height *= max_width / width;
            width = max_width;
          } else if (height > max_height) {
            width *= max_height / height;
            height = max_height;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          // Get the compressed image as base64 data URL
          const compressedDataUrl = canvas.toDataURL("image/jpeg", 0.8); // You can adjust the quality (0.8 is 80% quality)

          base64Images.push({
            data: compressedDataUrl.split(",")[1], // Extract the base64 image data
            extension: "jpg", // Assume the extension is jpg since we are compressing to jpeg
          });
        };
      };
    }
    await Promise.all(base64Images);
    if (typeTask === "desinstalation") {
      setDesinstallation({ ...desinstallation, images: base64Images });
    } else if (typeTask === "installation" || typeTask ==="installation gasoil" ) {
      setInstallation({ ...installation, images: base64Images });
    } else if (typeTask === "Changement du SIM") {
      setReparationSIM({ ...reparationSIM, images: base64Images });
    } else if (typeTask === "Changement du GPS") {
      setReparationGPS({ ...reparationGPS, images: base64Images });
    } else if (typeTask === "reparationRelais") {
      setReparationRelais({ ...reparationRelais, images: base64Images });
    } else if (typeTask === "Reinstallation") {
      setReinstallation({ ...reinstallation, images: base64Images });
    } else if (typeTask === "Panne") {
      setPanneTask({ ...panneTask, images: base64Images });
    }
  };

  const handleFileReinstallationEvent = (e) => {
    const value = [...e.target.files];
    setReinstallation({
      ...reinstallation,
      images: [...reinstallation.images, ...value],
    });
  };

  const handleFileDesinstallation = (e) => {
    const value = [...e.target.files];
    setDesinstallation({
      ...desinstallation,
      images: [...desinstallation.images, ...value],
    });
  };

  const handleFileReparationGPS = (e) => {
    const value = [...e.target.files];
    setReparationGPS({
      ...reparationGPS,
      images: [...reparationGPS.images, ...value],
    });
  };

  const handleFileReparationSim = (e) => {
    const value = [...e.target.files];
    setReparationSIM({
      ...reparationSIM,
      images: [...reparationSIM.images, ...value],
    });
  };

  const handleFileReparationRelais = (e) => {
    const value = [...e.target.files];
    setReparationRelais({
      ...reparationRelais,
      images: [...reparationRelais.images, ...value],
    });
  };

  const getAppareil = async () => {
    const { data } = await axios.get("/api/technicien/appareils");
    setAppareil(data);
  };
  const [AffectedArticle, setAffectedArticle] = useState({
    technicien: "",
  });
  const handleAffectedArticleOption = (CheckBoxAffectedArticle) => {
    setAffectedArticle({
      ...AffectedArticle,
      Articles: CheckBoxAffectedArticle,
    });
  };
  const [devicesReturned, setDevicesReturned] = useState([]);
  const getReturnedDevices = async () => {
    const result = await axios.get("/api/technicien/getAllReturnedDevices");
    setDevicesReturned(result.data);
  };

  const returnedToST = async (e) => {
    e.preventDefault();
    await axios.post("api/technicien/affectedReturnedDevices", AffectedArticle);
    getReturnedDevices();
  };

  const handleAddTask = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...DATAS, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setcheckbox(!checkbox);
  };

  const handleCheckBoxSimChange = (e) => {
    setcheckboxsim(!checkboxsim);
  };

  const handleCheckBoxAccChange = (e) => {
    setcheckboxAssessoire(!checkboxaccessoire);
  };

  const getTask = async (ID) => {
    const res = await axios.get(`/api/technicien/getTask/${ID}`);
    setTask(res.data.getTask);

    setAnnule({
      ...annule,
      nomComplet: res.data.getTask.clients.nomComplet,
      Matricule: res.data.getTask.vehicules
        ? res.data.getTask.vehicules.matricule
        : "",
    });

    setInstallation({
      ...installation,
      nomComplet: res.data.getTask.clients.nomComplet,
      Matricule: res.data.getTask.vehicules
        ? res.data.getTask.vehicules.matricule
        : "",
      IMEI: res.data.getTask.articles
        ? res.data.getTask.articles.serial_number
        : "",
      TypeAppareil: res.data.getTask.articles
        ? res.data.getTask.articles.modeles
        : "",
      NSIM:
        (res.data.getTask.articles
          ? res.data.getTask.articles.articles_combination
          : ""
        ).length > 0
          ? res.data.getTask.articles.articles_combination.map(
              (article_comb) => article_comb.serial_number
            )[0]
          : "",
      GPSstatut: res.data.getTask.articles
        ? res.data.getTask.articles.gps_principale
        : "",
      Acessoire:
        res.data.getTask.vehicules &&
        res.data.getTask.vehicules.articles &&
        res.data.getTask.vehicules.articles.length > 0
          ? res.data.getTask.vehicules.articles.map(
              (article) => article.serial_number
            )[0]
          : "",
      GPSProprietaire: res.data.getTask.articles,
    });

    setReinstallation({
      ...reinstallation,
      nomComplet: res.data.getTask.clients.nomComplet,
      AncienMatricule: res.data.getTask.clients.vehicules
        ? res.data.getTask.clients.vehicules
        : "",
      IMEI:
        res.data.getTask.articles != null
          ? res.data.getTask.articles.serial_number
          : "",
      TypeAppareil:
        res.data.getTask.articles != null && res.data.getTask.articles.modeles
          ? res.data.getTask.articles.modeles.types.nameType
          : "",
      NSIM:
        (
          res.data.getTask.articles != null &&
          res.data.getTask.articles.articles_combination
        ).length > 0
          ? res.data.getTask.articles.articles_combination.map(
              (article_comb) => article_comb.serial_number
            )[0]
          : "",
      GPSProprietaire:
        res.data.getTask.articles != null
          ? res.data.getTask.articles.gps_principale
          : "",
      Acessoire:
        res.data.getTask.vehicules &&
        res.data.getTask.vehicules.articles &&
        res.data.getTask.vehicules.articles.length > 0
          ? res.data.getTask.vehicules.articles.map(
              (article) => article.serial_number
            )[0]
          : "",
      // GPSProprietaire:res.data.getTask.articles,
      // GPSProprietaire:res.data.getTask.articles != null ? res.data.getTask.articles.gps_principale :"",
      AllTaskOfClient: res.data.articleOfClient,
    });

    setDesinstallation({
      ...desinstallation,
      nomComplet: res.data.getTask.clients.nomComplet,
      Matricules: res.data.getTask.clients.vehicules
        ? res.data.getTask.clients.vehicules
        : "",
      IMEI:
        res.data.getTask.articles != null
          ? res.data.getTask.articles.serial_number
          : "",
      modelAppareil:
        res.data.getTask.articles != null && res.data.getTask.articles.modeles
          ? res.data.getTask.articles.modeles
          : "",
      NSIM:
        (
          res.data.getTask.articles != null &&
          res.data.getTask.articles.articles_combination
        ).length > 0
          ? res.data.getTask.articles.articles_combination.map(
              (article_comb) => article_comb.serial_number
            )[0]
          : "",
      GPSProprietaire:
        res.data.getTask.articles != null &&
        res.data.getTask.articles.gps_principale,
      Acessoire:
        res.data.getTask.vehicules &&
        res.data.getTask.vehicules.articles &&
        res.data.getTask.vehicules.articles.length > 0
          ? res.data.getTask.vehicules.articles.map(
              (article) => article.serial_number
            )[0]
          : "",
      // GPSProprietaire:res.data.getTask.articles,
      endroit: res.data.getST,
      AllTaskOfClient: res.data.articleOfClient,
      allModelsOfGps: res.data.allModelsOfGps,
    });

    setReparationSIM({
      ...reparationSIM,
      nomComplet: res.data.getTask.clients.nomComplet,
      Matricules: res.data.getTask.clients.vehicules
        ? res.data.getTask.clients.vehicules
        : "",
      AncienSIM:
        (
          res.data.getTask.articles != null &&
          res.data.getTask.articles.articles_combination
        ).length > 0
          ? res.data.getTask.articles.articles_combination.map(
              (article_comb) => article_comb.serial_number
            )[0]
          : "",
      endroit: res.data.getST,
      AllTaskOfClient: res.data.articleOfClient,
    });

    setReparationGPS({
      ...reparationGPS,
      nomComplet: res.data.getTask.clients.nomComplet,
      Matricules: res.data.getTask.clients.vehicules
        ? res.data.getTask.clients.vehicules
        : "",
      AncienneIMEI:
        res.data.getTask.articles != null
          ? res.data.getTask.articles.serial_number
          : "",
      TypeAppareil:
        res.data.getTask.articles != null &&
        res.data.getTask.articles.modeles != null
          ? res.data.getTask.articles.modeles.types.nameType
          : "",
      NSIM:
        res.data.getTask.articles != null &&
        res.data.getTask.articles.articles_combination.length > 0
          ? res.data.getTask.articles.articles_combination.map(
              (article_comb) => article_comb.serial_number
            )[0]
          : "",
      statut:
        res.data.getTask.articles != null
          ? res.data.getTask.articles.gps_principale
          : "",
      endroit: res.data.getST,
      AllTaskOfClient: res.data.articleOfClient,
    });

    setReparationRelais({
      ...reparationRelais,
      nomComplet: res.data.getTask.clients.nomComplet,
      Matricule: res.data.getTask.vehicules
        ? res.data.getTask.vehicules.matricule
        : "",
      typeRelais:
        res.data.getTask.vehicules &&
        res.data.getTask.vehicules.articles &&
        res.data.getTask.vehicules.articles.length > 0
          ? res.data.getTask.vehicules.articles.map(
              (article) => article.serial_number
            )[0]
          : "",
      GPSProprietaire: res.data.getTask.articles,
    });
  };

  const handleAddChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInstallation({ ...installation, [name]: value });
  };

  const handleAddAccessory = (selectedOptions) => {
    const selectedSerialNumbers = selectedOptions.map((option) => option.value);
    // Update the accessory value in dataToUpdate state
    setInstallation((prevState) => ({
      ...prevState,
      accessory:
        selectedSerialNumbers.length > 0 ? selectedSerialNumbers : null,
    }));
  };

  const handleAddReporte = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setReporte({ ...reporte, [name]: value });
  };

  const handleAddAnnule = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAnnule({ ...annule, [name]: value });
  };

  const handleAddReinstalle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setReinstallation({ ...reinstallation, [name]: value });
  };

  const handleDesinstallChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDesinstallation({ ...desinstallation, [name]: value });
  };

  const handleReparationSim = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setReparationSIM({ ...reparationSIM, [name]: value });
  };

  const handleReparationGPS = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setReparationGPS({
      ...reparationGPS,
      [name]: value,
    });
  };

  const handleChangeEdroit = (e) => {
    const value = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex]; // Get the selected option
    const data_special = selectedOption.getAttribute("data-special");
    setReparationGPS({
      ...reparationGPS,
      affecteID: value,
      special: data_special,
    });
  };

  const handleReparationRelais = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setReparationRelais({ ...reparationRelais, [name]: value });
  };

  const installationSubmit = async (TASKID) => {
    try {
      const instalationTermine = await axios.put(
        `/api/technicien/updateTask/${TASKID}/installation`,
        installation
      );
      if (instalationTermine.data.message === 200) {
        setInstallation({
          nomComplet: "",
          Matricule: "",
          IMEI: "",
          NSIM: "",
          GPSstatut: "",
          Observation: "",
          imagesTask: [],
          etatTache: "",
          accessory: "",
          typeRelais: "",
        });
        showSuccessMessage("installation succès");
        setShowImages("");
        geTaskTechnician();
        setErrorMessage(null);
        navigate("technicien/tâches");
      } else {
        showErrorMessage("Error  installation !");
      }
    } catch (error) {
      setErrorMessage(error.response.data.errors);
    }
  };

  const reinstallationSubmit = async (TASKID) => {
    try {
      setReinstallation({ ...reinstallation, etatTache: 2 });
      await axios.put(
        `/api/technicien/reinstallationTask/${TASKID}`,
        reinstallation
      );
      setShowImages("");
      setReinstallation({
        nomComplet: "",
        AncienMatricule: "",
        NouvelleMatricule: "",
        IMEI: "",
        NSIM: "",
        TypeAppareil: "",
        GPSProprietaire: "",
        Acessoire: "",
        Observation: "",
        images: [],
        etatTache: "",
        AllTaskOfClient: "",
      });
      showSuccessMessage("reinstallation succès");
      await geTaskTechnician();
      navigate("technicien/tâches");
    } catch (error) {
      showErrorMessage("Échec  reinstallation !");
      setErrorMessage(error.response.data.errors);
    }
  };

  const desinstallationSubmit = async (TASKID) => {
    try {
      await axios.put(
        `/api/technicien/uninstallTask/${TASKID}`,
        desinstallation
      );
      showSuccessMessage("la tâche modifiée avec succès");
      setShowImages("");
      navigate("technicien/tâches");
      setDesinstallation({
        imagesTask: [],
        endroit: [],
        special: "tech",
        specialSIM: "tech",
        etatTache: "",
      });
      setErrorMessage();
    } catch (error) {
      showErrorMessage("Échec de la modification de la tâche");
      setErrorMessage(error.response.data.errors);
    }
  };

  const reporteSubmit = async (TASKID) => {
    try {
      const res = await axios.put(
        `/api/technicien/postponeTask/${TASKID}`,
        reporte
      );
      setReporte(null);
      showSuccessMessage("tâche reportée avec succès");
      navigate("technicien/tâches");
    } catch {
      showErrorMessage("Le report de la tâche a échoué");
    }
  };

  const annuleSubmit = async (TASKID) => {
    try {
      await axios.put(`/api/technicien/cancelTask/${TASKID}`, annule);
      showSuccessMessage("la tâche annulée avec succès");
      geTaskTechnician();
      navigate("technicien/tâches");
      setAnnule({ Observation: "" });
    } catch {
      showErrorMessage("Échec de l'annulation de la tâche");
    }
  };

  const geTaskTechnician = async () => {
    const res = await axios.get("/api/technicien/tâches");
    setTaskTechnicien(res.data);
  };

  //update tasks function
  const submitUpdateTask = async (e, updateTask, id) => {
    e.preventDefault();
    try {
      await axios.put(`api/technicien/updateTaskTermined/${id}`, updateTask);
      showSuccessMessage("la tâche modifiée avec succès");
      geTaskTechnician();
    } catch (error) {
      showErrorMessage("Échec de la modification de la tâche");
    }
  };

  ////

  //filter Client Info
  const [filterClientInfo, setFilterClientInfo] = useState({});

  const handleChangeName = (e, Clients) => {
    const nameClient = e.target.value;
    const name = e.target.name;
    const filterByNameClients = Clients.filter(
      (client) => client.nomComplet === e.target.value
    );
    if (filterByNameClients.length > 0) {
      setFilterClientInfo(...filterByNameClients);
      setClientInfo(...filterByNameClients);
    } else {
      setClientInfo({ [name]: nameClient });
      setFilterClientInfo({});
    }
  };

  const setClientInfo = (clientInfo) => {
    setData({
      ...DATAS,
      nomComplet: clientInfo.nomComplet,
      telephone: clientInfo.telephone_client,
      email: clientInfo.email_client,
      clientCity: clientInfo.city ? clientInfo.city.id : null,
    });
  };

  const SumbitTachConfirmée = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/technicien/taskToConfirmed", DATAS);
      setShow(false);
      setData({ Quantite: 1 });
      getAllTaskToConfirmed();
      showSuccessMessage("succès ajouter tâche");
      setErrorMessage();
    } catch (error) {
      setErrorMessage(error.response.data.errors);
    }
  };
  // end add new Task

  const ReparationSimSubmit = async (TASKID) => {
    try {
      await axios.put(`/api/technicien/changeSim/${TASKID}`, reparationSIM);
      setShowImages();
      navigate("technicien/tâches");
      showSuccessMessage("la tâche modifiée avec succès");
      setReparationSIM({
        Matricule: "",
        AncienSIM: "",
        NouveauSim: "",
        images: [],
        Observation: "",
      });
      setErrorMessage();
    } catch (e) {
      showErrorMessage("Échec de la modification de la tâche");
      setErrorMessage(e.response.data.errors);
    }
  };

  const [reparationTaskData, setReparationTaskData] = useState();
  const getTasksDataForReparation = async (TASKID) => {
    setReparationTaskData();
    const res = await axios.get(
      `/api/technicien/getTasksDataForReparation/${TASKID}`
    );
    setReparationTaskData(res.data);
  };
  const [relocationTaskData, setRelocationTaskData] = useState();
  const getTasksDataForRelocation = async (TASKID) => {
    setRelocationTaskData();
    const res = await axios.get(
      `/api/technicien/getTasksDataForRelocation/${TASKID}`
    );
    setRelocationTaskData(res.data);
  };
  const ReparationGPSSubmit = async (TASKID) => {
    try {
      const responseReparationGPS = await axios.put(
        `/api/technicien/changeDevice/${TASKID}`,
        reparationGPS
      );
      setReparationGPS({
        etatTache: "",
        special: "",
      });
      setShowImages([]);
      navigate("technicien/tâches");
      showSuccessMessage("la tâche modifiée avec succès");
    } catch (e) {
      showErrorMessage("Échec de la modification de la tâche");
      setErrorMessage(e.response.data.errors);
    }
  };

  const ReparationRelaisSubmit = async (TASKID) => {
    try {
      await axios.put(
        `/api/technicien/changeRelai/${TASKID}`,
        reparationRelais
      );
      navigate("technicien/tâches");
      showSuccessMessage("la tâche modifiée avec succès");
      setReparationRelais({
        nomComplet: "",
        Matricule: "",
        typeRelais: "",
        images: [],
        Observation: "",
      });
      setShowImages([]);
    } catch (e) {
      showErrorMessage(e.response.data.message);
      setErrorMessage(e.response.data.errors);
    }
  };

  const panneSubmit = async (TASKID) => {
    try {
      const result = await axios.put(
        `/api/technicien/panneTask/${TASKID}`,
        panneTask
      );
      setPanneTask();
      setShowImages([]);
      showSuccessMessage("la tâche modifiée avec succès");
      navigate("technicien/tâches");
    } catch (error) {
      showErrorMessage("Échec de la modification de la tâche");
      setErrorMessage(error.response.data.errors);
    }
  };

  //all Tasks To Confirmed of Technicien

  const [allTasksToConfirmed, setAllTasksToConfirmed] = useState([]);

  const getAllTaskToConfirmed = async () => {
    const res = await axios.get("/api/technicien/taskToConfirmed");
    setAllTasksToConfirmed(res.data);
  };

  const [allUsers, setAllUsers] = useState([]);

  const getAllUsers = async () => {
    const response = await axios.get("api/technicien/allUsers");
    setAllUsers(response.data);
  };

  //set the state for finish tasks
  const [clientInfoTask, setClientInfoTask] = useState({});
  return (
    <TechnicianContext.Provider
      value={{
        DATAS,
        handleAddAccessory,
        taskTechnicien,
        setTaskTechnicien,
        geTaskTechnician,
        submitUpdateTask,
        handleAddTask,
        handleCheckboxChange,
        checkbox,
        setData,
        checkboxaccessoire,
        checkboxsim,
        handleCheckBoxAccChange,
        handleCheckBoxSimChange,
        getAppareil,
        appareil,
        getTask,
        Task,
        setTask,
        handleAddChange,
        installation,
        handleFileEvent,
        reporte,
        handleAddReporte,
        handleAddAnnule,
        annule,
        handleFileReinstallationEvent,
        reinstallation,
        handleAddReinstalle,
        desinstallation,
        handleFileDesinstallation,
        handleDesinstallChange,
        installationSubmit,
        reinstallationSubmit,
        desinstallationSubmit,
        reporteSubmit,
        annuleSubmit,
        filterClientInfo,
        setFilterClientInfo,
        handleChangeName,
        SumbitTachConfirmée,
        setInstallation,
        setDesinstallation,
        setAnnule,
        setReporte,
        setReinstallation,
        setReparationRelais,
        reparationRelais,
        panneTask,
        setPanneTask,
        panneSubmit,
        setReparationGPS,
        reparationGPS,
        setReparationSIM,
        reparationSIM,
        handleReparationSim,
        handleReparationGPS,
        handleChangeEdroit,
        handleFileReparationSim,
        handleFileReparationGPS,
        ReparationSimSubmit,
        getTasksDataForReparation,
        reparationTaskData,
        getTasksDataForRelocation,
        relocationTaskData,
        ReparationGPSSubmit,
        handleReparationRelais,
        ReparationRelaisSubmit,
        handleFileReparationRelais,
        getAllTaskToConfirmed,
        allTasksToConfirmed,
        setShow,
        show,
        showImages,
        allUsers,
        getAllUsers,
        getReturnedDevices,
        devicesReturned,
        handleAffectedArticleOption,
        AffectedArticle,
        returnedToST,
        errorMessage,
        setErrorMessage,
        clientInfoTask,
        setClientInfoTask,
      }}
    >
      {children}
    </TechnicianContext.Provider>
  );
};
export default TechnicianContext;
