import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ComptableContext from "../../../context/ComptableContext";
import { Checkbox } from "primereact/checkbox";
import "../AppStyle.css";
export default function AdminModel() {
  const {
    errorMessage,
    setErrorMessage,
    alltype,
    getAllTypes,
    readExcelFile,
    handleChangeImportAppareils,
    handleSubmitImportAppareils,
    setDevicesNotInserted,
    devicesNotInserted,
    handleGarantie,
  } = useContext(ComptableContext);
  const [ModelAppareil, setModelAppareil] = useState([]);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    getAllTypes();
  }, []);

  useEffect(() => {
    if (errorMessage === 200 && devicesNotInserted === null) {
      setChecked(true);
      handleClose();
    }
  }, [errorMessage]);

  useEffect(() => {
    handleGarantie(checked);
  }, [checked]);

  // const [errorMessage, setErrorMessage] = useState([]);

  const handleTheTypeChange = (e) => {
    e.preventDefault();
    const ID = e.target.value;
    setModelAppareil(alltype.filter((type) => type.id == ID));
    e.preventDefault();
    setAppareilAdd({ ...AppareilAdd, type_id: "", Serie: "" });
    setSentData({ ...sentData, ModelDevice: "", Serie: [] });
  };

  const [AppareilAdd, setAppareilAdd] = useState({
    Modele_id: "",
    Series: [],
  });
  const handleTheSerieChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAppareilAdd({ ...AppareilAdd, [name]: value });
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setDevicesNotInserted(null);
    setSentData("");
    setAppareilAdd("");
    setModelAppareil("");
    setErrorMessage("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [sentData, setSentData] = useState({
    ModelDevice: "",
    Serie: [],
    numberBox: "",
    numberOfAccessory: "",
  });

  const devicesNotImportedBody = (devicesNotInserted) => {
    return (
      <>
        <hr />
        <h6> Non Importer:</h6>
        {devicesNotInserted.map((device, index) => (
          <ul key={index} style={{ display: "inline-block" }}>
            <li>{device.serial_number}</li>
          </ul>
        ))}
      </>
    );
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Import appareils{" "}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import appareils</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={handleSubmitImportAppareils}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>
                  Import appareils &nbsp;
                  <span className="star-Mandatory-field">*</span> &nbsp;:{" "}
                </Form.Label>
                <Form.Select
                  onChange={handleTheTypeChange}
                  aria-label="Default select example"
                >
                  <option value="none" selected disabled hidden>
                    Select Type
                  </option>
                  <option disabled>Select Type</option>
                  {alltype.map((types, index) => (
                    <option key={index} value={types.id}>
                      {types.nameType}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>
                  Modele appareil &nbsp;
                  <span className="star-Mandatory-field">*</span> &nbsp;:{" "}
                </Form.Label>
                <Form.Select
                  name="ModelDevice"
                  onChange={handleChangeImportAppareils}
                >
                  <option value="none" selected disabled hidden>
                    Select Model
                  </option>
                  <option disabled>Select Model</option>
                  {ModelAppareil
                    ? ModelAppareil.map((Model) =>
                        Model.modeles.map((model, index) => (
                          <option key={index} value={model.id}>
                            {model.nameModele}
                          </option>
                        ))
                      )
                    : ""}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label size="40">Box N°:</Form.Label>
                <Form.Control
                  className="IMEI-input"
                  onChange={handleChangeImportAppareils}
                  name="numberBox"
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>
                  Excel File &nbsp;
                  <span className="star-Mandatory-field">*</span> &nbsp;:{" "}
                  </Form.Label>
                <Form.Control
                  type="file"
                  accepte=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcelFile(file);
                  }}
                  name="fileAppareil"
                  autoComplete="off"
                />
              </Form.Group>

              {/* </Form.Group> */}
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom2">
                <Form.Label>garantie: </Form.Label>
                <Checkbox
                  className="ms-3 mb-2"
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>
              </Form.Group>
              {/* </Form.Group> */}
            </Row>

            {devicesNotInserted && devicesNotInserted.length > 0
              ? devicesNotImportedBody(devicesNotInserted)
              : ""}
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Ajouter appareil(s)
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
