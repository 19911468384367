import React from "react";
import AppCards from "./AppCards";
import "./AppStyle.css";
import ModelAppareil from "./modelAddAppareil/ModelAppareil";
import ImportAppareil from "./modelAddAppareil/ImportAppareil";

export default function Comptableapp() {
  return (
    <div>
      <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
        <ModelAppareil />
        <ImportAppareil />
      </div>
      <AppCards />
    </div>
  );
}
