import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import "./login.css";

const Login = () => {
  const { handleLogin, handleChange, error, formValue } =
    useContext(AuthContext);

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" method="post" onSubmit={handleLogin}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="errorMesage">
            {error ? <label>{error}</label> : null}
          </div>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              className="form-control mt-1"
              value={formValue.email}
              onChange={handleChange}
              placeholder="Email"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control mt-1"
              value={formValue.password}
              onChange={handleChange}
              placeholder="******"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
