import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";

const GpsTable = ({ GpsInstall }) => {
  const [allGpsInstall, setAllGpsInstall] = useState([]);
  const [pages, setPages] = useState(1);
  const [rowPrePage, setRowPrePage] = useState(6);
  const lastIndexOfAppareil = pages * rowPrePage;
  const firstIndexOfAppareil = lastIndexOfAppareil - rowPrePage;
  useEffect(() => {
    if (GpsInstall.numberModel != null) {
      setAllGpsInstall(
        GpsInstall?.numberModel.filter((obj) => obj.appareil_id === 1)
      );
    }
  }, [GpsInstall.numberModel]);

  const currentAppareil = allGpsInstall.slice(
    firstIndexOfAppareil,
    lastIndexOfAppareil
  );

  let numberOfPages = [];
  for (let p = 1; p <= Math.ceil(allGpsInstall.length / rowPrePage); p++) {
    numberOfPages.push(p);
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "30rem",
        padding: "2rem 2rem 0.2rem 2rem",
        minWidth: "",
        borderRadius: "2rem",
      }}
    >
      <p>Nombre de gps installée est : {GpsInstall?.numberGPS}</p>
      <MDBTable align="middle">
        <MDBTableHead style={{ border: "30px" }}>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Modéle</th>
            <th scope="col">Type</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {currentAppareil.map((data, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex align-items-center">
                  <div className="ms-3">
                    <p className="fw-bold mb-1">{data.total}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className="fw-normal mb-1">{data.nameModele}</p>
              </td>
              <td>
                <p className="fw-normal mb-1">{data.nameType}</p>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
      {allGpsInstall.length > 0 ? (
        <nav aria-label="...">
          <MDBPagination className="mb-0 d-flex justify-content-end">
            <MDBPaginationItem disabled>
              <MDBPaginationLink href="#" tabIndex={-1} aria-disabled="true">
                Pages
              </MDBPaginationLink>
            </MDBPaginationItem>
            {numberOfPages.map((numberPage, index) => (
              <MDBPaginationItem
                key={index}
                className={`${numberPage == pages ? "active" : "null"}`}
              >
                <MDBPaginationLink
                  style={{ cursor: "pointer" }}
                  onClick={() => setPages(numberPage)}
                >
                  {numberPage}
                </MDBPaginationLink>
              </MDBPaginationItem>
            ))}
          </MDBPagination>
        </nav>
      ) : (
        ""
      )}
    </div>
  );
};

export default GpsTable;
