import React, { useContext, useEffect } from "react";
import _ from "lodash";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";

import GpsModel from "./Models/GpsModel";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Gpsaddmodel from "./Models/Gpsaddmodel";
import UpdateModel from "./Models/UpdateModel";
import ComptableContext from "../../../context/ComptableContext";

const Gps = () => {
  const { type, getTypesgps } = useContext(ComptableContext);

  useEffect(() => {
    getTypesgps();
  }, []);
  return (
    <div>
      <GpsModel id="1" />

      <MDBContainer className="porfils pt-4" style={{ maxWidth: "1929" }}>
        <MDBAccordion initialActive={0}>
          {type
            ? type.map((appareil) => (
                <MDBAccordionItem
                  collapseId={appareil.id}
                  headerTitle={appareil.nameType}
                  key={appareil.id}
                >
                  <Gpsaddmodel
                    appareil_id="1"
                    id={appareil.id}
                    data={appareil}
                  />
                  <div
                    className="Table-tech"
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <MDBTable align="middle">
                      <MDBTableHead>
                        <tr>
                          <th scope="col">Modele</th>
                          <th scope="col">Modifier</th>
                        </tr>
                      </MDBTableHead>

                      <MDBTableBody>
                        {appareil.modeles.map((modele) => (
                          <tr key={modele.id}>
                            <td>{modele.nameModele}</td>

                            <td>
                              <UpdateModel data={modele} appareil_id="1" />
                            </td>
                          </tr>
                        ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </MDBAccordionItem>
              ))
            : ""}
        </MDBAccordion>
      </MDBContainer>
    </div>
  );
};

export default Gps;
