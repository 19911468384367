import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ComptableContext from "../../context/ComptableContext";
const UpdateClientInfo = ({ data }) => {
  const { allClients, allCities, sumitUpdateCustomerInfo } =
    useContext(ComptableContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [updateCustomerInfo, setUpdateCustomerInfo] = useState(null);
  useEffect(() => {
    setUpdateCustomerInfo({
      id: data.id,
      nomComplet: data.nomComplet,
      telephone_client: data.telephone_client,
      email_client: data.email_client,
      city: data.city != null && data.city.cityName,
    });
  }, [data]);
  const handleUpdateCustomerInfo = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUpdateCustomerInfo({ ...updateCustomerInfo, [name]: value });
  };

  useEffect(() => {
    handleClose();
  }, [allClients]);

  return (
    <div>
      <div style={{ padding: "10px" }}>
        <Button
          variant="none"
          style={{ border: "none" }}
          onClick={() => setShow(true)}
        >
          <BorderColorIcon sx={{ color: "#00c496" }} />
        </Button>
      </div>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Édit information client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form
            noValidate
            method="POST"
            onSubmit={(e) => sumitUpdateCustomerInfo(e, updateCustomerInfo)}
          >
            <Form.Group
              as={Col}
              className="mb-3"
              controlId="formGridnomComplet"
            >
              <Form.Label>Nom</Form.Label>
              <Form.Control
                name="nomComplet"
                type="text"
                placeholder="Enter Nom"
                autoComplete="off"
                value={updateCustomerInfo && updateCustomerInfo.nomComplet}
                onChange={handleUpdateCustomerInfo}
              />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="formGridPassword">
              <Form.Label>Telephone</Form.Label>
              <Form.Control
                type="text"
                name="telephone_client"
                placeholder="+212 6111111111"
                autoComplet="off"
                value={
                  updateCustomerInfo && updateCustomerInfo.telephone_client
                }
                onChange={handleUpdateCustomerInfo}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email_client"
                placeholder="Enter email"
                autoComplet="off"
                value={updateCustomerInfo && updateCustomerInfo.email_client}
                onChange={handleUpdateCustomerInfo}
              />
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="">
              <Form.Label>réalisée à: </Form.Label>
              <Form.Select
                name="clientCity"
                autoComplete="off"
                onChange={handleUpdateCustomerInfo}
              >
                <option selected hidden>
                  {(updateCustomerInfo && updateCustomerInfo.city) ||
                    "select ville"}
                </option>

                <option disabled>select ville</option>
                {allCities &&
                  allCities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.cityName}
                    </option>
                  ))}
              </Form.Select>
              {/* <span style={{ color: "red" }}>
                {error && error.clientCity ? error.clientCity[0] : ""}
              </span> */}
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                margin: "15px 0px",
              }}
            >
              <Button
                variant="primary"
                type="submit"
                style={{
                  width: "7rem",
                }}
              >
                enregistrer
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateClientInfo;
