import React, { useContext, useEffect, useState } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import { ShowImages } from "./ShowImages";
import TechnicianContext from "../context/TechnicienContext";

export const Panne = () => {
  const {
    handleFileEvent,
    getTasksDataForReparation,
    reparationTaskData,
    setPanneTask,
    panneTask,
    panneSubmit,
    errorMessage,
    setErrorMessage,
    clientInfoTask,
  } = useContext(TechnicianContext);
  const { id, statu } = useParams();
  const navigateTo = useNavigate();
  const handleSelectTypePane = (e) => {
    if (e.target.value == 5) {
      navigateTo(`/termineReparationGPS/${id}/Changement du GPS`);
    } else if (e.target.value == 4) {
      navigateTo(`/termineReparationSIM/${id}/Changement du SIM`);
    } else if (e.target.value == 6) {
      navigateTo(`/termineReparationRelais/${id}/reparationRelais`);
    }
  };

  useEffect(() => {
    getTasksDataForReparation(id);
    setErrorMessage(null);
  }, []);
  const [vehiculeInfo, setVehiculeInfo] = useState(null);

  const hanleFilterByDevice = async (e) => {
    const getIdVehicule = await reparationTaskData?.allDeviceInstalled.filter(
      (idVehicule) => idVehicule.serial_number === e.target.value
    );
    if (getIdVehicule.length > 0) {
      setVehiculeInfo(...getIdVehicule);
    } else {
      setVehiculeInfo(null);
    }
  };
  useEffect(() => {
    if (vehiculeInfo != null) {
      const vehicule = reparationTaskData.vehicules.filter(
        (articleInfo) => articleInfo.id === vehiculeInfo.vehicule_id
      )[0];
      setPanneTask({
        ...panneTask,
        Matricule: vehicule ? vehicule.matricule : "",
        IMEI: vehiculeInfo ? vehiculeInfo.serial_number : "",
      });
    } else {
      setPanneTask(null);
    }
  }, [vehiculeInfo]);
  return (
    <div>
      <div>
        {/* <ErrorMessage /> */}
        <div
          style={{
            backgroundColor: "white",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "1rem",
          }}
        >
          <MDBContainer>
            <MDBCardHeader>
              <h5> Panne</h5>
            </MDBCardHeader>
            <hr></hr>

            <Form noValidate id="myForm" encType="multipart/form-data">
              <Row className="mb-4">
                {/* Nom client */}

                <Form.Group as={Col} md="3" controlId="validationCustom04">
                  <Form.Label>Nom Client: </Form.Label>
                  <Form.Control
                    name="nomComplet"
                    disabled
                    required
                    type="text"
                    placeholder="Nom client"
                    value={clientInfoTask.client_name}
                  />
                </Form.Group>

                {/* Matricule */}

                <Form.Group as={Col} md="3" controlId="validationCustom04">
                  <Form.Label>Type Panne: </Form.Label>
                  <Form.Select
                    required
                    name="type panne"
                    type="text"
                    autoComplete="off"
                    onChange={handleSelectTypePane}
                  >
                    {" "}
                    <option selected disabled hidden>
                      select type de Panne
                    </option>
                    <option value="5">Changement du GPS</option>
                    <option value="4">Changement du SIM</option>
                    <option value="6">Reparation du Relais</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label>IMEI: </Form.Label>
                  <Form.Control
                    list="IMEI"
                    name="IMEI"
                    placeholder="IMEI"
                    autoComplete="off"
                    onChange={(e) => hanleFilterByDevice(e)}
                  />
                  <datalist id="IMEI">
                    {reparationTaskData?.allDeviceInstalled.map((oldDevice) => (
                      <option key={oldDevice.id} id={oldDevice.id}>
                        {oldDevice.serial_number}
                      </option>
                    ))}
                  </datalist>
                </Form.Group>
                {/* Ancienne Matricule */}

                <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label>Matricule : </Form.Label>
                  <Form.Control
                    name="Matricule"
                    autoComplete="off"
                    placeholder="Matricule"
                    onChange={(e) =>
                      setPanneTask((old) => {
                        return {
                          ...old,
                          Matricule: e.target.value,
                        };
                      })
                    }
                    value={panneTask ? panneTask.Matricule : ""}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} md="3" controlId="validationCustom04">
                  <Form.Label>Images : </Form.Label>
                  <Form.Control
                    required
                    name="imagesTask[]"
                    multiple
                    type="file"
                    accept="image/jpeg,image/png"
                    onChange={(e) => handleFileEvent(e, statu)}
                  />
                  {errorMessage && errorMessage.images ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.images[0]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <ShowImages />
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} md="9" controlId="validationCustom04">
                  <Form.Label>Observation : </Form.Label>
                  <Form.Control
                    style={{ height: "10rem" }}
                    as="textarea"
                    name="Observation"
                    required
                    type="file"
                    // value={installation.Observation}
                    onChange={(e) =>
                      setPanneTask((old) => {
                        return {
                          ...old,
                          Observation: e.target.value,
                        };
                      })
                    }
                  />
                  {errorMessage && errorMessage.Observation ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.Observation[0]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Row>
              <Row md="6" className="mt-4 justify-content-center">
                <Button
                  onClick={() => panneSubmit(id)}
                  variant="primary"
                  id="submitBtn"
                  name="submitBtn"
                >
                  Terminer Tâche
                </Button>
              </Row>
            </Form>
          </MDBContainer>
        </div>
      </div>
    </div>
  );
};
