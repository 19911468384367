import React, { useContext } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "../../../../context/axios";
import ComptableContext from "../../../../context/ComptableContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function GpsModel({ id, data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setErrors();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { getTypesgps } = useContext(ComptableContext);
  const [gpsModel, SetGpsMode] = useState({
    appareil_id: id,
    nameType: "",
  });
  const [errors, setErrors] = useState();
  const handleGpsModel = (e) => {
    const { name, value } = e.target;
    SetGpsMode({ ...gpsModel, [name]: value });
  };
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const addTypeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/comptable/addnewType", gpsModel);
      getTypesgps();
      handleClose();
      showSuccessMessage(response.data);
    } catch (e) {
      setErrors(e.response.data.errors);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "end", padding: "15px" }}>
        <Button variant="primary" onClick={handleShow}>
          Nouveau Type
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouveau type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={addTypeSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Nom Type: </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="nameType"
                  placeholder="Type"
                  autoComplete="off"
                  onChange={handleGpsModel}
                />
                <span style={{ color: "red" }}>{errors?.nameType[0]}</span>
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                Envoyer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
