import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import STcontext from "../../../context/STcontext";

const Accessoires = () => {
  const { getGerantAppareil, gerantAppareil } = useContext(STcontext);
  const [pages, setPages] = useState(1);
  const [rowsGpsPrePage, setGpsPrePage] = useState(5);
  const lastGpsIndex = pages * rowsGpsPrePage;
  const firstGpsIndex = lastGpsIndex - rowsGpsPrePage;
  const currentGpsPrePage = gerantAppareil.accessoryUserAssociate.slice(
    firstGpsIndex,
    lastGpsIndex
  );
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  useEffect(() => {
    getGerantAppareil();
  }, []);
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche par mot clé"
          />
        </span>
      </div>
    );
  };

  const formatDateBody = (rowDate) => {
    return formatDate(rowDate.created_at);
  };

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const header = renderHeader();
  let numberPageGpsPreUser = [];
  for (
    let i = 1;
    i <=
    Math.ceil(gerantAppareil.accessoryUserAssociate.length / rowsGpsPrePage);
    i++
  ) {
    numberPageGpsPreUser.push(i);
  }

  return (
    <div
      style={{
        width: "90%",
        padding: "2rem",
        marginTop: "1rem",
        marginLeft: "50px",
        borderRadius: "2rem",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "2rem",
        }}
      >
        <div className="bar-table" style={{ padding: "0rem 2rem 1rem 2rem " }}>
          <div>
            <h5>
              Accessoires &nbsp; <span class="vl"></span> &nbsp;{" "}
              <span style={{ fontSize: "15px" }}>
                {" "}
                {gerantAppareil.AllAccessory.length} Accessoires
              </span>{" "}
            </h5>
          </div>
        </div>{" "}
        <DataTable
          value={gerantAppareil.AllAccessory}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          dataKey="id"
          filters={filters}
          globalFilterFields={[
            "serial_number",
            "nameModele",
            "nameType",
            "status",
          ]}
          header={header}
          emptyMessage="Aucun appareil trouvé."
        >
          <Column
            header="#"
            headerStyle={{ width: "3rem" }}
            body={(data, options) => options.rowIndex + 1}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            header="Acessoires ID"
            field="serial_number"
            style={{ minWidth: "12rem", height: "4rem" }}
          />
          <Column
            header="Modéle"
            field="nameModele"
            showFilterMenu={false}
            style={{ minWidth: "14rem", height: "4rem" }}
          />
          <Column
            field="nameType"
            header="Type"
            filterPlaceholder="Search by name"
            style={{ minWidth: "12rem", height: "4rem" }}
          />
          <Column
            field="verified"
            header="Date D'entrée"
            style={{ minWidth: "6rem", height: "4rem" }}
            body={formatDateBody}
          />
        </DataTable>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            backgroundColor: "white",
            width: "80%",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col">Utilisateur ID</th>
                <th scope="col">Quantité </th>
              </tr>
            </MDBTableHead>
            {currentGpsPrePage.map((Accessory) => (
              <MDBTableBody>
                <tr>
                  <td>
                    <p className="fw-normal mb-1">{Accessory.name}</p>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">{Accessory.numberArticle}</p>
                  </td>
                  <td>{Accessory.nameType}</td>
                </tr>
              </MDBTableBody>
            ))}
          </MDBTable>
          {gerantAppareil.gpsUserAssociate.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberPageGpsPreUser.map((numberPage, index) => (
                  <MDBPaginationItem
                    key={index}
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPages(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Accessoires;
