import React, { useContext } from "react";
import TechnicianContext from "../context/TechnicienContext";

export const ShowImages = () => {
  const { showImages } = useContext(TechnicianContext);
  return (
    <div
      style={{
        display: "flex",
        width: "135px",
        gap: "10px",
        marginTop: "5px",
      }}
    >
      {showImages?.length > 0
        ? showImages.map((showImage, index) => (
            <img
              key={index}
              src={URL.createObjectURL(showImage)}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          ))
        : ""}
    </div>
  );
};
