import React, { useContext, useEffect, useState } from "react";
import UpdateClientInfo from "./UpdateClientInfo";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ComptableContext from "../../context/ComptableContext";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TuneIcon from "@mui/icons-material/Tune";
import { InputText } from "primereact/inputtext";
import { AddNewCustomer } from "./AddNewCustomer";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import pusher from "../../../pusher";
export const AllClients = () => {
  const {
    getAllClients,
    allClients,
    filterByDate,
    handleSearchTechnicianTaskPerDate,
    handleFilterClientsByDateClick,
    clearFilterClientByDate,
    getAllCities,
  } = useContext(ComptableContext);

  useEffect(() => {
    getAllClients();
    getAllCities();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAllClients();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const editClient = (rowDate) => {
    return (
      <div>
        <UpdateClientInfo data={rowDate} />
      </div>
    );
  };

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nomComplet: { value: null },
    telephone_client: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email_client: { value: null, matchMode: FilterMatchMode.IN },
    created_at: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();
  return (
    <>
      <div className="bar-table">
        <div className="left-bar-table">
          <AddNewCustomer />
        </div>
        <div className="right-bar-table">
          <div className="Filter">
            <div style={{ display: "flex", gap: "10px" }}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={filterByDate.startDate}
                    onChange={handleSearchTechnicianTaskPerDate}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={filterByDate.endDate}
                    onChange={handleSearchTechnicianTaskPerDate}
                    required
                  />
                </Form.Group>
              </Row>

              <div className="filter-button-task-technicien">
                <Button onClick={handleFilterClientsByDateClick}>
                  {" "}
                  <TuneIcon />
                  Filtrer
                </Button>
                <Button variant="danger" onClick={clearFilterClientByDate}>
                  {" "}
                  <ClearIcon />
                  Annule
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tâches-tech">
        <div className="Table-tech">
          <DataTable
            value={allClients}
            dataKey="id"
            paginator
            sortMode="multiple"
            filters={filters}
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            tableStyle={{ minWidth: "50rem" }}
            globalFilterFields={[
              "nomComplet",
              "telephone_client",
              "email_client",
              "created_at",
            ]}
            header={header}
            emptyMessage="No customers found."
          >
            <Column
              field="nomComplet"
              header="Nom Client"
              sortable
              style={{ width: "25%" }}
            ></Column>
            <Column
              field="telephone_client"
              header="Telephone"
              sortable
              style={{ width: "25%" }}
            ></Column>
            <Column
              field="email_client"
              header="Email"
              sortable
              style={{ width: "25%" }}
            ></Column>
            <Column
              field="created_at"
              header="Date création"
              sortable
              style={{ width: "25%" }}
            ></Column>
            <Column header="Action" body={editClient}></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
};
