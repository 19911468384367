import React, { useContext, useEffect, useState } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechnicianContext from "../context/TechnicienContext";
import { useParams } from "react-router-dom";
import { ShowImages } from "./ShowImages";

export default function Réinstallation() {
  //Again import from technician contet all functions and states related to reinstallation
  const {
    handleFileEvent,
    reinstallation,
    handleAddReinstalle,
    reinstallationSubmit,
    setReinstallation,
    getTasksDataForRelocation,
    appareil,
    relocationTaskData,
    clientInfoTask,
    errorMessage,
    setErrorMessage,

  } = useContext(TechnicianContext);
  //usePArams hook to grab the id of a specific task so that we can sent a request to get that task from the backend
  const { id, statu } = useParams();

  //execute the function when the component first mount by its id
  useEffect(() => {
    getTasksDataForRelocation(id);
    setErrorMessage()
  }, []);
  //useEffect hook to set the property "etatTache" of reinstallation state with the value 2 which is related to a terminated task state i

  const [vehiculeInfo, setVehiculeInfo] = useState(null);

  const hanleFilterByDevice = async (e) => {
    const getIdVehicule = await relocationTaskData?.allDeviceInstalled.filter(
      (idVehicule) => idVehicule.serial_number === e.target.value
    );
    if (getIdVehicule.length > 0) {
      setVehiculeInfo(...getIdVehicule);
    } else {
      setVehiculeInfo(null);
    }
  };
  useEffect(() => {
    if (vehiculeInfo != null) {
      const vehicule = relocationTaskData.vehicules.filter(
        (articleInfo) => articleInfo.id === vehiculeInfo.vehicule_id
      )[0];
      setReinstallation({
        ...reinstallation,
        statut: vehiculeInfo ? vehiculeInfo.statut : null,
        NSIM: vehiculeInfo
          ? vehiculeInfo.latest_sim_relating.map(
              (simCombination) => simCombination.serial_number
            )[0]
          : "",
        Matricule: vehicule ? vehicule.matricule : "",
        typeRelay:
          vehicule && vehicule.articles
            ? vehicule.articles.map((relay) => relay.modele_id)[0]
            : "",
        IMEI: vehiculeInfo ? vehiculeInfo.serial_number : "",
      });
    } else {
      setReinstallation(null);
    }
  }, [vehiculeInfo]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "1rem",
        }}
      >
        <MDBContainer>
          <MDBCardHeader>
            <h5> Réinstallation</h5>
          </MDBCardHeader>
          <hr></hr>

          <Form noValidate>
            <Row className="mb-4">
              {/* Nom client */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Nom Client: </Form.Label>
                <Form.Control
                  required
                  disabled
                  type="text"
                  placeholder="Nom client"
                  value={clientInfoTask.client_name}
                />
              </Form.Group>
              {/* IMEI */}

              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>IMEI: </Form.Label>
                <Form.Control
                  list="IMEI"
                  name="IMEI"
                  placeholder="IMEI"
                  autoComplete="off"
                  onChange={(e) => hanleFilterByDevice(e)}
                />
                <datalist id="IMEI">
                  {relocationTaskData?.allDeviceInstalled.map((oldDevice) => (
                    <option key={oldDevice.id} id={oldDevice.id}>
                      {oldDevice.serial_number}
                    </option>
                  ))}
                </datalist>
              </Form.Group>
              {/* Ancienne Matricule */}

              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Ancien Matricule : </Form.Label>
                <Form.Control
                  list="ancienMatricules"
                  name="AncienMatricule"
                  placeholder="ancien matricule"
                  autoComplete="off"
                  value={reinstallation ? reinstallation.Matricule : ""}
                />
              </Form.Group>
              {/* SIM */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Numéro carte SIM: </Form.Label>
                <Form.Control
                  list="NSIM"
                  name="NSIM"
                  placeholder="sim number"
                  autoComplete="off"
                  onChange={handleAddReinstalle}
                  value={reinstallation ? reinstallation.NSIM : ""}
                />
                <datalist id="NSIM">
                  {appareil.SIM.map((sim, index) => (
                    <option value={sim.serial_number} key={index}>
                      {sim.latest_device_relating.length > 0
                        ? sim.latest_device_relating.map(
                            (deviceRelating) => deviceRelating.serial_number
                          )[0]
                        : ""}
                    </option>
                  ))}
                </datalist>
              </Form.Group>
            </Row>

            <Row className="mb-4">
              {/* Nouvelle  Matricule */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Nouvelle Matricule: </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="NouvelleMatricule"
                  placeholder="Matricule"
                  autoComplete="off"
                  onChange={handleAddReinstalle}
                />
                   {errorMessage && errorMessage.NouvelleMatricule ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.NouvelleMatricule[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>

              {/* Principale/secondaire */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Gps principale / secondaire:</Form.Label>
                <Form.Select
                  name="statut"
                  onChange={handleAddReinstalle}
                  aria-label="Default select example"
                >
                  <option selected disabled hidden>
                    {reinstallation && reinstallation.statut === 0
                      ? "principale(1er)"
                      : reinstallation && reinstallation.statut === 1
                      ? "secondaire(2eme)"
                      : "select position"}
                  </option>
                  <option value="0">principale(1er)</option>
                  <option value="1">secondaire(2eme)</option>
                </Form.Select>
                {errorMessage && errorMessage.statut ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.statut[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>
              {/* type Relais */}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>normal / spécial:</Form.Label>
                <Form.Select
                  name="typeRelay"
                  onChange={handleAddReinstalle}
                  aria-label="Default select example"
                >
                  <option selected disabled hidden>
                    {reinstallation && reinstallation.typeRelay === 22
                      ? "Normal"
                      : reinstallation && reinstallation.typeRelay === 21
                      ? "Spéciale"
                      : "select type relais"}
                  </option>
                  <option disabled>Type Relais</option>
                  <option value="normal">normal</option>
                  <option value="spécial">spécial</option>
                </Form.Select>
                {errorMessage && errorMessage.statut ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.statut[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>
            </Row>

            <Row>
              {/*images*/}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Images : </Form.Label>
                <Form.Control
                  required
                  name="images"
                  multiple
                  type="file"
                  accept="application/pdf,image/jpeg,image/png"
                  onChange={(e) => handleFileEvent(e, statu)}
                />
                {errorMessage && errorMessage.images ? (
                    <span style={{ color: "red" }}>
                      {errorMessage.images[0]}
                    </span>
                  ) : (
                    ""
                  )}
              </Form.Group>
              <ShowImages />
            </Row>
            <Row className="mb-4">
              {/* Observation */}

              <Form.Group as={Col} md="9" controlId="validationCustom04">
                <Form.Label>Observation:</Form.Label>
                <Form.Control
                  style={{ height: "10rem" }}
                  as="textarea"
                  required
                  type="file"
                  name="Observation"
                  onChange={handleAddReinstalle}
                />
              </Form.Group>
            </Row>
            <Row md="6" className="mt-4 justify-content-center">
              <Button
                onClick={() => reinstallationSubmit(id)}
                variant="primary"
              >
                Terminer Tâche
              </Button>
            </Row>
          </Form>
        </MDBContainer>
      </div>
    </div>
  );
}
