import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import STcontext from "../context/STcontext";
import axios from "../context/axios";

export default function DetailsTaskST({ taskSTToUpdate }) {
  const { getTaskInfo, NewTask } = useContext(STcontext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navTo = useNavigate();
  const values = [true];

  const [TaskToUpdateFromST, setTaskToUpdateFromST] = useState({});
  const [newDataTask, setNewDataTask] = useState([]);
  const [fullscreen, setFullscreen] = useState(true);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
    setTaskToUpdateFromST(taskSTToUpdate);
  }

  const handelUpdateTaskFromST = (e) => {
    const { name, value } = e.target;
    setTaskToUpdateFromST({ ...TaskToUpdateFromST, [name]: value });
    setNewDataTask({ ...newDataTask, [name]: value });
  };

  const updateTaskSubmit = async (e, id) => {
    e.preventDefault();
    try {
      const updateTaskSubmit = await axios.put(
        `api/supportTechnique/updateTask/${id}`,
        newDataTask
      );
      if (updateTaskSubmit) {
        handleClose();
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log("error updating");
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={() => handleShow(values)}>
        Plus Détails
      </Button>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Détails tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ gap: "20px", marginBottom: "10px", overflowY: "scroll" }}
        >
          <Form
            noValidate
            method="POST"
            onSubmit={(e) => updateTaskSubmit(e, TaskToUpdateFromST.tache_id)}
          >
            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Nom client: </Form.Label>
                <Form.Control
                  required
                  list="listClient"
                  type="text"
                  name="client_name"
                  autoComplete="off"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.client_name}
                />
                <datalist id="listClient">
                  {NewTask.Clients.map((client) => (
                    <option key={client.id}>{client.nomComplet}</option>
                  ))}
                </datalist>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Téléphone:</Form.Label>
                <Form.Control
                  type="text"
                  name="clientPhoneNumber"
                  c
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.telephone_client}
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Type du tâche : </Form.Label>
                <Form.Control
                  list="typeTasks"
                  type="text"
                  name="catache"
                  autoComplete="off"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.catache}
                />
                <datalist id="typeTasks">
                  {NewTask.TypesTasks.map((typeTask) => (
                    <option key={typeTask.id}>{typeTask.catache}</option>
                  ))}
                </datalist>
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>matricule: </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="matricule"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.matricule}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Modele Gps : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="nameModeleGPS"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.nameModeleGPS}
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Prop App : </Form.Label>
                <Form.Select
                  list="proprietaireList"
                  required
                  type="text"
                  name="proprietaire"
                  onChange={handelUpdateTaskFromST}
                >
                  <option value="none" selected disabled hidden>
                    {TaskToUpdateFromST.proprietaire == 0
                      ? "interne"
                      : "client"}
                  </option>
                  <option disabled>proprietaire</option>
                  <option value={0}>interne</option>
                  <option value={1}>client</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Type d'installation d'app : </Form.Label>
                <Form.Select
                  type="text"
                  placeholder=""
                  name="gps_principale"
                  onChange={handelUpdateTaskFromST}
                >
                  <option value="none" selected disabled hidden>
                    {TaskToUpdateFromST.proprietaire == 0
                      ? "principale"
                      : "secondaire"}
                  </option>
                  <option disabled>proprietaire</option>
                  <option value={0}>principale</option>
                  <option value={1}>secondaire</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Modele SIM : </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="nameModeleSIM"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.nameModeleSIM}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>SIM : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="SIM"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.SIM}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Prop SIM : </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="SIM_proprietaire"
                  onChange={handelUpdateTaskFromST}
                  value={
                    TaskToUpdateFromST.SIM_proprietaire == 1
                      ? "interne"
                      : "client"
                  }
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Type Relais : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="accesoriesModel"
                  onChange={handelUpdateTaskFromST}
                  value={
                    TaskToUpdateFromST.accesoriesModel
                      ? TaskToUpdateFromST.accesoriesModel
                      : " pas d'accesoires"
                  }
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Garantie : </Form.Label>
                <Form.Check
                  type="checkbox"
                  label="sans"
                  name="garantie"
                  onChange={handelUpdateTaskFromST}
                  checked={TaskToUpdateFromST.garantie == 0 ? true : false}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Exécutée par : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="user_name"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.user_name}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Date previsionnel debut : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="date_previsionnelle_debut"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.date_previsionnelle_debut}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Date fin realisation : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="date_fin_realisation"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.date_fin_realisation}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="observation"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.observation}
                />
              </Form.Group>
            </Row>
            <Button type="submit" variant="primary">
              Envoyer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
