import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import STcontext from "../../../context/STcontext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import pusher from "../../../../pusher";

const Accessoires = () => {
  const Navigate = useNavigate();
  const { appareil, getAppareil } = useContext(STcontext);
  const {
    technicien,
    technicianAll,
    handleAffectedTechnicianOption,
    SubmittedToTechnician,
    handleAffectedArticleOption,
  } = useContext(STcontext);

  useEffect(() => {
    getAppareil();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAppareil();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);
  const [selectedAppareils, setSelectedAppareils] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    serial_number: { value: null },
    nameModele: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    nameType: { value: null, matchMode: FilterMatchMode.IN },
    numberBox: { value: null, matchMode: FilterMatchMode.IN },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    if (selectedAppareils != null) {
      const appareilsId = selectedAppareils.map((appareil) => appareil.id);
      handleAffectedArticleOption(appareilsId);
    }
  }, [selectedAppareils]);
  useEffect(() => {
    setSelectedAppareils(null);
  }, [appareil.AllAccessory]);

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  // table appareil pre user

  const [pages, setPagesAppareilPreUser] = useState(1);
  const [rowsPrePage, setRowsPrePage] = useState(5);
  const lastAppareilIndex = pages * rowsPrePage;
  const firstAppareilIndex = lastAppareilIndex - rowsPrePage;
  const currentAppareilPreUser = appareil.accessoryUserAssociate.slice(
    firstAppareilIndex,
    lastAppareilIndex
  );

  let numberPagesAppareilPreUser = [];
  for (
    let p = 1;
    p <= Math.ceil(appareil.accessoryUserAssociate.length / rowsPrePage);
    p++
  ) {
    numberPagesAppareilPreUser.push(p);
  }

  const header = renderHeader();

  return (
    <div
      style={{
        width: "90%",
        padding: "2rem",
        marginTop: "1rem",
        marginLeft: "50px",
        borderRadius: "2rem",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "2rem",
        }}
      >
        <div className="bar-table" style={{ padding: "0rem 2rem 1rem 2rem " }}>
          <div>
            <h5>
              Accessoires &nbsp; <span class="vl"></span> &nbsp;{" "}
              <span style={{ fontSize: "15px" }}>
                {" "}
                {appareil.AllAccessory.length} Accessoires
              </span>{" "}
            </h5>
          </div>
        </div>

        <DataTable
          value={appareil.AllAccessory}
          selectionMode={rowClick ? null : "checkbox"}
          selection={selectedAppareils}
          onSelectionChange={(e) => setSelectedAppareils(e.value)}
          dataKey="id"
          header={header}
          paginator
          sortMode="multiple"
          filters={filters}
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          globalFilterFields={[
            "serial_number",
            "nameModele",
            "nameType",
            "numberBox",
          ]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            header="#"
            headerStyle={{ width: "3rem" }}
            body={(data, options) => options.rowIndex + 1}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column
            bodyStyle={{ padding: "32px 1rem" }}
            field="serial_number"
            header="IMEI"
          ></Column>
          <Column field="nameModele" header="Model"></Column>
          <Column field="nameType" header="Marque"></Column>
        </DataTable>
        <div
          style={{
            display: "flex",
            width: "40%",
            gap: "20px",
            padding: "2rem",
          }}
        >
          <Form.Select
            aria-label="Default select example"
            name="technicien"
            onChange={handleAffectedTechnicianOption}
          >
            <option selected disabled hidden>
              Veuillez choisir un technicien
            </option>
            <option disabled>Veuillez choisir un technicien</option>
            {technicien.map((tech) => (
              <option key={tech.id} value={tech.id}>
                {tech.name}
              </option>
            ))}
          </Form.Select>
          <Button
            type="submit"
            variant="primary"
            onClick={(e) => SubmittedToTechnician(e, selectedAppareils)}
          >
            Envoyer
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            backgroundColor: "white",
            width: "80%",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Utilisateur</th>
                <th scope="col">Quantité</th>
                <th scope="col">Appareils</th>
              </tr>
            </MDBTableHead>
            {currentAppareilPreUser.map((appareilPreUser) => (
              <MDBTableBody>
                <tr>
                  <td>
                    <div className="d-flex align-items-center"></div>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">{appareilPreUser.name}</p>
                  </td>

                  <td>
                    <p className="fw-normal mb-1">
                      {appareilPreUser.numberArticle}
                    </p>
                  </td>
                  <td>
                    <Button
                      className="showAppareilPreUser"
                      variant="primary"
                      onClick={() =>
                        Navigate(
                          `/Support-technique/appareils-pour-l'utilisateur/${appareilPreUser.id}/accessorie`
                        )
                      }
                    >
                      voir les appareils
                    </Button>{" "}
                  </td>
                </tr>
              </MDBTableBody>
            ))}
          </MDBTable>
          {appareil.AllGPS.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberPagesAppareilPreUser.map((numberPage, index) => (
                  <MDBPaginationItem
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPagesAppareilPreUser(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Accessoires;
