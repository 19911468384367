import React, { useState } from "react";
import "../../../App.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import SIM from "./SGA/Sim";
import Gps from "./SGA/Gps";
import Accessoires from "./SGA/Accessoires";
import { useContext } from "react";
import ComptableContext from "../../context/ComptableContext";
import { useEffect } from "react";
import pusher from "../../../pusher";

export default function AppCards() {
  useEffect(() => {
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAllAppareilsForComtaple();
    });
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);
  const { getAllAppareilsForComtaple, AllApparielForComtple } =
    useContext(ComptableContext);

  useEffect(() => {
    getAllAppareilsForComtaple();
  }, []);
  const [table, setTable] = useState();
  const handleTable = (e) => {
    const { id } = e.target;
    if (id == 1) {
      setTable(<Gps />);
    } else if (id == 2) {
      setTable(<SIM />);
    } else if (id == 3) {
      setTable(<Accessoires />);
    }
  };
  return (
    <>
      <MDBRow className="AppCard ">
        <MDBCol>
          <MDBCard className="cardStats pointer ">
            <MDBCardBody id="1" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>Gps </MDBCardTitle>
              <span className="card-text">
                {AllApparielForComtple.NumberGps}
              </span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard className="cardStats pointer ">
            <MDBCardBody id="2" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>SIM</MDBCardTitle>
              <span className="card-text">
                {AllApparielForComtple.NumberSIM}
              </span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard className="cardStats pointer ">
            <MDBCardBody id="3" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>Accessoires</MDBCardTitle>
              <span className="card-text">
                {AllApparielForComtple.NumberAccessory}
              </span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <div>{table}</div>
    </>
  );
}
