import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import TechnicianContext from "../../../context/TechnicienContext";
import pusher from "../../../../pusher";

const Accessoires = () => {
  const { appareil, getAppareil } = useContext(TechnicianContext);
  useEffect(() => {
    getAppareil();
    setAccessoires(appareil.Accessory);
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAppareil();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);
  const [accessoires, setAccessoires] = useState([]);
  const [pages, setPages] = useState(1);
  const [rowsPrePage, setRowsPrepage] = useState(10);
  const lastAccessoryndex = pages * rowsPrePage;
  const firstAccessoryndex = lastAccessoryndex - rowsPrePage;
  const curranAccessoryPrePages = accessoires.slice(
    firstAccessoryndex,
    lastAccessoryndex
  );

  let numberOfPages = [];
  for (let p = 1; p <= Math.ceil(accessoires.length / rowsPrePage); p++) {
    numberOfPages.push(p);
  }

  return (
    <div>
      <div className="tablesApp">
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <div
            className="bar-table"
            style={{ padding: "0rem 2rem 1rem 2rem " }}
          >
            <div>
              <h5>
                Accessoires &nbsp; <span class="vl"></span> &nbsp;{" "}
                <span style={{ fontSize: "15px" }}>
                  {" "}
                  {accessoires.length} Accessoires
                </span>{" "}
              </h5>
            </div>
          </div>
          <div class="search-appareil-technicien input-group rounded">
            <input
              type="search"
              class="form-control rounded Search-input"
              placeholder="Search Acessoires ID, Modèle, Marque "
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </div>
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Acessoires ID</th>
                <th scope="col">Modèle</th>
                <th scope="col">Marque</th>
              </tr>
            </MDBTableHead>
            {curranAccessoryPrePages
              ? curranAccessoryPrePages.map((Accessory, index) => (
                  <MDBTableBody>
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center"></div>
                      </td>

                      <td>
                        <p className="fw-normal mb-1">
                          {Accessory.serial_number}
                        </p>
                      </td>
                      <td>
                        <p className="fw-normal mb-1">{Accessory.nameModele}</p>
                      </td>
                      <td>{Accessory.nameType}</td>
                    </tr>
                  </MDBTableBody>
                ))
              : " Aucun résultat trouvé"}
          </MDBTable>
          {accessoires.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberOfPages.map((numberPage, index) => (
                  <MDBPaginationItem
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPages(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}
          {/* <nav className="d-flex justify-content-end">
            <ul className="pagination">
              {pages.map((page) => (
                <div
                  className={`${
                    page === currentPage ? "page-item active" : "page-item"
                  }`}
                >
                  <p className="page-link" onClick={() => pagination(page)}>
                    {page}
                  </p>
                </div>
              ))}
            </ul>
          </nav> */}
        </div>
      </div>
    </div>
  );
};

export default Accessoires;
