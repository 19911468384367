import React, { useContext } from 'react'
import {
    MDBCardHeader,
    MDBContainer
  } from "mdb-react-ui-kit";
  import Button from "react-bootstrap/Button";
  import Form from "react-bootstrap/Form";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
import TechnicianContext from '../context/TechnicienContext';
import { useParams } from 'react-router-dom';
export default function Annulée() {
  //technician context  importing multipe states and functions to be used within this component
  const{clientInfoTask,handleAddAnnule,
    annule,annuleSubmit}=useContext(TechnicianContext)
      //useParams hook to grab the id of each Task and use it inside the function getTask within the useEffect hook
  const{id}=useParams()

  return (
    <div>

  <div style={{ backgroundColor:'white' , padding:'2rem' , marginTop:'1rem', borderRadius:'1rem'  }}>
    
<MDBContainer>

    <MDBCardHeader>
          <h5>Tâches annulée</h5>
    </MDBCardHeader> 
                <hr></hr>

        <Form noValidate>
            <Row className="mb-4">

            {/* Nom client */}

                <Form.Group as={Col} md="4" controlId="validationCustom04">
                        <Form.Label>Nom Client : </Form.Label>
                              <Form.Control
                                        disabled
                                        required
                                        name="nomComplet"
                                        type="text"
                                        placeholder="Nom client"
                                        value={clientInfoTask.client_name}

                                />
                </Form.Group>

                {/* Observation */}
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                        <Form.Label>Observation : </Form.Label>
                        <Form.Control
                                    style={{ height: '10rem'}}
                                    as= "textarea"
                                    required
                                    name="Observation"
                                    type="file"
                                    value={annule.Observation}
                                    onChange={handleAddAnnule}
                                />
                </Form.Group>

            </Row>
                <Row md="6" className='mt-4 justify-content-center' >
                <Button onClick={()=>annuleSubmit(id)} variant="primary" >
            Terminer Tâche 
          </Button>
                </Row>           
          </Form>
    </MDBContainer>
    
    </div>


    </div>
  )
}
