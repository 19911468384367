import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useContext } from "react";
import STcontext from "../context/STcontext";
import TechnicianContext from "../context/TechnicienContext";
import BorderColorIcon from "@mui/icons-material/BorderColor";

export default function TaskExceptionnel({ data }) {
  //get the Task Info and NewTAsk from the support Technique context
  const { NewTask } = useContext(STcontext);
  //get the datas stats and other functions to be used with the task excep component
  const { taskTechnicien, appareil, submitUpdateTask } =
    useContext(TechnicianContext);

  //states related to the modals and its logic
  const [show, setShow] = useState(false);
  const [showImages, setShowImages] = useState([]);
  const [dataToUpdate, setDataToUpdate] = useState({});
  const handleShow = () => {
    setDataToUpdate(data);
    setShow(true);
  };
  const [endroit, setEndroit] = useState([]);
  const statu = "updateTask";
  const [updateTask, setUpdateTask] = useState({});
  const [simCombination, setSimCombination] = useState({
    SIM: data ? data.SIM : "",
  });

  const handleUpdateTask = (e, GPS) => {
    const name = e.target.name;
    const value = e.target.value;
    setDataToUpdate({ ...dataToUpdate, [name]: value });
    setUpdateTask({ ...updateTask, [name]: value });
    setSimCombination({ ...simCombination, [name]: value });
    const serial_number = e.target.value;
    const filterSimByIMEI = GPS.filter(
      (simCombimation) => simCombimation.serial_number === serial_number
    );
    setSimCombination({
      ...simCombination,
      SIM: filterSimByIMEI.map((test) => test.serial_numberCombination),
    });
  };

  const handleFileEvent = async (e) => {
    setShowImages(Array.from(e.target.files));
    const filesImages = e.target.files;
    const base64Images = [];
    for (const image of filesImages) {
      const readerFile = new FileReader();
      readerFile.readAsDataURL(image);
      readerFile.onload = () => {
        base64Images.push({
          data: readerFile.result.split(",")[1], // Extract the base64 image data
          extension: image.type.split("/")[1], // Extract the image extension
        });
      };
    }
    await Promise.all(base64Images);
    setUpdateTask({ ...updateTask, imagesTask: base64Images });
  };

  const handleClose = () => {
    setShowImages([]);
    setShow(false);
  };
  useEffect(() => {
    handleClose();
  }, [taskTechnicien]);
  return (
    <div>
      <Button
        className="nouveauProfilButton"
        style={{ width: "100%", border: "none" }}
        onClick={handleShow}
      >
        <BorderColorIcon />
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            method="POST"
            onSubmit={(e) => submitUpdateTask(e, updateTask, dataToUpdate.id)}
          >
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Nom complet : </Form.Label>
                <Form.Control
                  name="nomComplet"
                  autoComplete="off"
                  placeholder="Nom complet"
                  onChange={handleUpdateTask}
                  value={dataToUpdate.client_name}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Matricule : </Form.Label>
                <Form.Control
                  name="matricule"
                  autoComplete="off"
                  placeholder="Matricule"
                  onChange={handleUpdateTask}
                  value={dataToUpdate.matricule}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Type de la tâche : </Form.Label>
                <Form.Select
                  name="typeTache"
                  placeholder="Type de la tâche "
                  autoComplete="off"
                  onChange={handleUpdateTask}
                  defaultValue={dataToUpdate.catache}
                >
                  <option value={dataToUpdate.catache} selected hidden>
                    {dataToUpdate.catache}
                  </option>
                  <option disabled>Select type tâche </option>

                  {NewTask.TypesTasks.map((TypeTask) => (
                    <option key={TypeTask.id} value={TypeTask.id}>
                      {TypeTask.catache}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Type de l'appareil : </Form.Label>
                <Form.Control
                  list="dataAppareil"
                  name="TypeAppareil"
                  placeholder="Type de l'appareil"
                  autoComplete="off"
                  onChange={handleUpdateTask}
                  defaultValue={dataToUpdate.nameModeleGPS}
                />
                <datalist id="dataAppareil">
                  {NewTask.TypesDevice.map((TypeDevice, index) => (
                    <option key={index}>{TypeDevice.nameType}</option>
                  ))}
                </datalist>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>IMEI : </Form.Label>
                <Form.Control
                  list="serial_numbers"
                  name="IMEI"
                  placeholder="IMEI"
                  autoComplete="off"
                  onChange={(e) => handleUpdateTask(e, appareil.GPS)}
                  value={dataToUpdate.IMEI}
                />
                <datalist id="serial_numbers">
                  {appareil.GPS.map((IMEI, index) => (
                    <option key={index}>{IMEI.serial_number}</option>
                  ))}
                </datalist>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>N° SIM : </Form.Label>
                <Form.Control
                  list="dataSIM"
                  name="SIM"
                  placeholder="Numéro de SIM"
                  autoComplete="off"
                  onChange={handleUpdateTask}
                  value={simCombination.SIM}
                />
                <datalist id="dataSIM">
                  {appareil.SIM.map((SIM, index) => (
                    <option key={index}>{SIM.serial_number}</option>
                  ))}
                </datalist>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              {/* Principale/secondaire */}
              <Form.Group as={Col} md="4" controlId="validationCustom04">
                <Form.Label>Gps principale / secondaire:</Form.Label>
                <Form.Select name="statut" onChange={handleUpdateTask}>
                  <option selected disabled hidden>
                    {dataToUpdate && dataToUpdate.statut === 0
                      ? "principale(1er)"
                      : dataToUpdate && dataToUpdate.statut === 1
                      ? "secondaire(2eme)"
                      : "select position"}
                  </option>
                  <option value="0">principale(1er)</option>
                  <option value="1">secondaire(2eme)</option>
                </Form.Select>
              </Form.Group>
              {/* type Relais */}
              <Form.Group as={Col} md="4" controlId="validationCustom04">
                <Form.Label>normal / spécial:</Form.Label>
                <Form.Select name="typeRelais" onChange={handleUpdateTask}>
                  <option
                    defaultValue={dataToUpdate.accesoriesModel}
                    selected
                    disabled
                    hidden
                  >
                    {dataToUpdate.accesoriesModel || "type relay"}
                  </option>
                  <option disabled>Type Relais</option>
                  <option value="normal">normal</option>
                  <option value="spécial">spécial</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Date : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="Date"
                  onChange={handleUpdateTask}
                  value={dataToUpdate.date_previsionnelle_debut}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom04">
                <Form.Label>Réalisée à : </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="madeAt"
                  onChange={handleUpdateTask}
                >
                  <option selected hidden>
                    {dataToUpdate.cityName || "select ville"}
                  </option>
                  <option disabled>Select ville</option>
                  {NewTask.Cities.map((city, index) => (
                    <option value={city.id} key={index}>
                      {city.cityName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Images : </Form.Label>
                <Form.Control
                  required
                  name="imagesTask[]"
                  multiple
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(e) => handleFileEvent(e, statu)}
                />
              </Form.Group>
              <div
                style={{
                  display: "flex",
                  width: "135px",
                  gap: "10px",
                  marginTop: "5px",
                }}
              >
                {showImages.length > 0
                  ? showImages.map((showImage, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(showImage)}
                        alt=""
                        style={{ width: "100%", height: "auto" }}
                      />
                    ))
                  : ""}
              </div>
            </Row>
            <Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  placeholder="description"
                  name="Description"
                  value={dataToUpdate.observation}
                  as="textarea"
                  rows={3}
                  onChange={handleUpdateTask}
                />
              </Form.Group>
            </Row>

            <Modal.Footer>
              <Button type="submit" variant="primary">
                Modifier la tâche
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
