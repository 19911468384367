import React, { createContext, useRef, useState } from "react";
import axios from "./axios";
import _ from "lodash";
import { toast } from "react-toastify";

const CommercialContext = createContext();

export const CommercialProvider = ({ children }) => {
  const [clients, setClients] = useState({
    Client: [],
  });
  const [addClient, setaddClient] = useState({
    Quantite: "1",
  });
  const [error, setError] = useState(null);

  const [task, setTask] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [show, setShow] = useState(false);
  const [paginaed, setPaginated] = useState([]);
  const [paginaedTaskToConfirm, setPaginatedTaskToConfirm] = useState([]);

  const pageSize = 10;

  //error messege Function
  const showErrorMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // Success Message toast
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  // get Client function

  const getClient = async () => {
    await axios.get("/api/clients").then((res) => {
      setAllClients(res.data);
    });
  };

  const getTasksCommerciale = async () => {
    const tasksCommerciale = await axios.get("/api/allCommercialeTasks");
    setTask(tasksCommerciale.data);
  };

  const [tasksPreClient, setTasksPreClient] = useState();
  const getTasksForEachClient = async (idClient) => {
    setTasksPreClient(null);
    const tasksclient = await axios.get(
      `/api/getTasksForEachClient/${idClient}`
    );
    setTasksPreClient(tasksclient.data);
  };

  const [taskToConfirmed, setTaskToConfirmed] = useState([]);
  const getTasktoConfirmed = async () => {
    await axios.get("/api/gerant/tâchesaconfirmer").then((res) => {
      setTask(res.data);
      setPaginatedTaskToConfirm(_(res.data).slice(0).take(pageSize).value());
    });
  };

  const [showDeletePop, setShowDeletePop] = useState(false);

  const deleteTask = async (id) => {
    try {
      await axios.delete(`/api/gerant/deleteTask/${id}`);
      showSuccessMessage("tâche supprimer  avec succès");

      getTasktoConfirmed();
      setShowDeletePop(false);
    } catch (err) {
      showErrorMessage("impossible de supprimer cette tâche");
    }
  };

  const deleteTaskPreClient = async (id, clientId) => {
    try {
      await axios.delete(`/api/gerant/deleteTask/${id}`);
      getTasksForEachClient(clientId);
      setShowDeletePop(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleAddClient = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setaddClient({ ...addClient, [name]: value });
  };

  //filter Client Info
  const [filterClientInfo, setFilterClientInfo] = useState({});

  const handleFilterClientInfo = (e, Clients) => {
    const name = e.target.name;
    const value = e.target.value;
    const getphoneNumber = Clients.filter(
      (phoneNumber) => phoneNumber.nomComplet === value
    );
    if (getphoneNumber.length > 0) {
      setFilterClientInfo(...getphoneNumber);
      setClientInfo(...getphoneNumber);
    } else {
      setClientInfo({
        [name]: value,
      });
      setFilterClientInfo({});
    }
  };
  const setClientInfo = (clientInfo) => {
    setaddClient({
      ...addClient,
      nomComplet: clientInfo.nomComplet,
      telephone: clientInfo.telephone_client,
      emailClient: clientInfo.email_client,
      clientCity: clientInfo.city ? clientInfo.city.id : null,
    });
  };

  // end filter client info

  const submitAddClient = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/clients", addClient);
      getTasksCommerciale();
      setShow(false);
      setaddClient({ Quantite: "1" });
      getClient();
    } catch (e) {
      console.error(e);
    }
  };
  const submitTaskToConfirmed = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/clients", addClient);
      getTasktoConfirmed();
      setShow(false);
      showSuccessMessage("tâche ajouter avec succès");
      setaddClient({ Quantite: "1" });
      getClient();
      setError(null);
    } catch (error) {
      setError(error.response.data.errors);
    }
  };

  const [input, setInput] = useState();
  const [filterData, setFilteredData] = useState(null);

  const handleSearchClient = async (e) => {
    const value = e.target.value;
    if (value != "") {
      const res = await axios.get(`/api/searchClient/${value}`);
      setAllClients(res.data);
    } else {
      getClient();
    }
  };

  const handleClick = async (e) => {
    const res = await axios.get(`/api/searchClient/${input}`);
    setFilteredData(res.data);
  };

  const [inputDate, setInputDate] = useState({ startDate: "", endDate: "" });
  const handleDateChange = async (e) => {
    const { name, value } = e.target;
    setInputDate({ ...inputDate, [name]: value });
  };

  const handleDateClick = async (e) => {
    const res = await axios.get(
      `/api/searchDateClient/${inputDate.startDate}/${inputDate.endDate}`
    );
    setAllClients(res.data);
  };

  const [filterTasksByDate, setFilterTasksByDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handleFilterTasksByDate = (e) => {
    const { name, value } = e.target;
    setFilterTasksByDate({ ...filterTasksByDate, [name]: value });
  };

  const filterTasksByDateClick = async () => {
    try {
      const res = await axios.get(`/api/filterTasksByDate`, {
        params: {
          startDate: filterTasksByDate.startDate,
          endDate: filterTasksByDate.endDate,
        },
      });
      setTask(res.data);
    } catch (error) {
      showErrorMessage(error.response.data.errors.startDate[0]);
    }
  };

  const clearFilterTaskTechnicien = () => {
    getTasksCommerciale();
    setFilterTasksByDate({ startDate: "", endDate: "" });
  };

  const handleAnnulerDateClick = () => {
    setInputDate({ startDate: "", endDate: "" });
    getClient();
  };

  const [NewTask, setNewTask] = useState({
    Clients: [],
    TypesTasks: [],
    TypesDevice: [],
    Articles: [],
    SIM: [],
    Accessories: [],
    Users: [],
    Cities: [],
  });

  const getTaskInfo = async () => {
    const response = await axios.get("/api/supportTechnique/newTask");
    setNewTask(response.data);
  };

  const UpdateTaskToConfirmSubmti = async (e, id, UpdateTask) => {
    e.preventDefault();
    try {
      await axios.put(`/api/gerant/UpdateTaskToConfirmed/${id}`, UpdateTask);
      showSuccessMessage("tâche modifiée avec succès");
      getTasktoConfirmed();
    } catch (error) {
      setError(error);
    }
  };
  const UpdateTaskToConfirmPreClientSubmit = async (e, UpdateTask) => {
    e.preventDefault();
    try {
      await axios.put(
        `/api/UpdateTaskToConfirmed/${UpdateTask.id}`,
        UpdateTask
      );
      showSuccessMessage("tâche modifiée avec succès");
      getTasksForEachClient(UpdateTask.client_id);
    } catch (error) {
      setError(error);
    }
  };

  //logic technician :
  const [data, setdata] = useState();
  const prevCountRef = useRef();

  return (
    <CommercialContext.Provider
      value={{
        getClient,
        allClients,
        getTasksCommerciale,
        getTasksForEachClient,
        tasksPreClient,
        task,
        deleteTask,
        deleteTaskPreClient,
        UpdateTaskToConfirmSubmti,
        UpdateTaskToConfirmPreClientSubmit,
        showDeletePop,
        setShowDeletePop,
        error,
        getTaskInfo,
        NewTask,
        clients,
        handleSearchClient,
        handleClick,
        input,
        filterData,
        inputDate,
        handleDateChange,
        handleDateClick,
        addClient,
        setaddClient,
        handleAddClient,
        submitAddClient,
        submitTaskToConfirmed,
        paginaed,
        setPaginated,
        setTask,
        data,
        setdata,
        prevCountRef,
        getTasktoConfirmed,
        taskToConfirmed,
        setPaginatedTaskToConfirm,
        paginaedTaskToConfirm,
        show,
        setShow,
        handleFilterClientInfo,
        filterClientInfo,
        setFilterClientInfo,
        handleFilterTasksByDate,
        filterTasksByDate,
        filterTasksByDateClick,
        clearFilterTaskTechnicien,
        handleAnnulerDateClick,
      }}
    >
      {children}
    </CommercialContext.Provider>
  );
};

export default CommercialContext;
