import React, { createContext, useEffect, useState } from "react";
import axios from "./axios";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const ComptableContext = createContext();

export const ComptableProvider = ({ children }) => {
  const [alltype, setAllType] = useState([]);
  const [type, setType] = useState([]);
  const [typesim, setTypeSim] = useState([]);
  const [typeacc, setTypeacc] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filteredData, setDataFiltering] = useState(null);
  const [filterByDate, setFilterByDate] = useState({
    startDate: null,
    endDate: null,
  });

  const clearFilter = () => {
    setFilterByDate({ startDate: "", endDate: "" });
  };
  const getAllTypes = async () => {
    const response = await axios.get("/api/comptable/allTypesOfAppareils");
    setAllType(response.data);
  };

  //error messege Function
  const showErrorMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // Success Message toast
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  //get GPS

  const getTypesgps = async () => {
    const response = await axios.get("/api/comptable/allTypesOfAppareils");
    setType(response.data.filter((appareil) => appareil.appareil_id === 1));
  };

  //get Sim
  const getTypessim = async () => {
    const response = await axios.get("/api/comptable/allTypesOfAppareils");
    setTypeSim(response.data.filter((appareil) => appareil.appareil_id === 2));
  };

  //get All Types Of Accessories
  const getTypesacc = async () => {
    const response = await axios.get("/api/comptable/allTypesOfAppareils");
    setTypeacc(response.data.filter((appareil) => appareil.appareil_id === 3));
  };

  //get All technician

  const [allUsers, setAllUsers] = useState();

  const getAllUsers = async () => {
    const response = await axios.get("api/comptable/allUsers");
    setAllUsers(response.data);
  };

  // edit serial number, model and endroit appareils who are with technician
  const [responseMessage, setResponseMessage] = useState();
  const editAppareilsPreUser = async (newData) => {
    const response = await axios.put(
      `api/comptable/editAppareilPreUser/${newData.appareilId}`,
      newData
    );
    setResponseMessage(response.status);
  };

  // start add new type sim
  const [simModel, SetSiMmode] = useState({
    appareil_id: 2,
    nameType: "",
  });
  const handleSIMModel = (e) => {
    const { name, value } = e.target;
    SetSiMmode({ ...simModel, [name]: value });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const addTypeSubmit = async (e) => {
    try {
      e.preventDefault();
      await axios.post("/api/comptable/addnewType", simModel);
      getTypessim();
      SetSiMmode("");
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  // end add new type sim

  const [SIMAddSpecificModel, setAddSPecificSIMMODEL] = useState({
    type_id: "",
    nameModele: "",
  });
  const [count, setCount] = useState(0);
  const [AccessoireAddSpecificModel, setAccessoireAddSpecificModel] = useState({
    type_id: "",
    nameModele: "",
  });

  const addModelSubmit = async (e) => {
    e.preventDefault();
    await axios.post("/api/comptable/addnewModel", SIMAddSpecificModel);
    getTypessim();
  };
  const addModelaccSubmit = async (e) => {
    e.preventDefault();
    await axios.post("/api/comptable/addnewModel", AccessoireAddSpecificModel);
    getTypesacc();
  };

  const [AllApparielForComtple, setAllAppareilForComtple] = useState({});

  const getAllAppareilsForComtaple = async () => {
    const allAppareils = await axios.get("/api/comptable/allAppareils");
    setAllAppareilForComtple(allAppareils.data);
  };

  const [CheckBoxAffectedArticle, setCheckBoxAffectedArticle] = useState([]);
  const [AffectedArticle, setAffectedArticle] = useState({
    SupportTechnicien: "",
  });

  const handleAffectedArticleOption = (checkedArticles) => {
    setAffectedArticle({
      ...AffectedArticle,
      Articles: checkedArticles,
    });
  };
  const handleAffectedTechnicianOption = (e) => {
    const { name, value } = e.target;
    setAffectedArticle({
      ...AffectedArticle,
      [name]: value,
      Articles: CheckBoxAffectedArticle,
    });
  };
  useEffect(() => {
    setAffectedArticle({
      ...AffectedArticle,
      Articles: CheckBoxAffectedArticle,
    });
  }, [CheckBoxAffectedArticle]);
  const handleAffectedCheckBox = (e, article) => {
    const { name, checked, value, id } = e.target;
    if (article.serial_number == id) {
      if (checked == true) {
        setAffectedArticle({
          ...AffectedArticle,
          [name]: checked,
          IMEI: article.serial_number,
        });
      } else {
        setAffectedArticle({ ...AffectedArticle, [name]: false, IMEI: "" });
      }
    }
  };
  const [allSupportTechnicals, setAllAupportTechnicals] = useState([]);

  const getAllaSupportTechnicals = async () => {
    const AllSupportTechnicals = await axios.get(
      "/api/comptable/AllSupportTechnicals"
    );
    setAllAupportTechnicals(AllSupportTechnicals.data);
  };

  //get all tasks technicien and support technical has etat done
  const [task, setSTTEchnicianTask] = useState([]);

  const getAllTerminedTasks = async () => {
    const res = await axios.get("/api/comptable/AllTerminedTasks");
    setSTTEchnicianTask(res.data.taskTechniciens);
  };

  const SubmittedToTechnician = async () => {
    try {
      await axios.post("/api/comptable/AffectedArticle", AffectedArticle);
      setAffectedArticle(null);
      getAllAppareilsForComtaple();
      setErrorMessage(null);
      showSuccessMessage("appareils envoyés avec succès");
    } catch (error) {
      setErrorMessage(error.response.data.errors);
      showErrorMessage("impossible d'envoyer des appareils");
    }
  };

  const [filteredDataByBox, setFilteredDataByBox] = useState();
  const [searchAppareil, setSetSearchAppareil] = useState({
    getAllGPSComptable: "",
  });
  const HandlesearchBoxNumber = async (e) => {
    const value = e.target.value;
    const typeAppareil = e.target.name;
    if (value != "") {
      const res = await axios.get(
        `/api/comptable/SearchedDataByBox/${value}/${typeAppareil}`
      );
      setSetSearchAppareil({ ...searchAppareil, getAllGPSComptable: res.data });
    } else if (value == "") {
      setSetSearchAppareil(null);
    }
  };

  const [importAppareils, setImportAppareils] = useState({});

  const handleChangeImportAppareils = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setImportAppareils({
      ...importAppareils,
      [name]: value,
    });
  };

  const handleGarantie = (etatGarantie) => {
    setImportAppareils({ ...importAppareils, garantie: etatGarantie });
  };
  //handle import  Appareil Form Excele
  const readExcelFile = async (file) => {
    const readerFile = new FileReader();
    const data = [];
    readerFile.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      setImportAppareils({
        ...importAppareils,
        Serie: XLSX.utils
          .sheet_to_json(ws, {
            header: 1,
            raw: false,
          })
          .flat(),
      });
    };
    readerFile.readAsArrayBuffer(file);
  };
  const [devicesNotInserted, setDevicesNotInserted] = useState(null);

  const handleSubmitImportAppareils = async (e) => {
    e.preventDefault();
    try {
      const submit = await axios.post(
        "/api/comptable/import-new-appareil",
        importAppareils
      );
      getAllAppareilsForComtaple();
      setDevicesNotInserted(submit.data.devicesNotInserted);
      showSuccessMessage(submit.data.devicesInserted);
      setErrorMessage(submit.status);
    } catch (e) {
      showErrorMessage("Échec de la Importation appareil(s)");
    }
  };

  const [notificationCompta, setNotificationCompta] = useState({
    status: "",
    message: [],
  });

  const [counter, setCounter] = useState(0);
  const [isLogicDone, setIsLogicDone] = useState(false);

  const getCOmptaAppareil = async () => {
    const res = await axios.get("/api/comptable/allAppareils");
    if (res.data.NumberGps < 10) {
      setCounter((counter) => counter + 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, "GPS Almost Done"],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberSIM < 10) {
      setCounter((counter) => counter + 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, "SIM Almost Done"],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberAccessory < 10) {
      setCounter((counter) => counter + 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, "ACC Almost Done"],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberGps > 10) {
      setCounter((counter) => counter - 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, ""],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberSIM > 10) {
      setCounter((counter) => counter - 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, ""],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberAccessory > 10) {
      setCounter((counter) => counter - 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, ""],
      }));
      setIsLogicDone(!isLogicDone);
    }
  };

  const updatedTheStatus = () => {
    setNotificationCompta((prevState) => ({
      ...prevState,
      status: counter,
    }));
  };

  // update and delete device from comptable

  const [updateDeviceShow, setUpdateDeviceShow] = useState(false);
  const handleConfirmAction = async (id) => {
    try {
      await axios.delete(`api/comptable/deleteArticles/${id}`);
      getAllAppareilsForComtaple();
      showSuccessMessage("l'appareil  supprimer avec succès");
    } catch (error) {
      showErrorMessage("impossible de supprimer cet appareil");
    }
  };

  const updateDeviceSubmit = async (e, updateDeice) => {
    e.preventDefault();
    try {
      await axios.post("/api/comptable/updateArticle", updateDeice);
      getAllAppareilsForComtaple();
    } catch (error) {
      console.log(error);
    }
  };
  // end  update and delete device from comptable

  const [allClients, setAllClients] = useState();
  const getAllClients = async () => {
    const allClientResult = await axios.get("/api/comptable/allClients");
    setAllClients(allClientResult.data);
  };

  // get All cities function

  const [allCities, setAllCities] = useState();
  const getAllCities = async () => {
    const response = await axios.get("/api/comptable/allCities");
    setAllCities(response.data);
  };

  // add new Client Function
  const [addNewCustomer, setAddNewCustomer] = useState({});
  const handleAddNewCustomer = (e) => {
    const { name, value } = e.target;
    setAddNewCustomer({ ...addNewCustomer, [name]: value });
  };

  const submitAddNewCustomer = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/comptable/addNewClient", addNewCustomer);
      showSuccessMessage("Client ajouter avec succès");
      getAllClients();
      setAddNewCustomer({});
    } catch (error) {
      setErrorMessage(error.response.data.errors);
    }
  };

  // update client info Function
  const sumitUpdateCustomerInfo = async (e, clientInfo) => {
    e.preventDefault();
    try {
      await axios.put(
        `/api/comptable/updateClient/${clientInfo.id}`,
        clientInfo
      );
      showSuccessMessage("Client modifié avec succès");
      getAllClients();
    } catch (e) {
      showErrorMessage("Échec de la modification du client");
    }
  };

  const handleChangeEtatBilled = async (e, idTask) => {
    e.preventDefault();
    await axios.put(`/api/comptable/updateEtatBilled/${idTask}`);
    getAllTerminedTasks();
  };

  const handleSearchTechnicianTaskPerDate = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setFilterByDate({
      ...filterByDate,
      [name]: value,
    });
  };
  const handleSearchTechnicianTaskPerDateClicked = async (profil_id) => {
    const res = await axios.get(
      `/api/comptable/searchTechnicianByDate/${filterByDate.startDate}/${filterByDate.endDate}/${profil_id}`
    );
    setDataFiltering(res.data);
  };

  const handleFilterClientsByDateClick = async () => {
    try {
      const res = await axios.get(`/api/comptable/filterClientsByDate`, {
        params: {
          startDate: filterByDate.startDate,
          endDate: filterByDate.endDate,
        },
      });
      setAllClients(res.data);
    } catch (error) {
      showErrorMessage(error.response.data.errors.startDate[0]);
    }
  };

  const clearFilterClientByDate = () => {
    clearFilter();
    getAllClients();
  };
  return (
    <ComptableContext.Provider
      value={{
        updateDeviceShow,
        setUpdateDeviceShow,
        errorMessage,
        setErrorMessage,
        getTypesgps,
        type,
        typesim,
        getTypessim,
        getTypesacc,
        typeacc,
        addModelSubmit,
        SIMAddSpecificModel,
        setAddSPecificSIMMODEL,
        addModelaccSubmit,
        AccessoireAddSpecificModel,
        setAccessoireAddSpecificModel,
        count,
        getAllTypes,
        alltype,
        AllApparielForComtple,
        setAllAppareilForComtple,
        getAllAppareilsForComtaple,
        CheckBoxAffectedArticle,
        setCheckBoxAffectedArticle,
        handleAffectedArticleOption,
        setAffectedArticle,
        handleAffectedTechnicianOption,
        handleAffectedCheckBox,
        SubmittedToTechnician,
        getAllaSupportTechnicals,
        allSupportTechnicals,
        HandlesearchBoxNumber,
        filteredDataByBox,
        searchAppareil,
        handleSIMModel,
        addTypeSubmit,
        handleClose,
        updatedTheStatus,
        getCOmptaAppareil,
        isLogicDone,
        notificationCompta,
        setNotificationCompta,
        setCounter,
        handleConfirmAction,
        updateDeviceSubmit,
        allClients,
        getAllClients,
        getAllCities,
        allCities,
        handleAddNewCustomer,
        setAddNewCustomer,
        submitAddNewCustomer,
        sumitUpdateCustomerInfo,
        addNewCustomer,
        handleChangeEtatBilled,
        editAppareilsPreUser,
        getAllUsers,
        allUsers,
        responseMessage,
        setResponseMessage,
        task,
        getAllTerminedTasks,
        handleSearchTechnicianTaskPerDate,
        filterByDate,
        handleSearchTechnicianTaskPerDateClicked,
        handleFilterClientsByDateClick,
        clearFilterClientByDate,
        filteredData,
        setDataFiltering,
        handleChangeImportAppareils,
        readExcelFile,
        handleGarantie,
        handleSubmitImportAppareils,
        setDevicesNotInserted,
        devicesNotInserted,
      }}
    >
      {children}
    </ComptableContext.Provider>
  );
};

export default ComptableContext;
