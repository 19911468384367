import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import STcontext from "../../../context/STcontext";
import { MDBIcon } from "mdb-react-ui-kit";
import ComptableContext from "../../../context/ComptableContext";

export default function ImportSim() {
  const {
    modeles,
    readExcelFile,
    handleImportSim,
    importSimSubmit,
    showImportSim,
    simNotImporter,
    setShowImportSim,
    setSimNotImporter,
  } = useContext(STcontext);
  const { alltype, getAllTypes } = useContext(ComptableContext);
  const [errorMessage, setErrorMessage] = useState([]);
  const [simModel, setSimModel] = useState([]);
  const handleClose = () => {
    setShowImportSim(false);
    setSimNotImporter([]);
  };
  const handleShow = () => {
    setShowImportSim(true);
  };

  useEffect(() => {
    getAllSimModel();
  }, [modeles]);
  const getAllSimModel = () => {
    setSimModel(
      modeles && modeles.filter((sim) => sim.type_id === 4 || sim.type_id === 5)
    );
  };
  const simNotImportedBody = (simNotImporter) => {
    return (
      <>
        <hr />
        <h6>Sim Non Importer:</h6>
        {simNotImporter.map((sim, index) => (
          <ul key={index} style={{ display: "inline-block" }}>
            <li>{sim}</li>
          </ul>
        ))}
      </>
    );
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        <MDBIcon fas icon="download" /> Import SIM{" "}
      </Button>

      <Modal show={showImportSim} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import SIM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={importSimSubmit}>
            <Row>
              <Form.Group
                as={Col}
                className="mb-3"
                controlId="validationCustom04"
              >
                <Form.Label>Modele appareil &nbsp;
                    <span className="star-Mandatory-field">*</span> &nbsp;:{" "}</Form.Label>
                <Form.Select
                  onChange={handleImportSim}
                  aria-label="Default select example"
                  name="modele_id"
                >
                  <option value="none" selected disabled hidden>
                    Select Model 
                  </option>
                  <option disabled>Select Model</option>
                  {simModel.map((sim) => (
                    <option key={sim.id} value={sim.id}>
                      {sim.nameModele}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3" controlId="formFile">
                <Form.Label>Select File &nbsp;
                    <span className="star-Mandatory-field">*</span> &nbsp;:{" "}</Form.Label>
                <Form.Control
                  type="file"
                  accepte=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcelFile(file);
                  }}
                  name="simFile"
                />
              </Form.Group>
            </Row>
            {errorMessage && <p>{errorMessage}</p>}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Observation</Form.Label>
              <Form.Control
                name="observation"
                onChange={handleImportSim}
                as="textarea"
                rows={3}
              />
            </Form.Group>

            {simNotImporter.length > 0
              ? simNotImportedBody(simNotImporter)
              : ""}
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Importer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
