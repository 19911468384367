import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useContext } from "react";
import ComptableContext from "../../../context/ComptableContext";
import STcontext from "../../../context/STcontext";

export const EditAppareilsPreUser = ({ data, paramsId, paramsTypeId }) => {
  const [modalShow, setModalShow] = useState(false);
  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const {
    type,
    allUsers,
    editAppareilsPreUser,
    responseMessage,
    setResponseMessage,
  } = useContext(ComptableContext);
  const [allTechnicien, setAllTechnicien] = useState([]);
  const { getAppareilsPerUser, apparielsPerUser } = useContext(STcontext);
  const [editAppareilPreUser, setEditAppareilPreUser] = useState({
    appareilId: data.appareilId,
    serial_number: data.serial_number,
    nameModele: data.nameModele,
    nameType: data.nameType,
    name: data.name,
  });
  const handleChangeEditAppareil = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex]; // Get the selected option
    const data_special = selectedOption.getAttribute("data-special");
    setEditAppareilPreUser({
      ...editAppareilPreUser,
      [name]: value,
      special: data_special,
    });
  };

  useEffect(() => {
    if (responseMessage === 200) {
      getAppareilsPerUser(paramsId, paramsTypeId);
      handleClose();
      setResponseMessage(null);
    }
  }, [responseMessage]);

  useEffect(() => {
    setEditAppareilPreUser({
      appareilId: data.appareilId,
      serial_number: data.serial_number,
      nameModele: data.nameModele,
      nameType: data.nameType,
      name: data.name,
    });
  }, [apparielsPerUser]);
  useEffect(()=>{
    setAllTechnicien( allUsers?.filter((technicien) => technicien.profil_id === 1))
  }, [allUsers])

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "start", padding: "25px" }}
      >
        <Button variant="primary" onClick={handleShow}>
          <BorderColorIcon />
        </Button>
      </div>

      <Modal
        show={modalShow}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modifier Appareil
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>IMEI</Form.Label>
              <Form.Control
                type="text"
                name="serial_number"
                placeholder="12345678909874233"
                value={editAppareilPreUser.serial_number}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Model Appareil</Form.Label>
              <Form.Control
                list="NameModelList"
                type="text"
                name="nameModele"
                placeholder="FMB920"
                value={editAppareilPreUser.nameModele}
                autoComplete="off"
                onChange={handleChangeEditAppareil}
              />
              <datalist id="NameModelList">
                {type.map((typeToModel) =>
                  typeToModel.modeles
                    ? typeToModel.modeles.map((modelAppareil) => (
                        <option key={modelAppareil.id}>
                          {modelAppareil.nameModele}
                        </option>
                      ))
                    : ""
                )}
              </datalist>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type Appareil</Form.Label>
              <Form.Control
                type="text"
                placeholder="TELTONIKA"
                value={editAppareilPreUser.nameType}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Endroit</Form.Label>
              <Form.Select
                list="nameUsersList"
                type="text"
                name="name"
                placeholder="TECHNICIEN"
                onChange={handleChangeEditAppareil}
              >
                <option value="none" selected disabled hidden>
                  {editAppareilPreUser.name}
                </option>
                <option disabled>Select Utilisateur</option>
                {allTechnicien?.map((user) => (
                  <option key={user.id} data-special="tech" value={user.id}>
                    {user.name}
                  </option>
                ))}
                <option data-special="ST">Support Technique</option>
                <option data-special="comptable">Comptable</option>
              </Form.Select>
            </Form.Group>
            <Modal.Footer>
              <Button onClick={() => editAppareilsPreUser(editAppareilPreUser)}>
                Modifier
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
