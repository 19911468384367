import React from "react";
import AppCards from "./AppCards";
import "./AppStyle.css";

export default function TechAppareil() {
  return (
    <div>
      <AppCards />
    </div>
  );
}
