import React, { useContext, useEffect, useState } from "react";
import "../../../App.css";
import _ from "lodash";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import SIM from "./SGA/Sim";
import Gps from "./SGA/Gps";
import Accessoires from "./SGA/Accessoires";
import STcontext from "../../context/STcontext";
import pusher from "../../../pusher";

export default function AppCards() {
  const [table, setTable] = useState(null);
  const [task, setTask] = useState([]);
  const { getAppareil, appareil, technicianAll } = useContext(STcontext);

  useEffect(() => {
    technicianAll();
    getAppareil();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAppareil();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  useEffect(() => {
    setTask(appareil.AllGPS);
  }, []);
  const handleTable = (e) => {
    const { id } = e.target;
    if (id == 1) {
      setTable(<Gps />);
    } else if (id == 2) {
      setTable(<SIM />);
    } else if (id == 3) {
      setTable(<Accessoires />);
    }
  };
  return (
    <>
      <MDBRow
        className="AppCard"
        style={{ width: "100%", padding: "30px 100px 30px 100px" }}
      >
        <MDBCol>
          <MDBCard className="cardStats pointer">
            <MDBCardBody id="1" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>Gps </MDBCardTitle>
              <span className="card-text">{appareil.NumberGps}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard className="cardStats pointer">
            <MDBCardBody id="2" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>SIM</MDBCardTitle>
              <span className="card-text">{appareil.NumberSIM}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard className="cardStats pointer">
            <MDBCardBody id="3" onClick={(e) => handleTable(e)}>
              <MDBCardTitle>Accessoires</MDBCardTitle>
              <span className="card-text">{appareil.NumberAccessory}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <div>{table != null ? table : ""}</div>
    </>
  );
}
