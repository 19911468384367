import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import STcontext from "../../../context/STcontext";
export const AddNewSim = () => {
  const [show, setShow] = useState(false);

  const {
    modeles,
    handleChangeAddNewSim,
    handleChangeSimNumber,
    handleAddNewSimSubmit,
    addInput,
    setArr,
    arr,
    appareil,
    setErrorsMessages,
    errorsMessages,
  } = useContext(STcontext);

  const handleShow = () => setShow(true);

  const modelesSim =
    modeles &&
    modeles.filter(
      (modeleSim) => modeleSim.type_id === 4 || modeleSim.type_id === 5
    );

  const handleClose = () => {
    setErrorsMessages();
    setArr([
      {
        type: "text",
        value: "",
        type_id: "",
      },
    ]);
    setShow(false);
  };
  useEffect(() => {
    handleClose();
  }, [appareil]);

  return (
    <>
      <Button onClick={handleShow} variant="primary">
        <AddIcon /> Ajouter SIM{" "}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter Nouvelle SIM</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form method="post">
            <Row className="mb-3">
              <Col>
                <Form.Group md="6">
                  <Form.Label>
                    Modèle sim &nbsp;
                    <span className="star-Mandatory-field">*</span> &nbsp;:{" "}
                  </Form.Label>
                  <Form.Select
                    name="modelName"
                    autoComplete="off"
                    onChange={handleChangeAddNewSim}
                  >
                    <option value="none" selected disabled>
                      Select Modèle
                    </option>
                    {modelesSim &&
                      modelesSim.map((model) => (
                        <option key={model.id}>{model.nameModele}</option>
                      ))}
                  </Form.Select>
                  {errorsMessages && errorsMessages.modelName ? (
                    <span style={{ color: "red" }}>
                      {errorsMessages.modelName[0]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Propriétaire sim &nbsp;
                    <span className="star-Mandatory-field">*</span> &nbsp;:{" "}
                  </Form.Label>
                  <Form.Select onChange={handleChangeAddNewSim} name="simOwner">
                    <option value="none" selected disabled>
                      Select propriétaire
                    </option>
                    <option value={1}>Client</option>
                    <option value={0}>D3 hi-tech</option>
                  </Form.Select>
                  {errorsMessages && errorsMessages.simOwner ? (
                    <span style={{ color: "red" }}>
                      {errorsMessages.simOwner[0]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              {arr
                ? arr.map((item, i) => {
                    return (
                      <Form.Group
                        key={i}
                        md="6"
                        as={Col}
                        controlId="validationCustom02"
                      >
                        <Form.Label size="40">
                          SIM N° <span className="star-Mandatory-field">*</span>{" "}
                          &nbsp;:
                        </Form.Label>
                        <input
                          className="form-control"
                          onChange={handleChangeSimNumber}
                          value={item.value}
                          id={i}
                          type={item.type}
                          size="40"
                          name="Serie"
                          autoComplete="off"
                        />
                        <span style={{ color: "red" }}>
                          {errorsMessages && errorsMessages.Serie
                            ? errorsMessages.Serie[0]
                            : ""}
                        </span>
                      </Form.Group>
                    );
                  })
                : ""}

              <Form.Group className="mt-4" as={Col} md="6" controlId="valid">
                <Button variant="secondary" onClick={addInput}>
                  <AddCircleIcon />
                </Button>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Observation</Form.Label>
                <Form.Control
                  as="textarea"
                  name="observation"
                  rows={3}
                  onChange={handleChangeAddNewSim}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            onClick={handleAddNewSimSubmit}
          >
            Ajouter sim{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
