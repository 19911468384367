import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import STcontext from "../context/STcontext";
import Button from "react-bootstrap/Button";
import DetailsModel from "../ST/Technicien/DetailsModel";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";

export const TaskByStatu = () => {
  const { task, handleTEchnicianSearchClick, handleTechnicianSearchInput } =
    useContext(STcontext);
  let location = useLocation();
  const tasksByState = location.state.idTask;
  const [pages, setPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const lastTaskIndex = pages * rowsPerPage;
  const firstTaskIndex = lastTaskIndex - rowsPerPage;
  const currentTask = tasksByState.slice(firstTaskIndex, lastTaskIndex);

  let numberPages = [];
  for (let p = 1; p <= Math.ceil(tasksByState.length / rowsPerPage); p++) {
    numberPages.push(p);
  }

  return (
    <div>
      <div className="bar-table">
        {/* <div class="search input-group rounded">
        <input type="search" class="form-control rounded" onChange={handleTechnicianSearchInput} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
        <span class="input-group-text border-0" style={{ cursor: 'pointer' }} onClick={handleTEchnicianSearchClick} id="search-addon">
          <i class="fas fa-search"></i>
        </span>
      </div> */}
      </div>
      <div className="tâches-tech">
        <div className="Table-tech">
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Nom du client</th>
                <th scope="col">Type du tache</th>
                <th scope="col">date affectation</th>
                <th scope="col">matricule</th>
                <th scope="col">Exécuter par </th>
                <th scope="col">Statut </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {currentTask.map((task) => (
                <tr
                  key={task.id}
                  style={{
                    background:
                      task.user_id == task.uti_utilsateur_id &&
                      task.date_previsionnelle_fin == null
                        ? "#06c491"
                        : task.etat_tache == 3 &&
                          task.date_previsionnelle_debut >
                            task.date_previsionnelle_fin
                        ? "red"
                        : "white",
                  }}
                >
                  <td>
                    <DetailsModel taskToUpdate={task} />
                  </td>
                  <td>
                    <p className="fw-bold mb-1">{task.client_name} </p>
                  </td>
                  <td>{task.catache}</td>
                  <td>{task.date_affectation}</td>
                  <td>{task.matricule}</td>
                  <td>{task.user_name}</td>
                  <td>
                    <Button>
                      {task.etat_tache == 0 ? "en attente" : ""}

                      {task.etat_tache == 1 ? "en cours" : ""}
                      {task.etat_tache == 2 ? "terminée" : ""}
                      {task.etat_tache == 3 ? "reportée" : ""}
                      {task.etat_tache == 4 ? "annulée" : ""}
                    </Button>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
          <nav aria-label="...">
            <MDBPagination className="mb-0 d-flex justify-content-end">
              <MDBPaginationItem disabled>
                <MDBPaginationLink href="#" tabIndex={-1} aria-disabled="true">
                  Pages
                </MDBPaginationLink>
              </MDBPaginationItem>
              {numberPages.map((numberPage, index) => (
                <MDBPaginationItem
                  className={`${numberPage == pages ? "active" : "null"}`}
                >
                  <MDBPaginationLink
                    style={{ cursor: "pointer" }}
                    onClick={() => setPages(numberPage)}
                  >
                    {numberPage}
                  </MDBPaginationLink>
                </MDBPaginationItem>
              ))}
            </MDBPagination>
          </nav>
        </div>
      </div>
    </div>
  );
};
