import React, { useContext, useEffect, useState } from "react";
import "../../../App.css";
import _ from "lodash";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import SIM from "./SGA/Sim";
import Gps from "./SGA/Gps";
import Accessoires from "./SGA/Accessoires";
import STcontext from "../../context/STcontext";

export default function AppCards() {
  const [table, setTable] = useState(null);
  const { getGerantAppareil, gerantAppareil } = useContext(STcontext);

  useEffect(() => {
    getGerantAppareil();
  }, []);
  const handleTable = (e) => {
    const { id } = e.target;
    if (id == 1) {
      setTable(<Gps />);
    } else if (id == 2) {
      setTable(<SIM />);
    } else if (id == 3) {
      setTable(<Accessoires />);
    }
  };
  return (
    <>
      <MDBRow
        className="AppCard"
        style={{ width: "100%", padding: "30px 100px 30px 70px" }}
      >
        <MDBCol>
          <MDBCard className="cardStats">
            <MDBCardBody
              className="pointer"
              id="1"
              onClick={(e) => handleTable(e)}
            >
              <MDBCardTitle>Gps </MDBCardTitle>
              <span className="card-text">{gerantAppareil.NumberGps}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard className="cardStats">
            <MDBCardBody
              className="pointer"
              id="2"
              onClick={(e) => handleTable(e)}
            >
              <MDBCardTitle>SIM</MDBCardTitle>
              <span className="card-text">{gerantAppareil.NumberSIM}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard className="cardStats  ">
            <MDBCardBody
              className="pointer"
              id="3"
              onClick={(e) => handleTable(e)}
            >
              <MDBCardTitle>Accessoires</MDBCardTitle>
              <span className="card-text">
                {gerantAppareil.NumberAccessory}
              </span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <div>
        {table == null ? (
          <div
            style={{
              width: "90%",
              padding: "2rem",
              marginTop: "1rem",
              marginLeft: "50px",
              borderRadius: "2rem",
            }}
          ></div>
        ) : (
          table
        )}
      </div>
    </>
  );
}
