import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "../../../../context/axios";
import ComptableContext from "../../../../context/ComptableContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function UpdateType({ typedata, appareil_id }) {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState();
  const [updateGpsModel, SetUpdateGpsMode] = useState({});
  const handleClose = () => {
    setErrors();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { getTypesgps, getTypessim, getTypesacc } =
    useContext(ComptableContext);
  useEffect(() => {
    SetUpdateGpsMode({
      typeId: typedata.id,
      newNameType: typedata.nameType,
    });
  }, [show]);

  const handleUpdateGpsType = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    SetUpdateGpsMode({ ...updateGpsModel, [name]: value });
  };
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const callGetTypeFun = (appareil_id) => {
    switch (appareil_id) {
      case "1":
        getTypesgps();
        break;
      case "2":
        getTypessim();
        break;
      case "3":
        getTypesacc();
        break;
    }
  };

  const UpdateTypeSubmit = async (e) => {
    try {
      e.preventDefault();
      const response = await axios.post(
        "/api/comptable/updateType",
        updateGpsModel
      );
      callGetTypeFun(appareil_id);
      handleClose();
      showSuccessMessage(response.data);
    } catch (e) {
      setErrors(e.response.data.errors);
    }
  };

  return (
    <div>
      <div>
        <Button variant="primary" onClick={handleShow}>
          Modifier Type
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={UpdateTypeSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Nouvelle Nom Type : </Form.Label>
                <Form.Control
                  required
                  autoComplete="off"
                  type="text"
                  name="newNameType"
                  value={updateGpsModel ? updateGpsModel.newNameType : ""}
                  onChange={handleUpdateGpsType}
                />
                <span style={{ color: "red" }}>{errors?.newNameType[0]}</span>
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                ENREGISTRER MODIFICATION
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
