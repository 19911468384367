import React, { useContext, useEffect, useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import Form from "react-bootstrap/Form";
import NouvelleTâches from "./Technicien/NouvelleTâches";
import { AuthContext } from "../context/AuthContext";
import STcontext from "../context/STcontext";
import DetailsTaskST from "./DetailsTaskST";
import pusher from "../../pusher";
import { useNavigate } from "react-router-dom";

const TachesSt = () => {
  const { getStTaches, handleChangeTaskST, tasksST } = useContext(STcontext);
  const { USE } = useContext(AuthContext);
  const navigateTo = useNavigate();
  useEffect(() => {
    getStTaches();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getStTaches();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    client_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    matricule: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    user_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    assign_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    catache: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Nom Client, type tâche..."
          />
        </span>
      </div>
    );
  };

  const currenttaskStatus = (taskStatus) => {
    switch (taskStatus) {
      case 0:
        return "en attente";
      case 1:
        return "en cours";
      case 2:
        return "terminé";
      case 3:
        return "reporté";
      case 4:
        return "annulé";
    }
  };
  const handleSelectChange = (e, rowData) => {
    const taskStatus = e.target.value;
    switch (taskStatus) {
      case "1":
        handleChangeTaskST(rowData.tache_id);
        break;
      case "2":
        navigateTo(
          `/SupportTechnique/tâches/supporttechnique/${rowData.tache_id}/terminer`,
          { state: rowData }
        );

        break;
      case "3":
        navigateTo(
          `/SupportTechnique/tâches/supporttechnique/${rowData.tache_id}/reporter`,
          { state: rowData }
        );
        break;
      case "4":
        navigateTo(
          `/SupportTechnique/tâches/supporttechnique/${rowData.tache_id}/annuler`,
          { state: rowData }
        );
        break;
    }
  };

  const renderTaskStatus = (rowData) => {
    return (
      <Form.Select
        onChange={(e) => handleSelectChange(e, rowData)}
        className="tâches-statut"
        aria-label="Default select example"
        style={{ margin: "5px" }}
      >
        <option value={rowData.etat_tache}>
          {currenttaskStatus(rowData.etat_tache)}
        </option>
        <option value={1}>en cours</option>
        <option value={2}>terminé</option>
        <option value={3}>reporté</option>
        <option value={4}>annulé</option>
      </Form.Select>
    );
  };

  const renderMoreDetailsBody = (rowData) => {
    return <DetailsTaskST taskSTToUpdate={rowData} />;
  };

  const header = renderHeader;
  const taskStatus = renderTaskStatus;
  const moreDetailsBody = renderMoreDetailsBody;

  return (
    <div>
      <div className="bar-table">
        {USE.profil_id == 2 ? (
          <div className="">
            <NouvelleTâches />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="tâches-tech">
        <div className="Table-tech">
          <DataTable
            value={tasksST}
            paginator
            sortMode="multiple"
            removableSort
            rows={10}
            dataKey="id"
            filters={filters}
            globalFilterFields={[
              "client_name",
              "user_name",
              "catache",
              "date_affectation",
              "assign_name",
            ]}
            header={header}
            emptyMessage="Aucune tâche trouvée."
          >
            <Column
              header="Plus Détails"
              style={{ minWidth: "10rem" }}
              body={moreDetailsBody}
            />
            <Column
              field="client_name"
              header="Nom du client"
              style={{ minWidth: "12rem" }}
              sortable
            />
            <Column
              header="Envoyer par"
              field="user_name"
              style={{ minWidth: "10rem" }}
              sortable
            />
            <Column
              header="Type du tache"
              field="catache"
              style={{ minWidth: "12rem" }}
              sortable
            />
            <Column
              field="date_affectation"
              header="date affectation"
              style={{ minWidth: "12rem" }}
              sortable
            />
            <Column
              field="assign_name"
              header="affecter a"
              style={{ minWidth: "6rem" }}
              sortable
            />
            <Column
              field="affecter a"
              header="Etat tache"
              style={{ minWidth: "7rem" }}
              body={taskStatus}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default TachesSt;
