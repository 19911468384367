import React, { useContext, useEffect, useState } from "react";
import "./style/technicienStyle.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import TuneIcon from "@mui/icons-material/Tune";
import ClearIcon from "@mui/icons-material/Clear";
import DetailsModel from "./DetailsModel";
import NouvelleTâches from "./NouvelleTâches";
import { AuthContext } from "../../context/AuthContext";
import STcontext from "../../context/STcontext";
import { Tag } from "primereact/tag";
import pusher from "../../../pusher";
import ConfirmDeleteTask from "./ConfirmDeleteTask";
const TachesTechnicien = () => {
  const { getUser, USE } = useContext(AuthContext);
  const [technicianTasks, setTechnicianTasks] = useState([]);
  const [selectTasksBy, setSelectTasksBy] = useState({
    type: "normal",
    category: null,
    technician: null,
    city: null,
    state: null,
  });
  const {
    NewTask,
    supportTechiqueTechnicianTask,
    task,
    getTaskInfo,
    filteredData,
    handleSearchTechnicianTaskPerDateClicked,
    handleSearchTechnicianTaskPerDate,
    searchnInputTEchnicianPerDate,
    setSearchInputTechnicianPerDate,
    setDataFiltering,
    deleteTaskTechnicien,
    handleGetAllModeles,
    getAllGps,
    getAllSim,
    validateTasks,
  } = useContext(STcontext);

  useEffect(() => {
    getUser();
    getTaskInfo();
    getAllGps();
    getAllSim();
  }, []);

  useEffect(() => {
    supportTechiqueTechnicianTask();
    handleGetAllModeles();
    //connect to pusher
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      supportTechiqueTechnicianTask();
    });
    //end connect to pusher
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  useEffect(() => {
    //set ALL TASKS for ST AND gerant
    if (filteredData == null && task.length > 0 && USE.profil_id != 28) {
      filterTasksTechnicienByType(task);
    }
    //set search tasks for ST AND gerant
    else if (
      filteredData != null &&
      filteredData.length >= 0 &&
      USE.profil_id != 28
    ) {
      filterTasksTechnicienByType(filteredData);
    }
  }, [task, filteredData]);

  //filter Tasks for technical support by type normal, exceptional, automatic postponement or automatic manually
  const filterTasksTechnicienByType = (data) => {
    let filterDataTasks = [];
    if (selectTasksBy.type === "normal") {
      filterDataTasks = data?.filter(
        (technicianTask) =>
          technicianTask.user_id != technicianTask.uti_utilsateur_id &&
          technicianTask.etat_tache <= 2
      );
    }

    //filter Tasks technicien (task exceptional)
    else if (selectTasksBy.type == 0) {
      filterDataTasks = data?.filter(
        (technicianTask) =>
          technicianTask.user_id === technicianTask.uti_utilsateur_id
      );
    }
    //filter tasks technicien (automatically postponed)
    else if (selectTasksBy.type == 1) {
      filterDataTasks = data?.filter(
        (technicianTasks) =>
          technicianTasks.etat_tache == 3 &&
          technicianTasks.date_previsionnelle_debut >
            technicianTasks.date_previsionnelle_fin
      );
    }

    //filter tasks technicien (manual postponement)
    else if (selectTasksBy.type == 2) {
      filterDataTasks = data?.filter(
        (technicianTasks) =>
          technicianTasks.etat_tache == 3 &&
          technicianTasks.date_previsionnelle_fin == null
      );
    }
    // annuler
    else if (selectTasksBy.type == 3) {
      filterDataTasks = data?.filter(
        (technicianTasks) => technicianTasks.etat_tache === 4
      );
    } else {
      filterDataTasks = data ? data : "no data";
    }
    filterTasksTechnicienByCategorey(filterDataTasks);
  };

  const filterTasksTechnicienByCategorey = (params) => {
    if (selectTasksBy.category != null) {
      let typeOfTask = "";
      let positionOfDevice = "";
      switch (selectTasksBy.category) {
        case "2nd":
          typeOfTask = "installation";
          positionOfDevice = 1;
          break;
        default:
          typeOfTask = selectTasksBy.category;
          positionOfDevice = null;
          break;
      }
      let filterTasksByCategory = params?.filter(
        (task) => task.catache == typeOfTask && task.gps_principale == positionOfDevice || task.catache == typeOfTask
      );
      filterTasksByTechnician(filterTasksByCategory);
    } else {
      filterTasksByTechnician(params);
    }
  };

  const filterTasksByTechnician = (params) => {
    if (selectTasksBy.technician != null) {
      let filterTasksByTechnician = params?.filter(
        (task) => task.user_name === selectTasksBy.technician
      );
      filterTasksByCity(filterTasksByTechnician);
    } else {
      filterTasksByCity(params);
    }
  };

  const filterTasksByCity = (params) => {
    if (selectTasksBy.city != null) {
      let filterTasksByCity = params?.filter(
        (task) => task.cityName === selectTasksBy.city
      );
      filterTasksByState(filterTasksByCity);
    } else {
      filterTasksByState(params);
    }
  };

  const filterTasksByState = (params) => {
    if (selectTasksBy.state != null) {
      let state = "";
      let validation = "";
      let billed = "";
      switch (selectTasksBy.state) {
        case "waiting":
          state = false;
          validation = true;
          billed =false;
          break;
        case "inValidation":
          state = 2;
          validation = 2;
          billed = false;
          break;
        case "validated":
          state = 2;
          validation = true;
          billed = false;
          break;
        case "billed":
          state = 2;
          validation = true;
          billed = true;
          break;
      }
      let filterTasksByState = params?.filter(
        (task) => task.etat_tache == state && task.validation == validation && task.billed == billed
      );
      setTechnicianTasks(filterTasksByState);
    }
    else{
      setTechnicianTasks(params);

    }
  };

  useEffect(() => {
    if (filteredData != null && filteredData.length >= 0) {
      filterTasksTechnicienByType(filteredData);
    } else {
      filterTasksTechnicienByType(task);
    }
  }, [selectTasksBy]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    IMEI: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    SIM: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const clearFilterTaskTechnicien = () => {
    setDataFiltering(null);
    setSearchInputTechnicianPerDate({ startDate: "", endDate: "" });
    supportTechiqueTechnicianTask();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderMoreDetailComponent = (rowDate) => {
    return (
      <>
        <DetailsModel taskToUpdate={rowDate} />
      </>
    );
  };

  const renderTypeTask = (rowDate) => {
    switch (true) {
      case (rowDate.catache == "installation" && rowDate.gps_principale == 1):
        return "2ème Installation";
      default:
        return rowDate.catache;
    }
  };
  const renderEtatTask = (status, validation) => {
    switch (true) {
      case status == 0:
        return "E.Attente";
      case status === 1:
        return "En.Cours";
      case status === 2 && validation == 2:
        return "Terminée";
      case status === 2 && validation == 1:
        return "Validée";
      case status === 3:
        return "Reportée";
      case status === 4:
        return "Annulé";
    }
  };

  const getSeverityEtat = (status) => {
    switch (true) {
      case status === 0:
        return "info";
      case status === 1:
        return "success";
      case status === 2:
        return "success";
      case status === 3:
        return "warning";
      case status === 4:
        return "danger";
    }
  };
  const renderValidatingTask = (rowData) => {
    return (
      <div style={{padding:'10px'}}>
        <Form.Group
          as={Col}
        >
          
          <Form.Select
            disabled={rowData.etat_tache ==2 && USE.profil_id == 2 ? false:true}
            required
            type="text"
            name="type"
            onChange={(e) =>
              validateTasks(e, rowData.id)
            }
          >
            <option value="none" selected disabled hidden>
              {rowData.etat_tache ==2 && rowData.validation ==1 ?"Validée":"Non Validée"}
            </option>
            <option value="Valide">Validée</option>
            <option value="notValide">Non Validée</option>
          </Form.Select>
        </Form.Group>
      </div>
    );
  };

  const renderStatuTask = (rowData) => {
    return (
      <Tag
        value={renderEtatTask(rowData.etat_tache, rowData.validation)}
        severity={getSeverityEtat(rowData.etat_tache, rowData.validation)}
      />
    );
  };

  const renderActionTaskButton = (rowDate) => {
    return (
      <>
        <ConfirmDeleteTask
          message="Etes vous sûre de vouloir supprimer cette tâche?"
          id={rowDate.id}
          deleteFunction={(e) => deleteTaskTechnicien(e, rowDate.id)}
        />
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche par mot clé"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <div>
      <div className="bar-table-task-technicien">
        <div className="">
        {USE.profil_id == 2 ? (
            <NouvelleTâches />
          ) : (
            ""
          )}
          </div>
        <div className="filter-task-technicien">
          <div style={{ display: "flex", gap: "10px" }}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Control
                  type="date"
                  name="startDate"
                  value={searchnInputTEchnicianPerDate.startDate}
                  onChange={handleSearchTechnicianTaskPerDate}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Control
                  type="date"
                  name="endDate"
                  value={searchnInputTEchnicianPerDate.endDate}
                  onChange={handleSearchTechnicianTaskPerDate}
                  required
                />
              </Form.Group>
            </Row>

            <div className="filter-button-task-technicien">
              <Button
                onClick={(e) => handleSearchTechnicianTaskPerDateClicked(1)}
              >
                <TuneIcon />
                Filtrer
              </Button>
              <Button variant="danger" onClick={clearFilterTaskTechnicien}>
                {" "}
                <ClearIcon />
                Annule
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="tâches-tech-technicien">
        <div className="Table-tasks">
          <h3>
            Total Tâches &nbsp; <span class="vl"></span> &nbsp;
            <span style={{ fontSize: "15px" }}>
              {technicianTasks.length} tâches
            </span>
          </h3>

          {/* form filter tasks by type */}
          <Form>
            <Row>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="2"
                controlId="validationCustom01"
              >
                <Form.Label>sélectionner les tâches par: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="type"
                  onChange={(e) =>
                    setSelectTasksBy({
                      ...selectTasksBy,
                      technician: e.target.value || null,
                    })
                  }
                >
                  <option value="none" selected disabled hidden>
                    {/* {selectTasksBy.type} */}
                    tous les techniciens
                  </option>
                  <option disabled>sélectionner les tâches par</option>
                  <option value=""> tous les techniciens</option>
                  {NewTask.Users.filter(
                    (technician) => technician.profil_id === 1
                  ).map((technician) => (
                    <option key={technician.id}>{technician.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="2"
                controlId="validationCustom01"
              >
                <Form.Label>sélectionner les tâches par: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="type"
                  onChange={(e) =>
                    setSelectTasksBy({ ...selectTasksBy, type: e.target.value })
                  }
                >
                  <option value="none" selected disabled hidden>
                    {selectTasksBy.type}
                  </option>
                  <option disabled>sélectionner les tâches par</option>
                  <option value="all">toutes</option>
                  <option value="normal">normal</option>
                  <option value="0">exceptionnelle</option>
                  <option value="1">reporté automatiquement</option>
                  <option value="2">reporté manuelle</option>
                  <option value="3">Annuler</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="2"
                controlId="validationCustom01"
              >
                <Form.Label>sélectionner les tâches par: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="type"
                  onChange={(e) =>
                    setSelectTasksBy({
                      ...selectTasksBy,
                      category: e.target.value || null,
                    })
                  }
                >
                  <option value="none" selected disabled hidden>
                    Type
                  </option>
                  <option disabled>sélectionner les tâches par</option>
                  <option value="">Toutes les tâches</option>
                  <option value="installation">Installation</option>
                  <option value="2nd">2ème Installation</option>
                  <option value="installation gasoil">installation gasoil</option>
                  <option value="desinstalation">Désinstallation</option>
                  <option value="Reinstallation">Réinstallation</option>
                  <option value="Changement du SIM">Changement du SIM</option>
                  <option value="Changement du GPS">Changement du GPS</option>
                  <option value="reparationRelais">Réparation Relais</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="2"
                controlId="validationCustom01"
              >
                <Form.Label>sélectionner les tâches par: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="city"
                  onChange={(e) =>
                    setSelectTasksBy({
                      ...selectTasksBy,
                      city: e.target.value || null,
                    })
                  }
                >
                  <option value="none" selected disabled hidden>
                    Toutes les villes
                  </option>
                  <option disabled>les villes</option>
                  <option value="">Toutes les villes</option>
                  {NewTask &&
                    NewTask.Cities.map((city, index) => (
                      <option key={index} value={city.cityName}>
                        {city.cityName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="2"
                controlId="validationCustom01"
              >
                <Form.Label>sélectionner les tâches par: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="state"
                  onChange={(e) =>
                    setSelectTasksBy({
                      ...selectTasksBy,
                      state: e.target.value || null,
                    })
                  }
                >
                  <option value="none" selected disabled hidden>
                    Tous les États
                  </option>
                  <option disabled>États</option>
                  <option value="">Toutes</option>
                  <option value="waiting">En attende</option>
                  <option value="inValidation">Terminée</option>
                  <option value="validated">Validée</option>
                  <option hidden={USE.profil_id == 3 ?false:true} value="billed">Facturées</option>
                
                </Form.Select>
              </Form.Group>
            </Row>
          </Form>
          <div className="card">
          <DataTable
            value={technicianTasks}
            dataKey="id"
            header={header}
            globalFilterFields={["client_name", "IMEI", "SIM"]}
            filters={filters}
            removableSort
            paginator
            rows={15}
            rowsPerPageOptions={[15, 25, 50, 100]}
            tableStyle={{
              minWidth: "50rem",
              backgroundColor: "#F24405",
            }}
          >
            <Column
              header="#"
              headerStyle={{ width: "3rem" }}
              body={(data, options) => options.rowIndex + 1}
              bodyStyle={{ fontWeight: "bold" }}
            ></Column>

            <Column
              header="Details"
              body={renderMoreDetailComponent}
              style={{ width: "5%" }}
            ></Column>
            <Column
              field="client_name"
              header="Nom du client"
              sortable
              style={{ width: "21%", color: "#000" }}
              bodyStyle={{ fontWeight: "bold" }}
            ></Column>
            <Column
              field="catache"
              header="Type du tache"
              body={renderTypeTask}
              sortable
              style={{ width: "12%" }}
            ></Column>
            <Column
              field="date_previsionnelle_debut"
              header="D.de travail"
              sortable
              style={{ width: "13%" }}
            ></Column>
            <Column
              field="date_affectation"
              header="Date affectation"
              sortable
              style={{ width: "10%" }}
            ></Column>
            <Column
              field="matricule"
              header="Matricule"
              sortable
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="user_name"
              header="Exécuter par"
              sortable
              style={{ width: "10%" }}
            ></Column>
            <Column
              header="Validée"
              body={renderValidatingTask}
              style={{ width: "20%" }}
            ></Column>
            <Column
              header="Statut"
              field="etat_tache"
              body={renderStatuTask}
              style={{ width: "15%" }}
            ></Column>
            {USE.profil_id === 2 ? (
              <Column
                header="Action"
                body={renderActionTaskButton}
                style={{ width: "25%" }}
              ></Column>
            ) : (
              ""
            )}
          </DataTable>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default TachesTechnicien;
