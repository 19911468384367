import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import NouvelleTâches from "./NouvelleTâches";
import Button from "react-bootstrap/Button";
import DetailsModel from "./DetailsModel";
import TechnicianContext from "../../context/TechnicienContext";
import { SuccessMessage } from "../../errorSuccessNotification/SuccessMessage";

const pageSize = 8;

const TechTachesaconfirmer = () => {
  const { allTasksToConfirmed, getAllTaskToConfirmed } =
    useContext(TechnicianContext);
  useEffect(() => {
    getAllTaskToConfirmed();
  }, []);
  const [pages, setPages] = useState(1);
  const [rowsPrePage, setRowsPrepage] = useState(10);
  const lastTaskIndex = pages * rowsPrePage;
  const firstTaskIndex = lastTaskIndex - rowsPrePage;
  const currentTask = allTasksToConfirmed.slice(firstTaskIndex, lastTaskIndex);
  let numberOfPages = [];
  for (
    let p = 1;
    p <= Math.ceil(allTasksToConfirmed.length / rowsPrePage);
    p++
  ) {
    numberOfPages.push(p);
  }

 
  return (
    <>
      <div className="allContent">
        <SuccessMessage />
        <div className="bar-table">
          <div className="">
            <NouvelleTâches />
          </div>
        </div>
        <div>
          <div className="Table-tech" style={{ padding: "2rem" }}>
            <div className="p-datatable p-datatable-header "></div>
            <div>
              <h5>
                Clients &nbsp; <span class="vl"></span> &nbsp;{" "}
                <span style={{ fontSize: "15px" }}>
                  {" "}
                  {allTasksToConfirmed.length} clients
                </span>{" "}
              </h5>
            </div>
            <MDBTable align="middle">
              <MDBTableHead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Nom du client</th>
                  <th scope="col" className="versionmobile">
                    Type du taches
                  </th>
                  <th scope="col" className="versionmobile">
                    date_previsionnelle_debut
                  </th>
                  <th scope="col">Status</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {currentTask
                  ? currentTask.map((TASK) => (
                      <tr key={TASK.id}>
                        <td>
                          <DetailsModel data={TASK} />
                        </td>
                        <td className="fw-bold mb-1">{TASK.client_name}</td>

                        <td className="versionmobile">
                          <p className="fw-normal mb-1">{TASK.catache}</p>
                        </td>
                        <td className="versionmobile">
                          <p className="fw-normal mb-1">
                            {TASK.date_previsionnelle_debut}
                          </p>
                        </td>
                        <td className="">
                          <Button style={{ width: "60%" }}>
                            {TASK.validation == 1
                              ? "Confirmée"
                              : "en validation"}
                          </Button>
                        </td>
                      </tr>
                    ))
                  : "Aucun résultat trouvé"}
              </MDBTableBody>
            </MDBTable>
            {allTasksToConfirmed.length > 0 ? (
              <nav aria-label="...">
                <MDBPagination className="mb-0 d-flex justify-content-end">
                  <MDBPaginationItem disabled>
                    <MDBPaginationLink
                      href="#"
                      tabIndex={-1}
                      aria-disabled="true"
                    >
                      Pages
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                  {numberOfPages.map((numberPage, index) => (
                    <MDBPaginationItem
                      className={`${numberPage == pages ? "active" : "null"}`}
                    >
                      <MDBPaginationLink
                        style={{ cursor: "pointer" }}
                        onClick={() => setPages(numberPage)}
                      >
                        {numberPage}
                      </MDBPaginationLink>
                    </MDBPaginationItem>
                  ))}
                </MDBPagination>
              </nav>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TechTachesaconfirmer;
