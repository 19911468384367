import _ from "lodash";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Form from "react-bootstrap/Form";
import { ConfirmationEndroitAppareil } from "./ConfirmationEndroitAppareil";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import STcontext from "../../../context/STcontext";
import pusher from "../../../../pusher";
import ComptableContext from "../../../context/ComptableContext";

const Simretournées = () => {
  const {
    confirmeAcceptanceOfTheDevice,
    getReturnedDevices,
    apprareilReturned,
  } = useContext(STcontext);
  const { alltype, getAllTypes } = useContext(ComptableContext);
  const [Modeles, setModeles] = useState([]);
  useEffect(() => {
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getReturnedDevices();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);
  const Navigate = useNavigate();
  const getAllModeles = () => {
    setModeles(alltype.map((sim) => sim.nameModele));
  };
  useEffect(() => {
    getReturnedDevices();
    getAllTypes();
  }, []);
  useEffect(() => {
    getAllModeles();
  }, [alltype]);
  const handleSelectChange = (e) => {
    e.preventDefault();
    const etatTache = e.target.value;
    if (etatTache == 0) {
      Navigate("/supporttechnique/appareilretournées/GPS");
    }
  };
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();
  const renderComfirmeSim = (rowDate) => {
    return (
      <>
        <ConfirmationEndroitAppareil
          data={rowDate}
          ConfirnationFunction={confirmeAcceptanceOfTheDevice}
          typeDevice="SIM"
          Model={Modeles}
        />
      </>
    );
  };

  const renderDateReturn = (rowDate) => {
    return formatDate(rowDate.created_at);
  };

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <div style={{ padding: "20px 50px 30px 50px" }}>
      <div className="Table-tech" style={{ padding: "2rem" }}>
        <div className="bar-table" style={{ padding: "10px 30px 10px 30px" }}>
          <div>
            <h5>
              SIM retournées &nbsp; <span class="vl"></span> &nbsp;
              <span style={{ fontSize: "15px" }}>
                {apprareilReturned && apprareilReturned.getAllSIM.length} sim
              </span>
            </h5>
          </div>
          <div class="search input-group rounded">
            <Form.Select
              onChange={(e) => handleSelectChange(e)}
              className="tâches-statut"
              aria-label="Default select example"
            >
              <option value="" selected disabled hidden>
                Veuillez choisir un option
              </option>
              <option value="" disabled>
                Veuillez choisir un option
              </option>
              <option value={0}>GPS retournée </option>
              <option value={1}>Sim retournées</option>
            </Form.Select>
          </div>
        </div>
        <DataTable
          value={apprareilReturned.getAllSIM}
          header={header}
          filters={filters}
          removableSort
          paginator
          rows={15}
          rowsPerPageOptions={[15, 25, 50, 100]}
          tableStyle={{
            minWidth: "50rem",
            backgroundColor: "#F24405",
          }}
        >
          <Column
            header="#"
            headerStyle={{ width: "3rem" }}
            body={(data, options) => options.rowIndex + 1}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            field="serial_number"
            header="N° SIM"
            sortable
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="nameModele"
            header="Type d'appareil"
            sortable
            style={{ width: "10%" }}
          ></Column>
          <Column
            field="created_at"
            header="Date retour"
            body={renderDateReturn}
            sortable
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="name"
            header="Retourne par"
            sortable
            style={{ width: "25%" }}
          ></Column>
          <Column
            header="Confirmer"
            sortable
            body={renderComfirmeSim}
            style={{ width: "25%", padding: "10px" }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Simretournées;
