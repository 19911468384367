import React, { useContext, useState } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechnicianContext from "../context/TechnicienContext";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { ShowImages } from "./ShowImages";

export default function RéparationSim() {
  //grab the id and stat from the url redirecting you this page
  const { id, statu } = useParams();
  //grab the states ,and functions related to reparationSIM
  const {
    reparationSIM,
    handleReparationSim,
    ReparationSimSubmit,
    setReparationSIM,
    getAppareil,
    appareil,
    handleFileEvent,
    getTasksDataForReparation,
    reparationTaskData,
    errorMessage,
    clientInfoTask,
  } = useContext(TechnicianContext);

  useEffect(() => {
    getTasksDataForReparation(id);
    getAppareil();
  }, []);

  //useEffect hook to set the property "etatTache" of reparationSIM state with the value 2 which is related to a terminated task state in the backend

  // useEffect(() => {
  //   if (reparationSIM.etatTache == "") {
  //     setReparationSIM({ ...reparationSIM, etatTache: "2" });
  //   }
  // }, [reparationSIM]);

  const [vehiculeInfo, setVehiculeInfo] = useState(null);

  const hanleFilterByDevice = async (e) => {
    const getIdVehicule = await reparationTaskData?.allDeviceInstalled.filter(
      (idVehicule) => idVehicule.serial_number === e.target.value
    );
    if (getIdVehicule.length > 0) {
      setVehiculeInfo(...getIdVehicule);
    } else {
      setVehiculeInfo(null);
    }
  };

  useEffect(() => {
    if (vehiculeInfo != null) {
      const vehicule = reparationTaskData.vehicules.filter(
        (articleInfo) => articleInfo.id === vehiculeInfo.vehicule_id
      )[0];
      setReparationSIM({
        ...reparationSIM,
        AncienSIM: vehiculeInfo
          ? vehiculeInfo.latest_sim_relating.map(
              (simCombination) => simCombination.serial_number
            )[0]
          : "",
        Matricule: vehicule ? vehicule.matricule : "",
        IMEI: vehiculeInfo ? vehiculeInfo.serial_number : "",
      });
    } else {
      setReparationSIM(null);
    }
  }, [vehiculeInfo]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "1rem",
        }}
      >
        <MDBContainer>
          <MDBCardHeader>
            <h5> Changement SIM</h5>
          </MDBCardHeader>
          <hr></hr>

          <Form noValidate>
            <Row className="mb-4">
              {/* Nom client */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Nom Client : </Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  name="nomComplet"
                  value={clientInfoTask.client_name}
                  placeholder="Nom client"
                />
              </Form.Group>
              {/* Ancienne IMEI*/}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>IMEI : </Form.Label>
                <Form.Control
                  list="IMEIList"
                  name="IMEI"
                  placeholder="IMEI"

                  autoComplete="off"
                  onChange={(e) => hanleFilterByDevice(e)}
                />
                <datalist id="IMEIList">
                  {reparationTaskData?.allDeviceInstalled.map((oldDevice) => (
                    <option key={oldDevice.id} id={oldDevice.id}>
                      {oldDevice.serial_number}
                    </option>
                  ))}
                </datalist>
              </Form.Group>
              {/* matricule */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Matricule : </Form.Label>
                <Form.Control
                  list="matricules"
                  type="text"
                  name="Matricule"
                  autoComplete="off"
                  onChange={handleReparationSim}
                  placeholder="Matricule"
                  value={reparationSIM ? reparationSIM.Matricule : ""}
                />
                <span style={{ color: "red" }}>
                  {errorMessage && errorMessage.Matricule
                    ? errorMessage.Matricule[0]
                    : ""}
                </span>
              </Form.Group>
              {/* Ancienne Carte SIM */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>N ancienne carte SIM : </Form.Label>
                <Form.Control
                  list="AncienSIM"
                  name="AncienSIM"
                  placeholder="sim number"

                  autoComplete="off"
                  onChange={handleReparationSim}
                  value={reparationSIM ? reparationSIM.AncienSIM : ""}
                />
              </Form.Group>
            </Row>
            <Row className="mb-4">
              {/* Nouvelle Carte SIM  */}

              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>nouvelle carte SIM : </Form.Label>
                <Form.Control
                  list="newSIMNumber"
                  name="NouveauSim"
                  autoComplete="off"
                  placeholder="Nouveau Sim"
                  onChange={handleReparationSim}
                />

                <datalist id="newSIMNumber">
                  {appareil.SIM.map((sim, index) => (
                    <option value={sim.serial_number} key={index}>
                      {sim.latest_device_relating.length > 0
                        ? sim.latest_device_relating.map(
                            (deviceRelating) => deviceRelating.serial_number
                          )[0]
                        : ""}
                    </option>
                  ))}
                </datalist>
                <span style={{ color: "red" }}>
                  {errorMessage && errorMessage.NouveauSim
                    ? errorMessage.NouveauSim[0]
                    : ""}
                </span>
              </Form.Group>
              {/*images*/}
              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Images : </Form.Label>
                <Form.Control
                  required
                  name="imagesTask"
                  multiple
                  type="file"
                  accept="application/pdf,image/jpeg,image/png"
                  onChange={(e) => handleFileEvent(e, statu)}
                />
                <span style={{ color: "red" }}>
                  {errorMessage && errorMessage.images
                    ? errorMessage.images[0]
                    : ""}
                </span>
              </Form.Group>
              <ShowImages />
            </Row>

            <Row className="mb-4">
              <Form.Group as={Col} md="9" controlId="validationCustom04">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  style={{ height: "10rem" }}
                  as="textarea"
                  name="Observation"
                  required
                  type="file"
                  value={reparationSIM?.Observation}
                  onChange={handleReparationSim}
                />
              </Form.Group>
            </Row>

            <Row md="6" className="mt-4 justify-content-center">
              <Button onClick={() => ReparationSimSubmit(id)} variant="primary">
                Envoyer
              </Button>
            </Row>
          </Form>
        </MDBContainer>
      </div>
    </div>
  );
}
