import React, { useState } from "react";
import "../../../App.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import SIM from "./SGA/Sim";
import Gps from "./SGA/Gps";
import Accessoires from "./SGA/Accessoires";
import { SuccessMessage } from "../../errorSuccessNotification/SuccessMessage";
export default function AppCards() {
  const [table, setTable] = useState();
  const handleTable = (e) => {
    const { id } = e.target;
    if (id == 1) {
      setTable(<Gps />);
    } else if (id == 2) {
      setTable(<SIM />);
    } else if (id == 3) {
      setTable(<Accessoires />);
    }
  };
  return (
    <>
      <SuccessMessage />
      <MDBRow className="AppCard ">
        <MDBCol>
          <MDBCard className="cardStats">
            <MDBCardBody>
              <MDBCardTitle>GPS </MDBCardTitle>
              <div className="pointer" id="1" onClick={(e) => handleTable(e)}>
                details
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard className="cardStats  ">
            <MDBCardBody>
              <MDBCardTitle>SIM</MDBCardTitle>
              <div className="pointer" id="2" onClick={(e) => handleTable(e)}>
                details
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard className="cardStats  ">
            <MDBCardBody>
              <MDBCardTitle>Accessoires</MDBCardTitle>
              <div className="pointer" id="3" onClick={(e) => handleTable(e)}>
                details
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <div>{table}</div>
    </>
  );
}
