import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CommercialContext from "../context/CommercialContext";

const UpdateTaskToConfirm = ({ taskToUpdate }) => {
  const { NewTask, UpdateTaskToConfirmSubmti, task } =
    useContext(CommercialContext);
  const [UpdateTask, setUpdateTask] = useState({
    id: taskToUpdate.id,
    typeTask: taskToUpdate.cataches.catache,
    nameClient: taskToUpdate.clients.nomComplet,
    phoneNumber: taskToUpdate.clients.telephone_client,
    email: taskToUpdate.clients.email_client,
    estimatedStartDate: taskToUpdate.date_previsionnelle_debut,
    observation: taskToUpdate.observation,
    city: taskToUpdate.city != null && taskToUpdate.city.cityName,
  });
  const [newDataTask, setNewDataTask] = useState({});

  useEffect(() => {
    setModalShow(false);
  }, [task]);

  const [modalShow, setModalShow] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUpdateTask({ ...UpdateTask, [name]: value });
    setNewDataTask({ ...newDataTask, [name]: value });
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "start", padding: "25px" }}
      >
        <Button variant="primary" onClick={() => setModalShow(true)}>
          <BorderColorIcon />
        </Button>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            modifier tache
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            method="POST"
            onSubmit={(e) =>
              UpdateTaskToConfirmSubmti(e, UpdateTask.id, newDataTask)
            }
          >
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Nom complet : </Form.Label>
                <Form.Control
                  required
                  type="list"
                  autoComplete="off"
                  name="nameClient"
                  placeholder="Nom complet"
                  value={UpdateTask.nameClient}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Telephone: </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="+212 555555"
                  name="phoneNumber"
                  value={UpdateTask.phoneNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Email : </Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  placeholder="example@example.com"
                  name="email"
                  value={UpdateTask.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Type tâche: </Form.Label>
                <Form.Select
                  name="typeTaskName"
                  autoComplete="off"
                  onChange={handleChange}
                >
                  <option selected hidden>
                    {UpdateTask.typeTask}
                  </option>
                  <option disabled>select type </option>
                  {NewTask.TypesTasks.map((TypesTask) => (
                    <option key={TypesTask.id} data-value={TypesTask.id}>
                      {TypesTask.catache}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Date :</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="estimatedStartDate"
                  autoComplete="off"
                  value={UpdateTask.estimatedStartDate}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="">
                <Form.Label>réalisée à: </Form.Label>
                <Form.Select
                  name="clientCity"
                  autoComplete="off"
                  onChange={handleChange}
                >
                  <option selected hidden>
                    {UpdateTask.city || "select ville"}
                  </option>
                  <option disabled>select ville</option>
                  {NewTask &&
                    NewTask.Cities.map((city, index) => (
                      <option key={index} value={city.id}>
                        {city.cityName}
                      </option>
                    ))}
                </Form.Select>
                {/* <span style={{ color: "red" }}>
                  {error && error.clientCity ? error.clientCity[0] : ""}
                </span> */}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="">
                <Form.Label>Observation: </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="observation"
                  autoComplete="off"
                  value={UpdateTask.observation}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Modal.Footer>
              <Button type="submit" className="Modelbutton" variant="primary">
                Enregistrer les modifications
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateTaskToConfirm;
