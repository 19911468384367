import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ComptableContext from "../../context/ComptableContext";
import { useEffect } from "react";
export const AddNewCustomer = () => {
  const {
    submitAddNewCustomer,
    handleAddNewCustomer,
    allClients,
    errorMessage,
    setErrorMessage,
    allCities,
  } = useContext(ComptableContext);
  useEffect(() => {
    handleClose();
  }, [allClients]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setErrorMessage(null);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <div>
      <Button
        className="nouveauProfilButton"
        style={{ width: "100%" }}
        onClick={handleShow}
      >
        Nouveau Client
      </Button>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter nouveau client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => submitAddNewCustomer(e)}>
            <Form.Group className="mb-3">
              <Form.Label>Nom Complet:</Form.Label>
              <Form.Control
                name="nomComplet"
                type="text"
                placeholder="Nom Complet"
                autoComplete="off"
                onChange={handleAddNewCustomer}
              />
              {errorMessage && errorMessage.nomComplet ? (
                <span style={{ color: "red" }}>
                  {errorMessage.nomComplet[0]}
                </span>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Telephone:</Form.Label>
              <Form.Control
                type="text"
                name="telephone_client"
                placeholder="+212 *********"
                autoComplete="off"
                onChange={handleAddNewCustomer}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email_client"
                placeholder="name@example.com"
                autoComplete="off"
                onChange={handleAddNewCustomer}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Ville:</Form.Label>
              <Form.Select
                type="text"
                name="cityName"
                placeholder="name@example.com"
                autoComplete="off"
                onChange={handleAddNewCustomer}
              >
                <option selected disabled>
                  Select ville
                </option>
                {allCities &&
                  allCities.map((city, index) => (
                    <option value={city.id} key={index}>
                      {city.cityName}
                    </option>
                  ))}
              </Form.Select>
              {errorMessage && errorMessage.cityName ? (
                <span style={{ color: "red" }}>{errorMessage.cityName[0]}</span>
              ) : (
                ""
              )}
            </Form.Group>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Ajouter Client
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
