import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import "./techstyle.css";
import Form from "react-bootstrap/Form";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import TaskExceptionnel from "./TaskExceptionnel";
import UpdateTask from "./UpdateTask";
import axios from "../context/axios";
import TechnicianContext from "../context/TechnicienContext";
import pusher from "../../pusher";
import { MoreDetail } from "./MoreDetail";
import Filterage from "./filtrage/Filterage";

const TehcTâches = () => {
  //getting all the technician Task and all the logic related to the technician profiles including states and functions from the techni context
  const {
    geTaskTechnician,
    taskTechnicien,
    getAppareil,
    getAllUsers,
    setClientInfoTask,
  } = useContext(TechnicianContext);
  //from the commercial contet get functions related to the search logic
  const Navigate = useNavigate();

  useEffect(() => {
    geTaskTechnician();
    getAppareil();
    getAllUsers();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      geTaskTechnician();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const [pages, setPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const lastTaskIndex = pages * rowsPerPage;
  const firstTaskIndex = lastTaskIndex - rowsPerPage;
  const [filterTasks, setFilterTasks] = useState([]);
  const currentTask = filterTasks.slice(firstTaskIndex, lastTaskIndex);

  let numberPages = [];
  for (let p = 1; p <= Math.ceil(filterTasks.length / rowsPerPage); p++) {
    numberPages.push(p);
  }
  const [details, setDetails] = useState(null);
  //function that store the data of each paginated item and put it inside the details state
  const handlePopsUpDetails = (data) => {
    setDetails(data);
  };

  const handleStateChange = (newData) => {
    setFilterTasks(newData);
    setPages(1);
  };

  //this is the logic behind triggering the redirecting to an other component depend on the state of the task ( terminé/reporée/annulé,etc...)
  const handleSelectChange = (e, TASK) => {
    e.preventDefault();
    const etatTache = e.target.value;
    setClientInfoTask(TASK);
    if (etatTache == 1) {
      axios.get(`/api/technicien/StartedTask/${TASK.id}`);
    }
    if (TASK.catache == "installation" || TASK.catache =="installation gasoil" && etatTache == 2) {
      Navigate(`/termineInstallation/${TASK.id}/${TASK.catache}`);
    } else if (TASK.catache == "Reinstallation" && etatTache == 2) {
      Navigate(`/termineRéinstallation/${TASK.id}/${TASK.catache}`);
    } else if (TASK.catache == "desinstalation" && etatTache == 2) {
      Navigate(`/termineDesinstalation/${TASK.id}/${TASK.catache}`);
    } else if (TASK.catache == "Changement du GPS" && etatTache == 2) {
      Navigate(`/termineReparationGPS/${TASK.id}/${TASK.catache}`);
    } else if (TASK.catache == "Changement du SIM" && etatTache == 2) {
      Navigate(`/termineReparationSIM/${TASK.id}/${TASK.catache}`);
    } else if (TASK.catache == "reparationRelais" && etatTache == 2) {
      Navigate(`/termineReparationRelais/${TASK.id}/${TASK.catache}`);
    } else if (TASK.catache == "Panne" && etatTache == 2) {
      Navigate(`/terminePanne/${TASK.id}/${TASK.catache}`);
    } else if (etatTache == 3) {
      Navigate(`/Reportée/${TASK.id}`);
    } else if (etatTache == 4) {
      Navigate(`/Annulée/${TASK.id}`);
    }
  };

  const bodyStatus = (TASK) => {
    return (
      <>
        <Form.Select
          size="sm"
          onChange={(e) => handleSelectChange(e, TASK)}
          className="tâches-statut"
          aria-label="Default select example"
        >
          <option value={TASK.etat_tache} hidden>
            {TASK.etat_tache == 0
              ? "E.attente"
              : TASK.etat_tache == 1
              ? "E.cours"
              : TASK.etat_tache == 2
              ? "Terminée"
              : TASK.etat_tache == 3
              ? "Reportée"
              : "Annulée"}
          </option>
          <option disabled>select statu</option>
          <option value={1}>en cours</option>
          <option value={2}>terminé</option>
          <option value={3}>reporté</option>
          <option value={4}>annulé</option>
        </Form.Select>
      </>
    );
  };

  return (
    <div className="allpage">
      <div className="bar-table">
        <div className="">
          <TaskExceptionnel />
        </div>
      </div>

      <div className="tâches-tech-container">
        <div className="Table-tech" style={{ padding: "2rem" }}>
          <div style={{ paddingBottom: "1rem" }}>
            <h5>
              List De Tâches &nbsp; <span className="vl"></span> &nbsp;{" "}
              <span style={{ fontSize: "15px" }}>
                {" "}
                {filterTasks.length} tâches
              </span>{" "}
            </h5>
            <Filterage onStateChange={handleStateChange} />
          </div>
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col">Plus Details</th>
                <th scope="col">Nom du client</th>
                <th scope="col">tâche</th>
                <th scope="col">Date</th>
                <th scope="col">ville</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {currentTask.map((TASK) => (
                <tr key={TASK.id}>
                  <td>
                    <button
                      onClick={() => handlePopsUpDetails(TASK)}
                      style={{ border: "none", background: "none" }}
                    >
                      <MoreDetail moreDetail={TASK} />
                    </button>
                  </td>
                  <td>
                    <p className="fw-bold mb-1">{TASK.client_name}</p>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">{TASK.catache}</p>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">
                      {TASK.date_previsionnelle_debut}
                    </p>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">
                      {TASK.cityName || "aucune ville"}
                    </p>
                  </td>
                  <td className="">{bodyStatus(TASK)}</td>
                  <td>
                    <button style={{ border: "none", background: "none" }}>
                      <UpdateTask data={TASK} />
                    </button>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
          {currentTask.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberPages.map((numberPage, index) => (
                  <MDBPaginationItem
                    key={index}
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPages(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            "Aucun résultat trouvé"
          )}
        </div>
      </div>
    </div>
  );
};

export default TehcTâches;
