import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const Updateapp = ({ data, updateFunction }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [updateDeice, setUpdateDevice] = useState({
    appareil_id: data.id,
    newNumberBox: data.numberBox,
    newSerialnumber: data.serial_number,
  });

  const handlechangeUpdateDevice = (e) => {
    const { name, value } = e.target;
    setUpdateDevice({ ...updateDeice, [name]: value });
  };
  useEffect(() => {
    handleClose();
  }, [data]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          padding: "25px 5px",
        }}
      >
        <Button variant="primary" onClick={handleShow}>
          <BorderColorIcon />
        </Button>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier Appareil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            method="POST"
            onSubmit={(e) => updateFunction(e, updateDeice)}
          >
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>N° Box:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="newNumberBox"
                  autoComplete="off"
                  value={updateDeice ? updateDeice.newNumberBox : ""}
                  onChange={handlechangeUpdateDevice}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Serial_number : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="newSerialnumber"
                  autoComplete="off"
                  value={updateDeice ? updateDeice.newSerialnumber : ""}
                  onChange={handlechangeUpdateDevice}
                />
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button type="submit" variant="primary">
                Modifier
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Updateapp;
