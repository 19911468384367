import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import ComptableContext from "../../../../context/ComptableContext";
import axios from "../../../../context/axios";

const AccessoireModel = ({id}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navTo = useNavigate();
    const {getTypesacc} = useContext(ComptableContext)
    const [AccessoireModel,SetAccessoireMode]=useState({
      appareil_id:id,
      nameType:""
    })
    // console.log(id)
    const handleGpsModel = (e)=>{
    e.preventDefault();
    // console.log("hello accessoire")
    
    const{name,value}= e.target
    SetAccessoireMode({...AccessoireModel,[name]:value})
    
    }
    const addTypeSubmit = async(e)=> {
      e.preventDefault();
        await axios.post('/api/comptable/addnewType', AccessoireModel)
        getTypesacc();
    }
    return (
        <div>
          <div  style={{ display:"flex" , justifyContent:"end", padding:'25px' }}>
     <Button variant="primary" onClick={handleShow}>
            Nouveau Type
          </Button>
          </div>
         
    
          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Nouveau type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate method="POST" onSubmit={addTypeSubmit} >
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Nom  : </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="nameType"
                      placeholder="Type "
                      onChange={handleGpsModel}
                    />
                  </Form.Group>
                 
                </Row>
               
          
    
                <Modal.Footer>
                  <Button type="submit" variant="primary" onClick={handleClose}>
                    Envoyer
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      );
}

export default AccessoireModel