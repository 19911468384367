import React, { useContext, useEffect } from "react";
import { MDBCardHeader, MDBContainer } from "mdb-react-ui-kit";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import STcontext from "../../context/STcontext";

export const CompleteTask = ({ detailsTask }) => {
  const {
    handleEndTaskST,
    completTaskSubmit,
    getAllVehiclesPreClient,
    vehiclesPreClient,
  } = useContext(STcontext);

  useEffect(() => {
    getAllVehiclesPreClient(detailsTask.client_id);
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "1rem",
        }}
      >
        <MDBContainer>
          <MDBCardHeader>
            <h5> {detailsTask.catache}</h5>
          </MDBCardHeader>
          <hr></hr>

          <Form noValidate id="myForm" encType="multipart/form-data">
            <Row className="mb-4">
              {/* Nom client */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Nom Client : </Form.Label>
                <Form.Control
                  name="nomComplet"
                  disabled
                  required
                  type="text"
                  value={detailsTask.client_name}
                />
              </Form.Group>

              {/* Matricule */}

              <Form.Group as={Col} md="3" controlId="validationCustom04">
                <Form.Label>Matricule : </Form.Label>
                <Form.Control
                  list="matriculesList"
                  required
                  name="Matricule"
                  type="text"
                  autoComplete="off"
                  placeholder="Veuillez saisir la matricule"
                  onChange={handleEndTaskST}
                />
                <datalist id="matriculesList">
                  {vehiclesPreClient?.map((vehicule) => (
                    <option>{vehicule.matricule}</option>
                  ))}
                </datalist>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col} md="9" controlId="validationCustom04">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  style={{ height: "10rem" }}
                  as="textarea"
                  name="Observation"
                  onChange={handleEndTaskST}
                  required
                />
              </Form.Group>
            </Row>
            <Row md="6" className="mt-4 justify-content-center">
              <Button
                onClick={() => completTaskSubmit(detailsTask.tache_id)}
                variant="primary"
                id="submitBtn"
                name="submitBtn"
              >
                terminer tâche
              </Button>
            </Row>
          </Form>
        </MDBContainer>
      </div>
    </div>
  );
};
