import React, { useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import STcontext from "../../../context/STcontext";
export const ConfirmationEndroitAppareil = ({
  data,
  ConfirnationFunction,
  typeDevice,
}) => {
  const {
    handleChangeEndroitAppareils,
    changeEndroitAppareils,
    responseMessage,
    setResponseMessage,
    modeles,
    setchangeEndroitAppareils,
    errorsMessages,
  } = useContext(STcontext);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => {
    setModalShow(false);
    setResponseMessage(null);
  };
  const handleShow = () => {
    setchangeEndroitAppareils({ modeleDevice: data.nameModele });
    setModalShow(true);
  };

  useEffect(() => {
    if (responseMessage === 200) {
      handleClose();
    }
  });

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        confirmer
      </Button>

      <Modal
        show={modalShow}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Appareil Retourné:{" "}
            <span style={{ fontSize: "20px", fontWeight: "normal" }}>
              {data.serial_number}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form
              onSubmit={(e) =>
                ConfirnationFunction(e, data.id, changeEndroitAppareils)
              }
            >
              <Row>
                <Form.Group
                  className="mb-3 mx-2"
                  as={Col}
                  controlId="validationCustom01"
                >
                  <Form.Label>
                    édit model appareil &nbsp;
                    <span className="star-Mandatory-field">*</span> &nbsp;:
                  </Form.Label>
                  <Form.Select
                    required
                    type="text"
                    name="modeleDevice"
                    onChange={handleChangeEndroitAppareils}
                    value={
                      changeEndroitAppareils.modeleDevice !== null
                        ? changeEndroitAppareils.modeleDevice
                        : "default"
                    }
                  >
                    <option value="default" disabled hidden>
                      Select model
                    </option>
                    {modeles &&
                      modeles.map((model) => (
                        <option key={model.id}>{model.nameModele}</option>
                      ))}
                  </Form.Select>
                  {errorsMessages && errorsMessages.modeleDevice ? (
                    <span style={{ color: "red" }}>
                      {errorsMessages.modeleDevice[0]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className="mb-3 mx-2"
                  as={Col}
                  controlId="validationCustom01"
                >
                  <Form.Label>
                    select endroit &nbsp;
                    <span className="star-Mandatory-field">*</span> &nbsp;:{" "}
                  </Form.Label>
                  <Form.Select
                    required
                    type="text"
                    name="etat"
                    onChange={handleChangeEndroitAppareils}
                  >
                    <option selected disabled>
                      select endroit
                    </option>
                    {typeDevice === "SIM" ? (
                      <>
                        <option value="0">Nouveaux SIM</option>
                        <option value="3">Ancien SIM</option>
                      </>
                    ) : (
                      <>
                        <option value="0">Nouveaux Appareils</option>
                        <option value="1">Appareils Client</option>
                        <option value="2">Appareils Bon Occasion</option>
                        <option value="3">Appareils En Damage</option>
                        <option value="0">Nouveaux SIM</option>
                        <option value="3">Ancien SIM</option>
                      </>
                    )}
                  </Form.Select>

                  {errorsMessages && errorsMessages.etat ? (
                    <span style={{ color: "red" }}>
                      {errorsMessages.etat[0]}
                    </span>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3 mx-2">
                  <Form.Label>Obsirvation:</Form.Label>
                  <Form.Control
                    name="obsirvation"
                    onChange={handleChangeEndroitAppareils}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
              </Row>
              <Modal.Footer>
                <Button type="submit">confirmer</Button>
              </Modal.Footer>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
