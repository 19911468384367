import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import ComptableContext from "../../../../context/ComptableContext";
export default function SimModel({ id }) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleclose = () => setShow(false);
  const navTo = useNavigate();
  const { handleSIMModel, addTypeSubmit } = useContext(ComptableContext);

  useEffect(() => {
    try {
      addTypeSubmit();
      handleclose();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "end", padding: "25px" }}>
        <Button variant="primary" onClick={handleShow}>
          Nouveau Type
        </Button>
      </div>

      <Modal size="lg" show={show} onHide={handleclose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouveau type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={addTypeSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Nom type: </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="nameType"
                  placeholder="Nom Type "
                  onChange={handleSIMModel}
                />
              </Form.Group>
            </Row>

            <Modal.Footer>
              <Button type="submit" variant="primary" onClick={handleclose}>
                Envoyer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
