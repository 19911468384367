import React, { useContext, useEffect, useState } from "react";
import "./style/technicienStyle.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import TuneIcon from "@mui/icons-material/Tune";
import ClearIcon from "@mui/icons-material/Clear";
import DetailsModel from "./DetailsModel";
import NouvelleTâches from "./NouvelleTâches";
import { AuthContext } from "../../context/AuthContext";
import STcontext from "../../context/STcontext";
import ComptableContext from "../../context/ComptableContext";
import pusher from "../../../pusher";
const ComptableTasksTechnicien = () => {
  const {
    handleChangeEtatBilled,
    getAllTerminedTasks,
    task,
    handleSearchTechnicianTaskPerDate,
    handleSearchTechnicianTaskPerDateClicked,
    filteredData,
    filterByDate,
    setSearchInputTechnicianPerDate,
    setDataFiltering,
    getAllCities,
    allCities,
  } = useContext(ComptableContext);
  const { USE } = useContext(AuthContext);

  //connect to pusher
  useEffect(() => {
    getAllTerminedTasks();
    getAllCities();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAllTerminedTasks();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  //end connect to pusher

  const [technicianTasks, setTechnicianTasks] = useState([]);
  const [selectTasksBy, setSelectTasksBy] = useState({
    category: null,
    city: null,
    state: null,
  });

  useEffect(() => {
    //set ALL TASKS for ST AND gerant
    if (filteredData == null && task.length > 0) {
      filterTasksTechnicienByType(task);
    }
    //set search tasks for ST AND gerant
    else if (filteredData != null && filteredData.length >= 0) {
      // setTechnicianTasks(
      //   filteredData.filter((technicianTask) => )
      // );
      filterTasksTechnicienByType(filteredData);
    }
  }, [task, filteredData]);

  //filter Tasks for technical support by type normal, exceptional, automatic postponement or automatic manually
  const filterTasksTechnicienByType = (data) => {
    setTechnicianTasks([]);
    let filterDataTasks = [];
    if (selectTasksBy.category != null) {
      filterDataTasks = data?.filter(
        (task) => task.catache === selectTasksBy.category
      );
      filterTechnicianTasksByCitiy(filterDataTasks)
    } else {
      filterTechnicianTasksByCitiy(data);
    }
  };

  const filterTechnicianTasksByCitiy = (newData)=>{
    let filterDataTasks = [];
    if(selectTasksBy.city !=null){
      filterDataTasks = newData?.filter((task)=>task.cityName ===selectTasksBy.city);
      filterTechnicianTasksByState(filterDataTasks);
    }
    else{
      filterTechnicianTasksByState(newData);
    }
  }

  const filterTechnicianTasksByState = (newData)=>{
    let filterDataTasks = [];
    if(selectTasksBy.state != null){
      let billed = "";
      switch(selectTasksBy.state){
        case "billed":
        billed = true;
        break;
      case "waiting":
        billed = false;
      }
      filterDataTasks = newData?.filter((task)=>task.billed == billed);
      setTechnicianTasks(filterDataTasks);
    }
    else{
      setTechnicianTasks(newData);
    }
  }

  useEffect(() => {
    if (filteredData != null && filteredData.length > 0) {
      filterTasksTechnicienByType(filteredData);
    } else {
      filterTasksTechnicienByType(task);
    }
  }, [selectTasksBy]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    IMEI: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    SIM: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const clearFilterTaskTechnicien = () => {
    setDataFiltering(null);
    setSearchInputTechnicianPerDate({ startDate: "", endDate: "" });
    getAllTerminedTasks();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderMoreDetailComponent = (rowDate) => {
    return (
      <>
        <DetailsModel taskToUpdate={rowDate} />
      </>
    );
  };

  const renderGPSPosition = (rowDate) => {
    return <>{rowDate.gps_principale === 0 ? "Première" : "Seconder"}</>;
  };
  const renderStatuTask = (rowDate) => {
    return (
      <>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Form.Select
            style={{ width: "100px" }}
            onChange={(e) => handleChangeEtatBilled(e, rowDate.id)}
            className="tâches-statut"
            aria-label="Default select example"
          >
            <option value="none" selected disabled hidden>
              {rowDate.billed === 0
                ? "attente"
                : rowDate.billed === 1
                ? "terminé"
                : ""}
            </option>
            <option value={1}>en attente</option>
            <option value={2}>terminé</option>
          </Form.Select>
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche par mot clé"
          />
        </span>
      </div>
    );
  };

  const renderTypeTask=(rowData)=>{
    switch (true) {
      case (rowData.catache == "installation" && rowData.gps_principale == 1):
        return "2ème Installation";
      default:
        return rowData.catache;
    }
  }

  const header = renderHeader();

  return (
    <div>
      <div className="top-bar-table-task-technicien">
        {USE.profil_id == 2 ? (
          <div className="">
            <NouvelleTâches />
          </div>
        ) : (
          ""
        )}
        <div
          className="filter-task-technicien"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "baseline",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Control
                  type="date"
                  name="startDate"
                  value={filterByDate.startDate}
                  onChange={handleSearchTechnicianTaskPerDate}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Control
                  type="date"
                  name="endDate"
                  value={filterByDate.endDate}
                  onChange={handleSearchTechnicianTaskPerDate}
                  required
                />
              </Form.Group>
            </Row>

            <div className="filter-button-task-technicien">
              <Button
                onClick={(e) => handleSearchTechnicianTaskPerDateClicked(1)}
              >
                {" "}
                <TuneIcon />
                Filtrer
              </Button>
              <Button variant="danger" onClick={clearFilterTaskTechnicien}>
                {" "}
                <ClearIcon />
                Annule
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="tâches-tech-technicien">
        <div className="Table-tasks">
          <h3>
            Total Tâches &nbsp; <span class="vl"></span> &nbsp;{" "}
            <span style={{ fontSize: "15px" }}>
              {" "}
              {technicianTasks.length} tâches
            </span>{" "}
          </h3>
          <Form>
            <Row md="4">
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="3"
                controlId="validationCustom01"
              >
                <Form.Label>sélectionner les tâches par: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="type"
                  onChange={(e) =>
                    setSelectTasksBy({
                      ...selectTasksBy,
                      category: e.target.value || null,
                    })
                  }
                >
                  <option value="none" selected disabled hidden>
                    Type Tâche
                  </option>
                  <option disabled>sélectionner les tâches par</option>
                  <option value="">Toutes les tâches</option>
                  <option value="installation">Installation</option>
                  <option value="desinstalation">Désinstallation</option>
                  <option value="reinstallation">Réinstallation</option>
                  <option value="Changement du SIM">Changement du SIM</option>
                  <option value="Changement du GPS">Changement du GPS </option>
                  <option value="reparationRelais">Réparation Relais</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="3"
                controlId="validationCustom01"
              >
                <Form.Label>sélectionner les tâches par: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="type"
                  onChange={(e) =>
                    setSelectTasksBy({
                      ...selectTasksBy,
                      city: e.target.value || null,
                    })
                  }
                >
                  <option value="none" selected disabled hidden>
                    ville
                  </option>
                  <option disabled>sélectionner les tâches par</option>
                  <option value="">Toutes les Villes</option>
                  {allCities?.map((city, index) => (
                    <option key={index} value={city.cityName}>
                      {city.cityName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-4 mx-2"
                as={Col}
                md="3"
                controlId="validationCustom01"
              >
                <Form.Label>sélectionner les tâches par: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="type"
                  onChange={(e) =>
                    setSelectTasksBy({
                      ...selectTasksBy,
                      state: e.target.value || null,
                    })
                  }
                >
                  <option value="none" selected disabled hidden>
                    statut
                  </option>
                  <option disabled>sélectionner les tâches par</option>
                  <option value="">tous les États</option>
                  <option value="billed">Facturées</option>
                  <option value="waiting">Non Facturées</option>
                </Form.Select>
              </Form.Group>
            </Row>
          </Form>
          <div className="card">
            <DataTable
              value={technicianTasks}
              header={header}
              filters={filters}
              globalFilterFields={["client_name", "IMEI", "SIM"]}
              removableSort
              paginator
              rows={15}
              rowsPerPageOptions={[15, 25, 50, 100]}
              tableStyle={{
                minWidth: "50rem",
                backgroundColor: "#F24405",
              }}
            >
              <Column
                header="Plus Details"
                body={renderMoreDetailComponent}
                style={{ width: "5%" }}
              ></Column>
              <Column
                field="client_name"
                header="Nom du client"
                sortable
                style={{ width: "22%", color: "#000" }}
                bodyStyle={{ fontWeight: "bold" }}
              ></Column>
              <Column
                field="catache"
                header="Type du tache"
                body={renderTypeTask}
                style={{ width: "12%" }}
              ></Column>
              <Column
                body={renderGPSPosition}
                header="Position GPS"
                style={{ width: "10%" }}
              ></Column>
              <Column
                field="date_fin_realisation"
                header="Date fin réalisation"
                sortable
                style={{ width: "15%" }}
              ></Column>
              <Column
                field="matricule"
                header="Matricule"
                sortable
                style={{ width: "15%" }}
              ></Column>
              <Column
                field="cityName"
                header="Ville"
                sortable
                style={{ width: "10%" }}
              ></Column>

              <Column
                field="user_name"
                header="Exécuter par"
                sortable
                style={{ width: "20%" }}
              ></Column>
              <Column
                field="billed"
                header="Statut"
                body={renderStatuTask}
                sortable
                style={{ width: "25%" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComptableTasksTechnicien;
